import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'shared-add-skills-rate-form',
  templateUrl: './add-skills-rate-form.component.html',
  styleUrls: ['./add-skills-rate-form.component.scss'],
})
export class AddSkillsRateFormComponent implements OnInit, OnChanges {
  minBroadcastRate = 11.5;
  skills = [];

  @Output() formSubmitted = new EventEmitter();
  @Output() userUpdate = new EventEmitter();
  @Output() updateBrodcastRate = new EventEmitter();
  @Output() reset = new EventEmitter();
  @Input() user: IUser;
  @Input() defaultRates: [];
  @Input() hideNextButton?: boolean;
  minBroadcastFocused = false;
  rates = [];
  orignal
  currentRate: any;
  debounceTimeout: NodeJS.Timer;
  constructor() {
  }

  ngOnChanges() {

    this.orignal =

      JSON.parse(JSON.stringify(this.user.skills.filter((skill) => {
        return skill.name !== 'all positions';
      })));
    this.skills = this.user.skills.filter((skill) => {
      return skill.name !== 'all positions';
    }).sort((a, b) => a.name > b.name ? 1 : -1);
    this.minBroadcastRate = this.user.iWillNotWorkForShiftsBelow;
 }
  ngOnInit() {
    for (let i = 0.5; i <= 150; i = i + 0.5) {
      this.rates.push({
        id: i,
        label: '$' + i.toFixed(2)
      });
    }
  }

  formatRate(id: number) {
    const index = this.rates.findIndex( rate => rate.id == id);
    if (index == -1) return ""
    return this.rates[index].label;
  }

 
  updateMinimumBroadcastRate() {
   
    //Remove if any existing timeot found
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    //Set debounce timeout 
    this.debounceTimeout = setTimeout(() => {
      this.updateBrodcastRate.emit({
        iWillNotWorkForShiftsBelow: +this.minBroadcastRate
      });
      this.minBroadcastRate = +this.minBroadcastRate;
    } , 500);
  }


    updateRate(skill: string, newRate: number, i: number) {
      // Clear any existing timeout
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
    
      // Set a new timeout to call updateRate after 500ms, move debounce from html and place it after the value is changed
      this.debounceTimeout = setTimeout(() => {
        this.skills[i].rate = newRate;
        this.userUpdate.emit({
          skill,
          rate: newRate
        });
      }, 500);
    }


  next() {
    this.formSubmitted.emit();
  }

}
