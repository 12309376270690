import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { forEach, groupBy, isEqual } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { getUserInfoAction, setNotificationCountAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { IUpdateUser } from 'src/modules/authentication/interfaces/update-user.interface';
import { IUserAvailability } from 'src/modules/authentication/interfaces/user-availability.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { INotification } from 'src/modules/talent-dashboard/interfaces/notification.interface';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { TalentLocationTrackingService } from 'src/modules/talent-dashboard/services/talent-location-tracking.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ApplyErrorHandler } from '../../services/apply-error-handler.service';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { PopupService } from '../../services/popup.service';
import { SubSkillService } from '../../services/subskill.service';
import { UserService } from '../../services/user.service';
import { UtilService } from '../../services/util.services';
import { IEntity } from 'src/modules/talent-dashboard/interfaces/entity.interface';
import { appendQueryParameter } from '../../utils/helpers/appendQueryParameter.helper';
import { ITalentDashboardState } from 'src/modules/talent-dashboard/+store/talent-dashboard.state';
import { rejectNonRecurringSuccessAction, rejectRecurringSuccessAction } from 'src/modules/talent-dashboard/+store/actions/talent-dashboard.actions';
import { TalentJobChecksService } from 'src/modules/talent-dashboard/services/talent-job-checks.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit, OnDestroy {

  openHeight = 0;
  currentDate;
  noShifts : {
    text: string,
    imageUrl: string,
    subText: string,
    buttonText: string,
    navigateRoute: string
  };

  userData: IUser;
  subscription: Subscription;
  queryParamSubscription: Subscription;
  hour24PayAvailableBadge: boolean;
  readonly user = this.authStore.pipe(select(getUserInfo));
  notifications: { [key: string]: INotification[] };
  notificationData: {
    notifications: INotification[];
    notificationsLength: number;
    archivedLength: number;
  };
  notificationId: string;
  settings: ISetting;
  userIndustry: string;
  isLoadingComplete: boolean = false;
  @Input() loadNotificationFromDrawer: boolean;
  @Input() isOpen: boolean = true;
  @ViewChild('drawer' , {read: ElementRef, static: false}) drawer: ElementRef;
  @Output() hideList = new EventEmitter();
  isPageAlive$ = true;
 
  message: string;
  constructor(private el: ElementRef,
    private authStore: Store<IAuthState>,
    private talentDashboardStore: Store<ITalentDashboardState>,
    private talentDashboardService: TalentDashboardService,
    private jobService: JobService,
    private utilService: UtilService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private loadingService: LoadingService,
    private popupService: PopupService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    private platform: Platform,
    private subSkillService: SubSkillService,
    private applyErrorHandler: ApplyErrorHandler,
    private cdRef: ChangeDetectorRef,
    private modalController: ModalController,
    private talentJobChecksService: TalentJobChecksService,

    private talentLocationTrackingService: TalentLocationTrackingService,
    ) {}
 

  ionViewWillEnter() {
    this.cdRef.detectChanges();
    this.toggleDrawer();
  }

  async ionViewDidEnter() {
    this.currentDate = moment().tz('America/Toronto').format('MMM DD, YYYY');
    let user =  this.authStore.pipe(select(getUserInfo), distinctUntilChanged(isEqual));

    this.subscription = user.subscribe(async result => {
      this.userData = result;
      if(this.userData) {
        this.queryParamSubscription = this.route.queryParams.subscribe(params => {
          this.notificationId = params.id;
          if (this.notificationId) {
            this.loadSingleNotification();
          } else {
            this.loadNormal();
          }
        });
      }
    });
    this.settings = await this.localStorageService.getSetiings();
    
    this.message = this.settings.messageOfDay || 'No Message Found';
    if (this.settings && this.settings.hour24PayAvailableBadge) {
      this.hour24PayAvailableBadge = true;
    } else {
      this.hour24PayAvailableBadge = false;
    }
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('destroyed');
    
  }


  ngAfterViewChecked() {
    this.toggleDrawer();
  }

  drawerVisibility(redirectPath? : string) {
    this.isOpen = !this.isOpen;
    this.toggleDrawer();
    this.modalController.dismiss({
      isOpen: this.isOpen,
      redirectPath
    }, null, "notification-list-modal");
  }

  toggleDrawer() {
    const drawer = this.drawer.nativeElement;
  
    if(this.isOpen) {
      drawer.style.visibility = 'visible';
      drawer.style.transition = '.4s ease-out';
      drawer.style.transform = 'translateY(0px)';

      if(drawer) {
        let wrapper = this.el.nativeElement.querySelector('.wrapper');
        if(wrapper)
          wrapper.style.height = `${this.openHeight - 100}px`;
      }  
    } else {
      drawer.style.transition = '.4s ease-out';
      drawer.style.transform =  `translateY(${this.openHeight}px)`;
      this.el.nativeElement.querySelector('.wrapper').style.height = `${this.openHeight - 100}px`;
      drawer.style.visibility = 'hidden';
    }
  }

  
  async ngOnInit() {
    this.openHeight = (this.platform.height());
    this.noShifts = {
      text: 'No notification found',
      imageUrl: 'assets/images/sorry1.png',
      subText: '',
      buttonText: '',
      navigateRoute: ''
    }
  }

  returnZero() {
    return 0;
  }


  showMOTD() {
    this.popupService.showModal({
      heading: 'Staffy: Message of the day',
      message: this.message,
      btn: 'Ok',
      navigateRoute: null,
      imgURL: ''
    });
  }
  
  async loadSingleNotification() {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      const notification = await this.talentDashboardService.getSingleNotifications(this.notificationId);
      const notifications = await this.formatAndFeedCurrentUserNotifications([notification]);
      this.notifications = groupBy(notifications, 'day');
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingService.hideLoading(loading);
      this.isLoadingComplete = true;
    }
  }

  async getLatestNotifications() {
    let  loading; 
    
    if(this.isOpen)
      loading = await this.loadingService.showLoading().toPromise();
    
    try {
      this.notificationData = await this.talentDashboardService.getNotifications(this.userData._id);
      const notifications = await this.formatAndFeedCurrentUserNotifications(this.notificationData.notifications);
      this.notifications = groupBy(notifications, 'day');
    } catch (error) {
      console.log(error);
    } finally {
      if(loading)
        this.loadingService.hideLoading(loading);
      this.isLoadingComplete = true;
    }
  }

  formatAndFeedCurrentUserNotifications(notifications: INotification[]) {
    forEach(notifications, (notification: INotification) => {
      let day: string;

      const created = moment(notification.created);
      const today = moment().clone().startOf('day');
      const yesterday = moment().clone().subtract(1, 'days').startOf('day');

      if (created.isSame(today, 'day')) {
        day = 'Today';
      } else if (created.isSame(yesterday, 'd')) {
        day = 'Yesterday';
      } else {
        day = created.format('MMM DD, YYYY');
      }

      notification.day = day;

      if (notification.job && notification.groupJobId) {
        notification.job.isStatPay = this.utilService.isStatPayAvailable(notification.groupJobId.jobs);

        if (notification.job.state === 'offer') {
          notification.isAJobOffer = true;
        } else {
          notification.isAJobOffer = false;
        }
        if (notification.job.state === 'complete' && notification.job.comments.staffer.rating) {
          notification.isARateRequestAndHasBeenRated = true;
        } else {
          notification.isARateRequestAndHasBeenRated = false;
        }
      }
    });

    return notifications;
  }

  async confirmedClear() {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.talentDashboardService.dismissOldNotifications(this.userData._id, +moment().format('X'));
      await this.getLatestNotifications();
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingService.hideLoading(loading);
      this.isLoadingComplete = true;
    }
  }

  async cardClicked(notification: INotification) {
    try {
      await this.talentDashboardService.updateNotificationReadStatus(notification._id);
    } catch (error) {
      console.log(error);
    }
  }

  async clear() {
    const alert = await this.alertController.create({
      header: 'Dismiss Notifications',
      message: 'Are you sure you want to clear previous notifications?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Yes',
          handler: () => {
            this.confirmedClear();
          }
        }
      ]
    });

    await alert.present();
  }

  async doRefresh(event) {
    if (this.notificationId) {
      await this.loadSingleNotification();
    } else {
      await this.loadNormal();
    }
    event.target.complete();
  }

  loadNormal() {
  this.authStore.dispatch(setNotificationCountAction({ notificationCount: 0 }));

    if (this.userData) {
      this.getLatestNotifications();
    }
  }

  async acceptNonRecurringBroadcastRequest(data: { jobId: string; stafferId: string; acceptedRcmpFee: boolean, skill: string, job: ITalentJob }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.jobService.acceptBroadcastRequestNonRecurring(data.jobId, data.stafferId, data.acceptedRcmpFee);
     
      const homecare = data.job.entity.homecareClient;

      if(homecare && homecare.screening && homecare.screening.isRequired) {
        let link = homecare.screening.link;
        const entityName = data.job.entity.name;
        if(data.job.homecareShift&& data.job.homecareShift.patientId){
          link = appendQueryParameter(link,`a3=${data.job.homecareShift.patientId}`)
        }
        return this.popupService.showScreeningPopup(link, entityName);
      }

      this.popupService.showModal({
        heading: 'Shift requested',
        message: 'You have successfully requested this shift! We will send you a notification if you are approved.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/clap.png'
      });

      this.userIndustry = await this.utilService.getIndustryBySkillName(data.skill);
      this.analyticsService.trackEvent('job_applied', 'User Flow', '3 Apply Job', 'app', this.userIndustry);

      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);

      // checking if we need to show subskill error
      const { isHandled } = this.subSkillService.checkSubSkillError(error, this.userData);
      if (isHandled) {
        return;
      }

      this.applyErrorHandler.handleError(error);
    }
  }

  async unApplyFromNonRecurringShift(data: { jobId: string; dueToRCMPFee: boolean }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.jobService.unApplyFromBroadcast(data.jobId, this.userData, true, false, data.dueToRCMPFee);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async rejectNonRecurringJob(data: { job: ITalentJob }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.jobService.rejectNonRecurringJob({ ...data.job });
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
      //Dispatch action to update the state of non recurring shift in staffer and shifts 
      //Update state to "reject"
      this.talentDashboardStore.dispatch(rejectNonRecurringSuccessAction({jobId: data.job._id, stafferId: this.userData._id}));
      
      this.popupService.showModal({
        heading: 'Job rejected',
        message: 'Shift rejected. The creator has been notified.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async acceptNonRecurringHireRequest(data: { jobId: string; shiftResponseTime: number }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.jobService.acceptNonRecurringHireRequest(data.jobId, {
        stafferId: this.userData._id,
        shiftResponseTime: data.shiftResponseTime
      });
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
      this.popupService.showModal({
        heading: 'Job Accepted',
        message: 'Shift accepted. The creator has been notified.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/thumbs-up.png'
      });
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
      this.popupService.showModal({
        heading: 'Technical Issue',
        message: 'Technical error, contact Staffy OPS Team to proceed.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
    }
  }

  async confirmNonRecurringShift(data: { jobId: string }, notification?: INotification ) {
    if (!this.userData.hasAccessToApplyOnShifts) {
      this.popupService.showModal({
        heading: 'Please sign the Waiver of Liability',
        message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
        btn: 'Ok',
        navigateRoute: 'talent-dashboard/hello-sign',
        imgURL: 'assets/images/profile.png'
      });
    } else {
      const loading = await this.loadingService.showLoading().toPromise();
      try {
        //Check if the contractor has valid VSC before confirming for non recurring shift
        await this.talentJobChecksService.ensureContractorHasValidDocuments(notification.job, this.userData);
        await this.jobService.nonRecurringShiftConfirm(data.jobId, this.userData._id);
        await this.loadingService.hideLoading(loading);
        this.getLatestNotifications();
      } catch (error) {
        console.log('error', error);
        await this.loadingService.hideLoading(loading);

        if (error === 'VACANCIES_FULL') {
          this.popupService.showModal({
            heading: 'Vacancies full',
            message: `Sorry, all vacancies for this shift are full.`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
          });
        } else if (error && error.error && typeof error.error === 'string') {

          let heading = 'Sorry';
          let cssClass = '';

          //Overwrite heading and CSS class if the error is a background check error
          if(error.error.includes('background check')) {
            heading = 'This shift requires a valid background check';
            cssClass = 'background-check'
          }

          this.popupService.showModal({
            heading: heading,
            message: error.error,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png',
            specificClass: cssClass
          });
        }
      }
    }
  }
  async stafferStillWorkingOnJob(data: { trackerId: string }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.talentDashboardService.stillWorkingStafferNonRecurringJob(data.trackerId);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }
  async rejectStafferBroadcastRequest(data: { jobId: string; stafferId: string; notificationId: string }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.jobService.rejectStafferBroadcastRequest(data);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  seeAvailableJobs() {
    this.router.navigateByUrl('talent-dashboard/home');
  }

  async updateUser(user: IUpdateUser) {
    // for rcmp update
    try {
      await this.userService.updateUserPromise(this.userData._id, user);
      this.authStore.dispatch(getUserInfoAction());
    } catch (error) {
      console.log('err', error);
    }
  }

  async submitRating(data: { jobId: string; stafferId: string; rating: number, skill: string }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      const payload = {
        stafferId: data.stafferId,
        rating: data.rating,
        jobIds: [data.jobId],
        skill: data.skill
      };

      await this.jobService.rateStafferInJobs(payload);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log(error);
    }
  }

  async stopTimerOnJob(data: { jobId: string; endTime: number; userId: string, skill: string }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      const location = await this.talentLocationTrackingService.getUserLocation();
      await this.talentDashboardService.stopTimer(data.jobId, data.endTime, data.userId, {
        latitude: `${location.coords.latitude}`,
        longitude: `${location.coords.longitude}`
      });
      this.popupService.showModal({
        heading: 'End Shift Request',
        message: 'Shift ended. The creator will be sent a notification.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/request-sent.png'
      });

      this.userIndustry = await this.utilService.getIndustryBySkillName(data.skill);
      this.analyticsService.trackEvent('job_completed', 'User Flow', '4 Complete Job', 'app', this.userIndustry);
    
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async hireStafferForTheNonRecurringJob(data: { job: ITalentJob; staffer: IUser }) {
    const invoiceResponse = await this.ensureStafferAvailibility(data.job._id, data.staffer._id);
    if (!invoiceResponse) {
      return;
    }
    const bCStaffer = data.job.isABroadcastRequest.broadcastedTo;
    for (const shift of bCStaffer) {
      if (shift.staffer === data.staffer._id && shift.status === 'hired') {
        return;
      } else if (this.utilService.isStaffersConfirmedShifts(data.job.staffersAndShifts).length === data.job.isABroadcastRequest.vacancies) {
        this.popupService.showModal({
          heading: 'OOPS!',
          message: 'Vacancies are Full.',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/sorry1.png'
        });
        return;
      }
    }
    const updatedJob = await this.updateJobStatus(data.job._id, data.staffer._id, invoiceResponse);
    this.updateAvailibility(data.staffer, updatedJob);
  }

  async ensureStafferAvailibility(jobId: string, stafferId: string) {
    try {
      return await this.jobService.isStafferAvailableToAcceptJob(jobId, { staffer_id: stafferId });
    } catch (error) {
      if (error.errMsg) {
        this.popupService.showModal({
          heading: 'Sorry!',
          message: 'Something went wrong, please contact Staffy Team to proceed.',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/profile.png'
        });
      } else {
        this.popupService.showModal({
          heading: 'OOPS!',
          message: 'Staffer is no longer available in that time slot.',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/profile.png'
        });
      }
      return;
    }
  }

  async updateJobStatus(
    jobId: string,
    stafferId: string,
    invoiceResponse: {
      isAvailable: boolean;
      invoiceId: string;
    }
  ) {
    const payload = {
      stafferAndShift: {
        staffer: stafferId,
        state: 'accepted',
        active: true
      }
    };
    if (invoiceResponse.invoiceId) {
      payload['invoices'] = invoiceResponse.invoiceId;
    }
    try {
      return await this.jobService.updateJob(jobId, payload);
    } catch (error) {
      console.log('err', error);
      this.popupService.showModal({
        heading: 'Sorry!',
        message: 'Something went wrong, please contact Staffy Team to proceed.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
      return;
    }
  }

  async updateAvailibility(staffer: IUser, updatedJob: ITalentJob) {
    if (staffer.availability_schedule.length) {
      let acceptedJobDate = moment(updatedJob.shiftDate).format('MMMM Do YYYY');
      if (moment.unix(updatedJob.shiftStartTime).hours() < 6) {
        acceptedJobDate = moment.unix(updatedJob.shiftStartTime).subtract(1, 'days').format('MMMM Do YYYY');
      }
      const interestedStafferUpdatedSchedule: IUserAvailability[] = [];
      forEach(staffer.availability_schedule, schedule => {
        if (schedule.date !== acceptedJobDate) {
          interestedStafferUpdatedSchedule.push(schedule); // update availability status for that date
        }
      });

      await this.userService.updateUserAvailability(staffer._id, interestedStafferUpdatedSchedule, true);
      this.popupService.showModal({
        heading: 'Request Approved!',
        message: 'The staffer will be sent a notification.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/request-sent.png'
      });
      this.getLatestNotifications();
    } else {
      this.popupService.showModal({
        heading: 'Request Approved!',
        message: 'The staffer will be sent a notification.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/request-sent.png'
      });
      this.getLatestNotifications();
    }
  }

  async acceptRecurringBroadcastRequest(data: { groupJobId: string; stafferId: string; acceptedRcmpFee: boolean, skill: string,  entity:IEntity,job: ITalentJob}) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.talentDashboardService.acceptBroadcastRequestRecurring(data.groupJobId, data.stafferId, data.acceptedRcmpFee);
      

      const homecare = data.entity.homecareClient;

      if(homecare && homecare.isTrue && homecare.screening && homecare.screening.isRequired) {
        let link = homecare.screening.link;
        const entityName = data.entity.name;
        if(data.job && data.job.homecareShift && data.job.homecareShift.patientId){
          link = appendQueryParameter(link,`a3=${data.job.homecareShift.patientId}`)
        }
        return this.popupService.showScreeningPopup(link, entityName);
      }

      this.popupService.showModal({
        heading: 'Shift requested',
        message: 'You have successfully requested this shift! We will send you a notification if you are approved.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/clap.png'
      });

      this.userIndustry = await this.utilService.getIndustryBySkillName(data.skill);
      this.analyticsService.trackEvent('job_applied', 'User Flow', '3 Apply Job', 'app', this.userIndustry);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);

      // checking if we need to show subskill error
      const { isHandled } = this.subSkillService.checkSubSkillError(error, this.userData);
      if (isHandled) {
        return;
      }

      this.applyErrorHandler.handleError(error);
    }
  }

  async rejectStafferRecurringBroadcastRequest(data: { groupJobId: string; stafferId: string; notificationId: string }) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      await this.jobService.rejectStafferRecurringRequest(data.groupJobId, {
        stafferId: data.stafferId,
        notificationId: data.notificationId
      });
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async unApplyFromRecurringShift(data: { groupJobId: string; dueToRCMPFee: boolean }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.jobService.unApplyFromBroadcast(data.groupJobId, this.userData, true, true, data.dueToRCMPFee);
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async confirmRecurringShift(data: { groupJobId: string }, notification?: INotification) {
    if (!this.userData.hasAccessToApplyOnShifts) {
      this.popupService.showModal({
        heading: 'Please sign the Waiver of Liability',
        message: 'To be able to apply to this shift, you will need to sign the Waiver of liability.',
        btn: 'Ok',
        navigateRoute: 'talent-dashboard/hello-sign',
        imgURL: 'assets/images/profile.png'
      });
    } else {
      const loading = await this.loadingService.showLoading().toPromise();
      try {
        //Check if the contractor has valid VSC before confirming for recurring shift
        await this.talentJobChecksService.ensureContractorHasValidDocuments(notification.job, this.userData);
        await this.jobService.recurringShiftConfirm(data.groupJobId, this.userData._id);
        await this.loadingService.hideLoading(loading);
        this.getLatestNotifications();
      } catch (error) {
        console.log('error', error);
        await this.loadingService.hideLoading(loading);

        if (error === 'VACANCIES_FULL') {
          this.popupService.showModal({
            heading: 'Vacancies full',
            message: `Sorry, all vacancies for this shift are full.`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
          });
        } else if (error && error.error && typeof error.error === 'string') {
          let heading = 'Sorry';
          let cssClass = '';

          //Overwrite heading and CSS class if the error is a background check error
          if(error.error.includes('background check')) {
            heading = 'This shift requires a valid background check';
            cssClass = 'background-check'
          }

          this.popupService.showModal({
            heading: heading,
            message: error.error,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/profile.png',
            specificClass: cssClass
          });
        }
      }
    }
  }
  
  

  async acceptRecurringHireRequest(data: { groupJobId: string; shiftResponseTime: number }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.talentDashboardService.acceptRecurringHireRequest({
        stafferId: this.userData._id,
        groupJobId: data.groupJobId,
        shiftResponseTime: data.shiftResponseTime
      });
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
      this.popupService.showModal({
        heading: 'Job Accepted',
        message: 'Shift accepted. The creator has been notified.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/thumbs-up.png'
      });
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
      this.popupService.showModal({
        heading: 'Technical Issue',
        message: 'Technical error, contact Staffy OPS Team to proceed.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
    }
  }

  async rejectRecurringHireRequest(data: { groupJobId: string }) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      await this.talentDashboardService.rejectRecurringHireRequest({
        groupJobId: data.groupJobId,
        stafferId: this.userData._id
      });
      this.loadingService.hideLoading(loading);
      this.getLatestNotifications();
      //Dispatch action to update the state of recurring shifts in staffer and shifts  
      //Update state to "reject"
      this.talentDashboardStore.dispatch(rejectRecurringSuccessAction({groupJobId: data.groupJobId, stafferId: this.userData._id}));
      
      this.popupService.showModal({
        heading: 'Job rejected',
        message: 'Shift rejected. The creator has been notified.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
    } catch (error) {
      this.loadingService.hideLoading(loading);
      console.log('error', error);
    }
  }

  async hireStafferForTheRecurringJob(data: { staffer: IUser; groupJobId: string }) {
    try {
      await this.jobService.hireStafferOnRecurringJob(data.groupJobId, {
        stafferId: data.staffer._id,
        entityId: this.userData.entityId._id
      });
      this.popupService.showModal({
        heading: 'Request Approved!',
        message: 'The staffer will be sent a notification.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/request-sent.png'
      });
      this.getLatestNotifications();
    } catch (err) {
      console.log('error', err);
      let errMsg = 'Something went wrong, please contact Staffy Team to proceed.';
      if (err.error === 'Vacancies are Full') {
        errMsg = 'Vacancies are Full';
      } else if (err.error === 'Availability is not set for job specific days') {
        errMsg = 'Staffer is not available in this time frame';
      } else if (err.transaction === false) {
        errMsg = 'Your credit card was declined';
      }
      this.popupService.showModal({
        heading: 'SORRY!',
        message: errMsg,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
    }
  }
  
}
