import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-shift-header',
  templateUrl: './shift-header.component.html',
  styleUrls: ['./shift-header.component.scss'],
})
export class ShiftHeaderComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {}

}
