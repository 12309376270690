<div class="main-container">
  <ion-col class="checkbox" size="1">
    <app-form-checkbox #checkBox (onChange)="toggleCheckBox()" [options]="checkBoxOptions">
    </app-form-checkbox>

  </ion-col>
  <div class="row-container">
    <ion-col class="recurrent-data" [ngClass]="{'date-item': item == 'Date'}" size="3" *ngFor="let item of config">
      {{data[item]}}
    </ion-col>
    <ion-col (click)="toggleAllCheckBoxes()" class="recurrent-data action-item" size="3">
      {{data['action']}}
    </ion-col>
  </div>
</div>