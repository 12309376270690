<ion-list class = "reusable-form-component">
  <ion-item>
    <ion-label class="label" position="stacked" *ngIf = "labelText">{{labelText}}</ion-label>
    <app-form-add-new class = "add-new-option" [icon] = "icon" *ngIf = "labelText === 'Patient Id'" [labelText] = "'Add New'" (click) = "handlePopup()"></app-form-add-new>
    <ion-select 
    class = "form-select" 
    [disabled] = "disableList"
    [(ngModel)] = "value" 
    placeholder="{{placeholder}}" 
    [ngClass] = "{'inital-value' : !value}" 
    [interfaceOptions] = "{cssClass: 'references-select'}" 
    (ionChange) = "handleChange($event)">
      <ion-select-option *ngFor="let option of options" value="{{dataKey ? option[dataKey] : option}}">{{dataKey ? option[dataKey] : option}}</ion-select-option>
    </ion-select>
  </ion-item>
</ion-list>


<app-form-error-message class = "error" *ngIf = "control?.touched && control?.errors?.required"   
  [error] = "'This is a required field'"></app-form-error-message>


<app-form-error-message class = "error" *ngIf = "control?.touched && control?.errors?.message"   
  [error] = "control?.errors?.message"></app-form-error-message>
  
