import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class NewThemeViewsService {

constructor() {}


newViewsUrl(): string[] {
    const newLayoutPageUrls: string[] = [
        'home',
        'recurrent-shifts',
        'shift-requests',
        'ongoing-shifts'
    ];
    return newLayoutPageUrls;
}

    
newHeaderProperties(currentView: string, extendedParams?: string) {
    const currentHeaderProperties = {
        'home': {
          'heading': 'Home',
          'button': 'Create new broadcast',
          'navigateTo': 'company-dashboard/broadcast-request'
        }, 
    
        'recurrent-shifts': {
          'heading': 'Recurring Shifts',
          'button': null,
          'navigateTo': null
        },
        
        'shift-requests': {
          'open': {
            'heading': 'Open Requests',
            'button': null,
            'navigateTo': null,
            'icon': 'fa fa-calendar',
            "toggleBar": {
                data : {
                    allText : 'All',
                    leftText: 'Regular',
                    rightText : 'Recurring'
                }
            }
          }, 
          'filled': {
            'heading': 'Filled Requests',
            'button': null,
            'navigateTo': null,
            'icon': 'fa fa-calendar',
            "toggleBar": {
                data : {
                  allText: 'All',
                  leftText : 'Regular',
                  rightText : 'Recurring'
                }
            }
          },
          'ongoing-shifts': {
            'heading': 'Ongoing Shifts',
            'button': null,
            'navigateTo': null,
            'icon': null
          }
        },
        
    };


    if(!currentHeaderProperties[currentView]) {
        return;
    }
    return extendedParams ? currentHeaderProperties[currentView][extendedParams] : currentHeaderProperties[currentView];
    }
}

