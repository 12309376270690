import { Component, Input, OnInit } from '@angular/core';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-training-shift',
  templateUrl: './training-shift.component.html',
  styleUrls: ['./training-shift.component.scss'],
})


export class TrainingShiftComponent implements OnInit {
  @Input() jobItem

   
    constructor( private popupService: PopupService,
      private userService: UserService) { }

  ngOnInit() {}

  showTrainingRequired(jobItem: ITalentJob) {
    this.popupService.showModal(
      {
        heading: 'Training Required',
        message: `This location requires training which you do not have.  If you'd like to sign up for training, please press the sign up button below, and we will be in touch.`,
        btn: 'Sign up!',
        rejectBtnText: 'Cancel',
        navigateRoute: null,
        imgURL: 'assets/images/interested_staffers.png'
      },
      async accept => {
        this.userService.createTrainingRequest(jobItem.entity._id, jobItem.entity.name, jobItem._id);
        this.popupService.showModal({
          heading: 'Request Received.',
          message: 'We have recieved your request, We will notify you of any upcoming training shifts.',
          btn: 'Ok',
          navigateRoute: null,
          imgURL: 'assets/images/thumbs-up.png'
        });
      }
    );
  }

}
