import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';

@Component({
  selector: 'app-recurring-shift-list-item',
  templateUrl: './recurring-shift-list-item.component.html',
  styleUrls: ['./recurring-shift-list-item.component.scss'],
})
export class RecurringShiftListItemComponent {
  @ViewChild('checkBox', { static: false }) checkBoxRef: FormCheckboxComponent;

  @Input() data: any;
  @Input() config: Array<string>;
  @Output() onToggleCheckBox = new EventEmitter<boolean>();
  @Output() onToggleAllCheckBoxes = new EventEmitter<boolean>();
  checkBoxOptions = [ { label : ""}]

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.checkBoxRef.writeValue(this.data.selected);
    this.cdr.detectChanges();
  }

  toggleCheckBox() {
    this.onToggleCheckBox.emit(!this.data.selected);
  }

  toggleAllCheckBoxes() {
    this.onToggleAllCheckBoxes.emit(!this.data.selected);
  }
}

