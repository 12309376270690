<div class="table-container">
  <ngx-datatable 
      class="material data-table"
      [rows]="rows"
      [columnMode]="'force'"
      [headerHeight]="50"
      [rowHeight]="50"
      [rowClass]="getRowClass"
      [scrollbarH]="true"
      [scrollbarV]="true">
      <ngx-datatable-column [width]="280" name="column" prop="column" [headerClass]="getHeaderClass" *ngFor="let column of columns" [cellClass]="getCellClass" [name]="column.name" [prop]="column.prop">
        <ng-template   ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
         <div [ngClass]="{'data-table-error-cell': row.error && row.error[column.name]}" style="border-bottom:1px solid">
          <div class="data-table-error-message"  *ngIf="row.error && row.error[column.name]; else elseBlock">{{row.error[column.name]}}</div>
          <ng-template #elseBlock>
            <div>&nbsp;&nbsp;&nbsp;</div>
          </ng-template>
           {{ value || '-' }}
          </div>
          </ng-template>
        
        
      </ngx-datatable-column>
    </ngx-datatable>
</div>