import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { IStafferReferralList } from '../../../onboarding-staffer/interfaces/staffer-referral-list.interface';
import { AlertController } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { Router } from '@angular/router';
import { PopupService } from '../../services/popup.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'shared-friend-referrals-list',
  templateUrl: './friend-referrals-list.component.html',
  styleUrls: ['./friend-referrals-list.component.scss'],
})
export class FriendReferralsListComponent implements OnInit, OnChanges {
  @Output() formSubmitted = new EventEmitter<IStafferReferralList>();
  @Output() addAnother = new EventEmitter();

  @Input() refList: IStafferReferralList[];
  @Input() activeRefsList; // jobsNonHC, jobsHC, staffer
  @Input() isMobileDevice: boolean;
  @Input() userData: IUser;
  txtQueryRef: string;
  searchQuery: string;
  referralLevel = '';
  earning = 0;
  potentialRefs = 0; // no of refs with 5 completed jobs
  allRefListCopy = [];
  activeRefListCopy = [];
  allReferrals = [];
  firstHCskillStafferId;
  firstNonHCskillStafferId;
  referralForm: FormGroup;
  isTypeEmail = true;
  enableSearch: boolean;
  @Output() discardNewEntry = new EventEmitter();
  @Output() referralAdded = new EventEmitter<{ displayName: string, email: string, phoneNumber: string }>();

  constructor(
    private alertController: AlertController,
    private router: Router,
    private popupService: PopupService) { }

  ngOnInit() {
    this.referralForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl(''),
      phoneNumber: new FormControl(''),
    });
  }

  ngOnChanges() {
    this.allReferrals = [...this.activeRefsList].concat([...this.refList]);
  }

  async resendInvite(item: IStafferReferralList) {
    const alert = await this.alertController.create({
      header: 'Resend Invite',
      cssClass: 'alert-modal',
      message: `Resend invitation to ${item.displayName}`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',

        }, {
          text: 'Yes',
          handler: () => {
            this.formSubmitted.emit(item);
          }
        }
      ]
    });
    await alert.present();

  }
  
  addNew() {
    this.addAnother.emit();
  }

  toggleSearch() {
    this.enableSearch = !this.enableSearch;
  }

  clearSearch(event) {
    this.enableSearch = false;
    this.searchQuery = '';
    this.refList = [...this.allReferrals];
    event.stopPropagation();
  }

  onFieldChangeActiveRef() {
    if (!this.searchQuery) {
      this.refList = [...this.allReferrals];
      this.enableSearch = false;
    } else {
      this.refList =
        this.allReferrals.filter((referral) => {
          return referral.referral ? referral.referral.displayName.includes(this.searchQuery) : referral.displayName.includes(this.searchQuery);
        });
    }
  }

  importContact() {

    if (!this.isMobileDevice) {
      this.popupService.showModal({
        heading: 'Error',
        message: 'To import contacts, login with mobile device',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/profile.png'
      });
      return;

    }


    if (this.userData.profile_approved) {
      this.router.navigateByUrl('talent-dashboard/import-referrals');

    } else {
      this.router.navigateByUrl('onboarding-staffer/import-referrals');

    }
  }

  formatStatus(status: string) {
    if(status == 'Invite sent')
      return 'Invited';
    return status
  }

  processForm() {
    if (!this.referralForm.value.name) {
      this.popupService.showModal({
        heading: 'Error', message: 'Please enter name', btn: 'Dismiss', navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
      return;
    }

    if (!this.referralForm.valid || (!this.referralForm.value.email && !this.referralForm.value.phoneNumber)) {
      this.popupService.showModal({
        heading: 'Error', message: 'Please enter an email or phone number', btn: 'Dismiss', navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      });
      return;
    }
    const payload = {
      displayName: this.referralForm.value.name,
      email: this.referralForm.value.email,
      phoneNumber: this.referralForm.value.phoneNumber
    };
    if (!payload.phoneNumber) { delete payload.phoneNumber; }
    if (!payload.email) { delete payload.email; }

    if (!payload.phoneNumber && !payload.email) {
      this.popupService.showPopup('Error', `Enter email or phone number `)

    }
    
    const mobRegex = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     
    if(payload.email && !emailRegex.test(payload.email)){
      this.popupService.showPopup('Error', `Invalid Email`)
      return
    }
    else if(payload.phoneNumber && (!mobRegex.test(payload.phoneNumber) ||  payload.phoneNumber.length > 15 ) ){
      this.popupService.showPopup('Error', `Invalid Number`)
      return
    }
     
    else {
      this.referralForm.reset();
      this.referralAdded.emit(payload);  
      }
  }
}
