<ion-row class="p0">
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <p class="input-label">
        Patient ID
        <span (click)="showNewPatientForm()" class="add-link"><i class="fa fa-plus"></i> Add New</span>
      </p>
    </div>
  </ion-col>
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <p class="input-label">
        Postal code
      </p>
    </div>
  </ion-col>
</ion-row>


  <app-shift-patient
  [entityId]="entityId"
  [availablePatients]="availablePatients"
  (onPatientChange)="handlePatientChange($event)"
  ></app-shift-patient>

  