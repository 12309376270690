import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITalentJob } from '../../interfaces/talent-job.interface';
import { NotesComponent } from '../../../shared/components/notes/notes.component';
import { UtilService } from '../../../shared/services/util.services';
import { RecurringShiftsTimingComponent } from '../../../shared/components/recurring-shifts-timing/recurring-shifts-timing.component';
import { PopupService } from '../../../../modules/shared/services/popup.service';
import { UserService } from '../../../../modules/shared/services/user.service';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { ImagesService } from 'src/modules/shared/services/images.service';
import { TalentDashboardUtilService } from '../../services/talent-dashboard-util.service';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { BulletinsComponent } from 'src/modules/shared/bulletins/bulletins.component';
import { IBulletin } from 'src/modules/shared/interfaces/bulletin.interface';


@Component({
  selector: 'app-shift-card',
  templateUrl: './shift-card.component.html',
  styleUrls: ['./shift-card.component.scss']
})
export class ShiftCardComponent implements OnInit {
  @Input() userData: any;
  // job to show in the card
  @Input() jobItem: any;
  // when user applies on job
  @Output() jobApplied = new EventEmitter<ITalentJob>();
  // when user decline a job
  @Output() jobDeclined = new EventEmitter<ITalentJob>();
  // when user unapply from a job
  @Output() jobUnApplied = new EventEmitter<ITalentJob>();

  // this is a flag for recurring shifts which would indicate weather the
  // timings for all the jobs are similar or not
  areTimingsSimilarForAllShifts: boolean;
  profilePicture: any;
  shiftStartTimeFormatted: string;
  startTime: any;
  endTime: any;
  hasStafferStartedAJob: any;
  isStafferConfirmedShift: any;
  humanizeShiftTime: any;
  duration: any;
  potentialEarning: any;
  distanceFromShift: string;
  timeLeft: boolean;
  currentUserIndex: any;
  source: any;
  initiator: any;
  isShiftStarted: any;
  shiftAddressList: string[] = [];
  constructor(private talentJobFormatService : TalentJobFormatService, private talentDashboardUtilService: TalentDashboardUtilService, private imagesService: ImagesService, private utilService: UtilService, private modalController: ModalController, private popupService: PopupService, private userService: UserService) {}

  ngOnInit() {
    console.log('jobitem: ', this.jobItem);
  }

  ngOnChanges() {
    if(this.jobItem && this.userData) {
      this.profilePicture = this.getProfilePic(this.jobItem.entity.creator.profileUrl);
      this.shiftStartTimeFormatted = moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone).format('dddd MMMM DD');
      this.startTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true);
      this.endTime = this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true);
      this.hasStafferStartedAJob = this.talentDashboardUtilService.hasStafferStartedAJob(this.userData._id, this.jobItem.staffersAndShifts);
      this.isStafferConfirmedShift = this.talentDashboardUtilService.isStafferConfirmedShift(this.jobItem, this.userData);
      this.humanizeShiftTime = this.talentJobFormatService.humanizeShiftTime(this.jobItem.shiftStartTime, this.hasStafferStartedAJob);
      this.duration = this.talentJobFormatService.getJobDuration(moment.unix(this.jobItem.shiftStartTime).tz(this.jobItem.timeZone), moment.unix(this.jobItem.shiftEndTime).tz(this.jobItem.timeZone));
      this.duration = this.talentJobFormatService.formatDuration(this.duration);
      this.potentialEarning = this.talentDashboardUtilService.getcalculatedPotentialEarning(this.jobItem);
      this.distanceFromShift = this.utilService.calculateDistanceFromShift(this.jobItem.companyAddress, this.userData.address);
      this.timeLeft = this.talentJobFormatService.getTimeDifference(this.jobItem.shiftStartTime) < 0 ? true : false;
      this.source = this.initiator;
      this.shiftAddressList = this.utilService.fetchAddressesFromPatientList(this.jobItem);      
    }
  }
  getProfilePic(profileUrl: { [key: string]: string }) {
    return this.imagesService.getProfilePic(profileUrl);
  }

  async showStatPayShifts(jobItem: ITalentJob) {
    if (jobItem.recurringShift.isTrue == true) this.utilService.showStatPayShifts(jobItem, 'staffer');
    else return;
  }

  showLocationOnMap(lat: number, lng: number, name: string) {
    this.utilService.launchAddressOnMap(lat, lng, name);
  }

  async showShiftAndSitenotes(item: { [key: string]: any }) {
    const myModal = await this.modalController.create({
      component: NotesComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        item
      }
    });
    return await myModal.present();
  }

  async viewShiftTimings(jobItem: ITalentJob) {
    const myModal = await this.modalController.create({
      component: RecurringShiftsTimingComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        jobItem
      }
    });
    return await myModal.present();
  }

  async showBulletins(bulletins: IBulletin, entityId : string) {
    await this.popupService.viewShiftBulletins(bulletins, entityId);
  }

  respondToJob(jobItem: ITalentJob) {
    this.jobApplied.emit(jobItem);
  }

  unApplyFromJob(jobItem: ITalentJob) {
    this.jobUnApplied.emit(jobItem);
  }

  declineJob(jobItem: ITalentJob) {
    this.jobDeclined.emit(jobItem);
  }

  viewRecurringPropertiesOfShift(jobItem: ITalentJob, heading: string, showUnitNumber: boolean) {
    this.talentJobFormatService.viewRecurringProperties(jobItem, heading, showUnitNumber);
  }  

  viewNotes(type: 'shift' | 'site') {
    
    let heading = '';
    let message = '';

    if (type === 'shift') {
     heading = 'Shift notes';
     message = this.jobItem.shiftNotes;
    }
    if (type === 'site') {
     heading = 'Site notes';
     message = this.jobItem.siteNotes || this.jobItem.entity.siteNotes;
    }

    this.popupService.showModal({heading: heading, message: message, btn: 'Dismiss', navigateRoute: '',
    imgURL: ''});
  }
}
