<div class="main-container">

    <ion-list *ngIf="isLoading">
        <ion-list-header>
            <ion-skeleton-text [animated]="true" style="width: 80px"></ion-skeleton-text>
        </ion-list-header>
        <ion-item>
            <ion-label>
                <h3>
                    <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
                </h3>
                <p>
                    <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
                </p>
                <p>
                    <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
                </p>

                <p>
                    <ion-skeleton-text [animated]="true" style="margin-left:40px;width: 80%;"></ion-skeleton-text>
                </p>
            </ion-label>
            
        </ion-item>
    </ion-list>
    <div class="content-container" *ngIf="!isLoading">
        <div class="heading">
            <span class="analytics-number">{{cardData.totalShifts}}</span><span
                class="text">{{cardData.headingText}}</span>
        </div>
        <div class="content">
            <div class="analytics-detail">
                <div *ngFor="let analytics of cardData.analyticsData" class="detail-item">
                    <div><i class="fas {{analytics.icon}} icon"></i><span class="text">{{analytics.text}}</span></div>
                    <p *ngFor="let subAnalytics of analytics.subAnalytics" class="subanalytics">{{subAnalytics.text}}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="cardData.navigateLinkText" (click)="navigateToRoute(cardData.navigateRoute)" class="footer">
            <span class="text">{{cardData.navigateLinkText}}</span><i class="fas fa-arrow-circle-right icon"></i>
        </div>
    </div>

</div>