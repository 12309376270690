import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'shared-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnChanges {
  @Input() checked: boolean;
  @Input() disabled: boolean=false;
  @Output() toggleChange = new EventEmitter<{
    value: boolean,
  }>();
  value: boolean;
  showbar: boolean = true;

  constructor() { 

    this.showbar  = true;
  }
  ngOnChanges(e) {
    this.value = this.checked;
    this.showbar = this.value;

  }

  onChange() {
    this.showbar = this.value;

    this.toggleChange.emit({
      value: this.value
    });
  }

}
