import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { UtilService } from 'src/modules/shared/services/util.services';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { AddressModificationService } from '../../services/address-modification.service';

@Component({
  selector: 'shared-address-map-v2',
  templateUrl: './address-map-v2.component.html',
  styleUrls: ['./address-map-v2.component.scss'],
})
export class AddressMapComponentV2 implements OnChanges, AfterViewInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @Input() googlePlace:  google.maps.places.PlaceResult;
  @Input() address: { [key: string]: string };
  @Output() onLocationChanged = new EventEmitter<google.maps.places.PlaceResult>();

  map: google.maps.Map; // map reference
  marker: google.maps.Marker; // marker reference

  constructor(
 ) { }

  ngOnChanges() {

    if(this.googlePlace){
      this.loadMap();
    }
  }

  ngAfterViewInit() {
    console.log(this.address);
    console.log(this.googlePlace);
    this.loadMap();
   
  }
  async loadMap() {

    let options: google.maps.MapOptions;

    if(this.googlePlace){
      // if google place is passed, no need to call the geocoder api
      options = {
          center: (this.googlePlace.geometry && this.googlePlace.geometry.location) ? this.googlePlace.geometry.location 
          : {lat: 0, lng: 0},
          zoom: 18,
          disableDefaultUI: false,
        };

    } else if(this.address){
      // use geocoder api to find the google place

      const coords = await this.fetchGeoCoordsFromAddress(this.address);

        options = {
          center: new google.maps.LatLng(coords.lat, coords.lng),
          zoom: 18,
          disableDefaultUI: false
        };

      }
    
      this.renderMap(options);

  }

  renderMap(options: google.maps.MapOptions){
     if(!this.map){
        this.map = new google.maps.Map(this.mapElement.nativeElement, options);
        // init marker
        this.marker = new google.maps.Marker({
          map: this.map,
          draggable: true,
          animation: google.maps.Animation.DROP,
          position: options.center
        });

      //  add event listener to marker
      this.marker.addListener('dragend', () => {
        this.handleMarkerPositionChange();
      });

      }else{
        this.map.setOptions(options);
        this.marker.setPosition(options.center);
      }

      
  }

  handleMarkerPositionChange() {
    this.map.setCenter(this.marker.getPosition());
    this.map.panTo(this.marker.getPosition());
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ 'location': this.marker.getPosition() }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
         this.onLocationChanged.emit({name: '', ...results[0]});
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  fetchGeoCoordsFromAddress(address): Promise<{lat: number; lng: number}>{

    return new Promise((resolve, reject)=>{
       const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ 'address': this.address.street }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        // unable to load geocoords
        return resolve({lat: 0, lng: 0})
      }

        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        return resolve({lat, lng});

      });
    })

    
  }  

}
