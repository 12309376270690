import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';

@Component({
  selector: 'app-shift-patient',
  templateUrl: './shift-patient.component.html',
  styleUrls: ['./shift-patient.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShiftPatientComponent),
      multi: true
    }
  ]
})
export class ShiftPatientComponent implements OnInit,OnChanges {

  @Input() entityId: string;
  @Input() availablePatients: HomecarePatient[] = [];
  @Output() onPatientChange: EventEmitter<HomecarePatient> = new EventEmitter();
  @Input() patient?: HomecarePatient;
  patientAddress: string = "";
  selectedPatient: HomecarePatient;


  constructor(
    private utilService: BroadcastJobsUtilService
  ) { }

  ngOnInit() {
    if(this.patient){
      this.selectedPatient = this.patient
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.patient){
      this.selectedPatient = this.patient
    }
  }

  handlePatientIdChange(){
    this.onPatientChange.emit(this.selectedPatient);
  }

  handleAddressChange(address){
    const formattedAddress = this.utilService.getFormatedAddress(address);
    if(this.selectedPatient){
      this.onPatientChange.emit({id: this.selectedPatient.id, address: formattedAddress })
    }
  }

  trackByPatient(i: number, patient: HomecarePatient): string {
    return patient.id;
  }

}