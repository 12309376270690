import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService } from 'src/modules/shared/services/local-storage.service';
import * as authActions from '../actions/auth.actions';
import * as talentDashboardActions from '../../../talent-dashboard/+store/actions/talent-dashboard.actions';
import { exhaustMap, map, catchError, tap, switchMap, mergeMap, first } from 'rxjs/operators';
import { of, EMPTY, from, iif } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { IUser } from '../../interfaces/user.interface';
import { OnBoardingUtilService } from 'src/modules/shared/services/onboarding-util.service';
import { UserService } from 'src/modules/shared/services/user.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { PushNotificationService } from 'src/modules/shared/services/push-notification.service';
import { AppInfoService } from 'src/modules/shared/services/app-info.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { AnalyticsService } from 'src/modules/shared/services/analytics.service';
import { LocalStorageKeys } from 'src/modules/shared/enums/local-storage-keys.enum';
import { getUserIdFromInfo } from '../auth-selector';
import { select, Store } from '@ngrx/store';
import { IAuthState } from '../auth.state';
@Injectable({
  providedIn: 'root'
})
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private loadingService: LoadingService,
    private onBoardingUtilService: OnBoardingUtilService,
    private popupService: PopupService,
    private authenticationService: AuthenticationService,
    private pushNotificationService: PushNotificationService,
    private appInfoService: AppInfoService,
    private utilService: UtilService,
    private analyticsService: AnalyticsService,
    private store: Store<IAuthState>,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginAction),
      exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
      exhaustMap(({ action, loader }) =>
        this.authenticationService.loginViaEmail(action.credentials).pipe(
          tap(
            async () => await this.loadingService.hideLoading(loader),
            async () => await this.loadingService.hideLoading(loader)
          ),
          map(data => authActions.loginSuccessAction(data)),
          catchError(error => of(authActions.loginFailureAction({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccessAction),
      tap(action => this.localStorageService.setLoggedInUserToken(action.token)),
      map(_ => authActions.getUserInfoAfterLoginAction())
    )
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginFailureAction),
        tap(action =>
          this.popupService.showModal({
            heading: 'Error',
            message: action.error.error.message ? action.error.error.message : 'Error connecting to Staffy, please try again in a few minutes.',
            btn: 'Ok',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
          })
        )
      ),
    { dispatch: false }
  );

  userInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserInfoAction),
      exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
      switchMap(({ loader, action }) =>
        this.userService.me().pipe(
          tap(
            async () => await this.loadingService.hideLoading(loader),
            async () => await this.loadingService.hideLoading(loader),
          ),
          map((data: IUser) => authActions.getuserInfoSuccessAction({ user: data, redirectLink: action.redirectLink })),
          catchError(error => of(authActions.getUserInfoFailureAction({ error })))
        )
      )
    )
  );

  userInfoWoLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserInfoActionWithoutLoader),
      switchMap(() =>
        this.userService.me().pipe(
          map((data: IUser) => authActions.getuserInfoSuccessAction({ user: data })),
          catchError(error => of(authActions.getUserInfoFailureAction({ error })))
        )
      )
    )
  );

  userInfoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.getuserInfoSuccessAction),
        tap(action => {
          this.localStorageService.setLoggedInUserInfo(action.user);
          if(action.redirectLink){
            this.router.navigate([action.redirectLink]);
          }
        })
      ),
    { dispatch: false }
  );

  userInfoAfterCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserInfoAfterCreateUserAction),
      exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
      switchMap(({ loader, action }) =>
        this.userService.me().pipe(
          tap(
            async () => await this.loadingService.hideLoading(loader)
          ),
          map((data: IUser) => authActions.getUserInfoAfterLoginSuccessAction({ user: data })),
          catchError(error => of(authActions.getUserInfoFailureAction({ error })))
        )
      )
    )
  );

  addPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.addPatientAction),
      switchMap(async (action) => {
        const user: any = await this.localStorageService.getLoggedInUserInfo()
        if(user.entityId.homecareClient.isTrue){
          user.entityId.homecareClient.patients.push(action.patient)
        }
        return authActions.getuserInfoSuccessAction({ user });
      })
    ),
  );

  updatePatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updatePatientAction),
      switchMap(async (action) => {
        const user: any = await this.localStorageService.getLoggedInUserInfo()
        if(user.entityId.homecareClient.isTrue){
          const index = user.entityId.homecareClient.patients.findIndex(p => p._id === action.patient._id);
          if (index !== -1) {
            user.entityId.homecareClient.patients[index] = action.patient;
          }
        }
        return authActions.getuserInfoSuccessAction({ user });
      })
    ),
  );

  removePatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.removePatientAction),
      switchMap(async (action) => {
        const user: any = await this.localStorageService.getLoggedInUserInfo()
        if(user.entityId.homecareClient.isTrue){
          user.entityId.homecareClient.patients = user.entityId.homecareClient.patients.filter(p => p._id !== action.patient._id);
        }
        return authActions.getuserInfoSuccessAction({ user });
      })
    ),
  );

  updateUserPartialState$ = createEffect(() => this.actions$.pipe(
      ofType(authActions.updateUserPartialState),
      switchMap((action) => this.store.pipe(select(getUserIdFromInfo), first(), map(userId => ({ action, userId })))),
      switchMap(({ action, userId }) => 
          this.userService.updateUser(userId, action.partialUserState).pipe(
              map(() => authActions.updateUserPartialStateSuccess({partialUserState: action.partialUserState}))
          )
      ),
  ));

  updateUserPersonalInfoState$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.updateUserPersonalInfoState),
    switchMap((action) => this.store.pipe(
      select(getUserIdFromInfo), 
      first(), 
      map(userId => ({ action, userId }))
    )),
    exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, ...action })))),
    switchMap(({ action, userId, loader }) => {
     
      const stateFix = {
        ...action.partialPersonalInfoState,
      };

      if (action.partialPersonalInfoState.address && typeof action.partialPersonalInfoState.address.street !== 'string') {
        stateFix.address = {
          ...action.partialPersonalInfoState.address,
          street: action.partialPersonalInfoState.address.street.formatted_address
        }
      }
      

      return this.userService.updateUser(userId, action.partialPersonalInfoState).pipe(
        map(() => authActions.updateUserPartialStateSuccess({partialUserState: stateFix})),
        tap(async (res) => {

          if (action.successRoute) {
            this.router.navigateByUrl(action.successRoute);
          }
          await this.loadingService.hideLoading(loader);
          
        }),
      )
    }),
  ));

  // DUPLICATE
  userInfoAfterCreateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.getUserInfoAfterCreateUserSuccessAction),
        tap(action => this.localStorageService.setLoggedInUserInfo(action.user)),
        tap(action => this.utilService.setTimeZone(action.user.address.province)),
        tap(action => this.pushNotificationService.initPushNotification(action.user)),
        tap(action => this.onBoardingUtilService.checkForVouchersAndCode(action.user)),
        tap(action => this.appInfoService.checkForAppInfo(action.user)),
        tap(action => {
          action.successRoute && this.router.navigate([action.successRoute]);
        })
      ),
    { dispatch: false }
  );

  getUserInfoAfterLoginAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getUserInfoAfterLoginAction),
      exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
      switchMap(({ loader }) =>
        this.userService.me().pipe(
          tap(
            async () => await this.loadingService.hideLoading(loader),
          ),
          map((data: IUser) => authActions.getUserInfoAfterLoginSuccessAction({ user: data })),
          catchError(error => of(authActions.getUserInfoFailureAction({ error })))
        )
      )
    )
  );

  getUserInfoAfterLoginSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.getUserInfoAfterLoginSuccessAction),
        tap(action => this.localStorageService.setLoggedInUserInfo(action.user)),
        tap(action => this.appInfoService.checkForAppInfo(action.user)),
        tap(action => this.pushNotificationService.initPushNotification(action.user)),
        tap(action => this.utilService.setTimeZone(action.user.address.province)),
        tap(action => this.appInfoService.checkForAppInfo(action.user)),
        tap(action => {
          if (action.user.type === UserTypeEnum.EMPLOYER) {
            // Company is Logged In
            if (action.user.resetPasswordToken && action.user.resetPasswordToken.length > 0) {
              // if resetPasswordToken is present, redirect to set password screen
              this.router.navigate(['/shared/set-password/' + action.user.resetPasswordToken]);
            }
            else this.router.navigate(['/company-dashboard/home']);
          } else {
            this.analyticsService.setUserProperty('user_type', 'Work');
            if(!action.user.isSignupProcessComplete && action.user.currentNavStep){
              this.onBoardingUtilService.resolveUrl(action.user);
              }
            else 
              this.router.navigate(['/talent-dashboard/home']);
          }
        })
      ),
    { dispatch: false }
  );

  redirectToLoginActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.redirectToLoginAction),
        tap(_ => this.router.navigate(['/auth/login']))
      ),
    { dispatch: false }
  );
  redirectToDashboardAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.redirectToDashboardAction),
        tap(action => {
          if (action.user.type === UserTypeEnum.EMPLOYER) {
            this.router.navigate(['/company-dashboard/home']);
          } else {
            if(!action.user.isSignupProcessComplete && action.user.currentNavStep != '/talent-dashboard/home')
            this.onBoardingUtilService.resolveUrl(action.user);
            else 
              this.router.navigateByUrl('/talent-dashboard/home');
            }
        })
      ),
    { dispatch: false }
  );

  blockUserAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.blockUserAction),
      exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
      switchMap(({ loader, action }) =>
        this.userService.markBlocked().pipe(
          tap(
            async () => await this.loadingService.hideLoading(loader),
            async () => await this.loadingService.hideLoading(loader)
          ),
          map(() => authActions.logOutAction())
        )
      )
    )
  );

  logOutAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logOutAction),
      mergeMap(() => [
        talentDashboardActions.resetTalentDashboardStoreAction(), 
        authActions.resetStoreAction(), 
        authActions.removeDeviceTokenAction(),
        authActions.clearCacheAction()
      ])
    )
  );

  setStoreAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.setStoreAction),
        tap((action) => {
          if (action.token) {
            this.localStorageService.setLoggedInUserToken(action.token);
          }
          if (action.user) {
            this.localStorageService.setLoggedInUserInfo(action.user);
          }
        }),
      ),
    { dispatch: false }
  );
  
  resetStoreAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.resetStoreAction),
        exhaustMap(action => this.loadingService.showLoading().pipe(exhaustMap(loader => of({ loader, action })))),
        tap(({ loader }) => this.localStorageService.logout()),
        tap(__ => this.router.navigate(['auth/login'], { replaceUrl : true})),
        tap(({ loader }) => this.loadingService.hideLoading(loader)),
        switchMap(() => EMPTY)
      ),

    { dispatch: false }
  );

  removeDeviceTokenAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.removeDeviceTokenAction),
        tap(action => this.pushNotificationService.removePushNotificationToken())
      ),
    { dispatch: false }
  );

  clearCache$ = createEffect(
    () => 
    this.actions$.pipe(
      ofType(authActions.clearCacheAction),
      tap(action => this.utilService.clearCashedJobs.next('true')),
    ),
    { dispatch: false }
  )

  completedIntroductoryStepsEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.completedIntroductoryStepsAction),
        tap(action => this.localStorageService.setSingleKey(LocalStorageKeys.HAS_COMPLETED_INTRODUCTORY_STEPS, true))
      ),
    { dispatch: false }
  );
}
