import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NewPatientFormComponent } from 'src/modules/shared/components/new-patient-form/new-patient-form.component';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { PopupService } from '../../services/popup.service';


@Component({
  selector: 'app-shift-patient-v2',
  templateUrl: './shift-patient-v2.component.html',
  styleUrls: ['./shift-patient-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShiftPatientV2Component),
      multi: true
    }
  ]
})
export class ShiftPatientV2Component implements OnInit, ControlValueAccessor {

  @Input() entityId: string;
  @Input() availablePatients: HomecarePatient[] = [];
  @Input() selectedPatientValue: HomecarePatient = null;
  @Input() colSize: number;
  @Input() icon: string;
  @Input() control: FormControl;



  @Output() onPatientChange: EventEmitter<HomecarePatient> = new EventEmitter();

  // patientAddress: string = "";
  // selectedPatient: HomecarePatient = null;

  _patientId: string = null;
  _patientAddress: IUserAddress = null;

  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor(
    private modalController: ModalController,
    private utilService: BroadcastJobsUtilService,
    private popupService: PopupService
  ) { }

  get patientId(): string {
    return this._patientId;
  }

  set patientId(id: string) {
    this._patientId = id;
    this.onPatientChange.emit({id: this._patientId, address: this._patientAddress});
    this.propagateChange({id: this._patientId, address: this._patientAddress});
  }

  get patientAddress(): IUserAddress {
    return this._patientAddress;
  }

  set patientAddress(address: IUserAddress) {
    this._patientAddress = address;
    this.onPatientChange.emit({id: this._patientId, address: this._patientAddress});
    this.propagateChange({id: this._patientId, address: this._patientAddress});
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  handlePatientIdChange(patientId: string){
    // extract the address from available patients
    this.patientId = patientId;
    const patient = this.availablePatients.find(patient => patient.id === this.patientId);
    // assign it to patientAddress
    this.patientAddress = patient.address;
  }

  handleAddressChange(address){
    const formattedAddress = this.utilService.getFormatedAddress(address);
    this.patientAddress = formattedAddress;
  }

  async showNewPatientForm(){
    const patientModal = await this.modalController.create({
      component: NewPatientFormComponent,
      cssClass: 'patientsModal',
      backdropDismiss: true,
      componentProps: {
        entityId: this.entityId,
        addPatientAddress: (newPatient: {id: string, address: IUserAddress}) => {
          this.availablePatients.push(newPatient);
          this.patientId = newPatient.id;
          this.patientAddress = newPatient.address
        }
      }
    });

    patientModal.present();
    
    patientModal.onDidDismiss().then(async (dataReturned) => {
      const modalDismissState = dataReturned.data;

      if(!modalDismissState) {
        return;
      }
      this.showPopup(modalDismissState);
  });
  }

  trackByPatient(i: number, patient: HomecarePatient): string {
    return patient.id;
  }

  
  //Form Accessor Functions for custom components
  writeValue(value: {id: string; address: IUserAddress}): void {
    if(!value) return;
    this._patientId = value.id;
    this._patientAddress = value.address;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  
  showPopup(state: string) {

    const popupObject = {
      'success' : {
        heading:  'Success',
        message: 'Patient has been added successfully', 
        btn: 'Dismiss', 
        navigateRoute: null,
        imgURL: 'assets/images/thumbs-up.png'
      }, 

      'fail' : {
        heading:  'Error',
        message: 'Failed to add patient. Please try again.', 
        btn: 'Dismiss', 
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png'
      
      }
    };

    this.popupService.showModal(popupObject[state]);
  }

  resetPatientValues() {

    //Reset the form controls, if it exists
    if (this.control) {
      this.control.reset();
    }
    //Reflect the chnage at component level UI. Set id and address to null
    this.writeValue({id: null, address: null }); 
  }
}

