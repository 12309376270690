<div class = "staffer">
  <div class="child specificClass">
    <div class = "container heading">
      <div class = "header">
        <div class = "small-font">{{ action }}</div>
        <h1>
           Unit Number
        </h1>
      </div>
      <div class = "unit-number-list">
        <div class = "flex-column">
          <div class = "bold-font row-header width-60">
            Shift  
          </div>
  
          <div class = "bold-font row-header width-40">
            Unit Number  
          </div>
        </div>
        
        <div class = "unit-list-card" *ngFor = "let job of jobItem">
          <div class="regular-font column-header width-60">
             <div class = "bold-font">{{ job.skill }}</div>   
            {{job.shiftStartTime | momentPipeUnix:'MMM DD/YY'}}, {{ job.shiftStartTime | momentPipeUnix:'hh:mma' }} - {{ job.shiftEndTime | momentPipeUnix:'hh:mma' }}
          </div>

          <div class = "row-header width-40">
            <ion-select ok-text = "Select" [interfaceOptions] = "{cssClass: 'references-select'}"  [(ngModel)] = "job.selectedUnitNumber"  name = "{{ job.selectedUnitNumber }}" (ionChange)="handleUnitNumberChange(job)">
              <ion-select-option value= "" disabled>{{ (shiftUnitNumber
                ? 'OPEN_REQUESTS.Edit Unit Number' : 'OPEN_REQUESTS.Add Unit Number') | translate }}
              </ion-select-option>
              <ion-select-option class = "selection" *ngFor = "let unitNumber of job.unitNumberList" [value] = "unitNumber">{{unitNumber}}</ion-select-option>
            </ion-select>
          </div>

      </div>
      </div>
    </div>

    <div class="buttons-section" >
      <ion-button class = "btn remove-bg" (click)='closeModal()'>Cancel</ion-button>
      <ion-button color="basic" class = "btn" (click)='handleSaveUnitNumber()'>Save</ion-button>
    </div>
  </div>
</div>
 