<ion-content class="main-container">
  <ion-grid>


    <form [formGroup]="cancellationForm">
      <ion-row class="header">
        <ion-col size="12" class="heading">
          <i class="fa fa-times cross-icon" (click)="onCancelModal()"></i>
          <p>
            Select the recurrences you want to cancel
          </p>
        </ion-col>
        <ion-col size="12" class="selected-shift-count">
          <p>
            {{getSelectedShiftCount()}} shifts selected
          </p>
        </ion-col>

      </ion-row>

      <div class="list">
        <app-recurring-shift-list
          (onToggleAllCheckBoxes)="onToggleAllCheckBoxes($event)" (onToggleCheckBox)="onToggleCheckBox($event)"
          [config]="recurringShiftConfig" [data]="recurringShiftData"></app-recurring-shift-list>
      </div>

      <ion-row class="footer">
        <ion-col size="6" class="padding-left-20">
          <app-form-textarea [placeholder]="'Please provide a cancellation reason'"
            formControlName="cancellationReason"></app-form-textarea>
        </ion-col>
        <ion-col size="4" class="padding-right-20 submit-btn">
          <app-form-submit-button [showIcon]="false" [labelText]="'Cancel Shifts'" (onClickHandle)="onCancelShifts()">
          </app-form-submit-button>
        </ion-col>

      </ion-row>
    </form>

  </ion-grid>
</ion-content>