import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'app-filled-request-contractor-list',
  templateUrl: './filled-request-contractor-list.component.html',
  styleUrls: ['./filled-request-contractor-list.component.scss'],
})
export class FilledRequestContractorListComponent implements OnInit {

  @Input() jobItem;
  @Input() userData: IUser; 

  constructor() { }

  ngOnInit() {
    console.log('updated: ', this.jobItem);
    this.loadInitialContractors();
  }

  loadInitialContractors() {
    //Add a property to show first 2 contractors in each active shift 
    this.jobItem = this.jobItem.map(( staffer) => {
      staffer['confirmedContractorsList'] = staffer.staffersAndShifts.slice(0,2);
      return staffer;
    });
  }

}
