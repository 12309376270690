<div class = "banner" *ngIf = "!subTitle && !userData?.profile_approved && !userData?.isSignupProcessComplete; else header" >
  <ion-buttons *ngIf="(showMenu | async) && userData?.isSignupProcessComplete" slot="start">
    <ion-menu-button>
      <i class="fas fa-bars menu-icon"></i>
    </ion-menu-button>
  </ion-buttons>
  
  <div class = "img-div">
    <img src = "assets/images/hands-up.png">
  </div>

  <div class = "text-div">
    <p class = "regular-text">You're on a path to work when and where you want as an <span>independent contractor.</span></p>    
    <p style="margin-top:8px!important;" class = "heavy-text" (click) = "navigateToView('instruction')">Complete my account<i class = "fa fa-arrow-right"></i></p>
  </div>
</div>


<ng-template #header>
  <div class = "header" *ngIf = "userData?.type == 'staffer'"> 
    <ion-buttons *ngIf="subTitle || userData?.isSignupProcessComplete || userData?.profile_approved" slot="start">
      <ion-menu-button>
        <i class="fas fa-bars menu-icon"></i>
      </ion-menu-button>
    </ion-buttons>

      <div class = "content">
        <div class = "name" *ngIf = "!subTitle && userData?.profile_approved">
          <div class = "greeting">
            <p class = "greet">Welcome,</p>
            <p *ngIf = "userData?.profile_approved == true" class = "text">{{ heading }}</p>
          </div>  
          <div class = "upcoming" (click) = "navigateToView('upcoming')">
            <ion-button color = "basic" *ngIf = "userData?.profile_approved == true"><span *ngIf = "!count || count == 0">no</span><span *ngIf = "count > 10">10+</span><span *ngIf = "count < 10 && count > 0">{{ count }}</span>&nbsp;upcoming shifts</ion-button>
          </div>
        </div>
        <div class = "underReview" *ngIf = "(!subTitle && userData?.isSignupProcessComplete && userData?.profile_approved == false) || (!subTitle && userData?.isUnderReview == true && userData?.profile_approved == false)">
          <img src = "assets/images/search.png" class = "search">
          <div>
            <p>Your account is pending approval.</p>
            <p style="margin-top:15px!important;" class="heavy-text" (click)="navigateToView('onboarding-dashboard')">Edit your profile<i
                class="fa fa-arrow-right" style="margin-left: 5px"></i></p>
          </div>

        </div>

        <div class = "name" *ngIf = "subTitle">
          <div class = "greeting">
            <p class = "text">{{ subTitle }}</p>
          </div>  
          <div class = "upcoming" *ngIf = "showUpcomingCount" (click) = "navigateToView('upcoming')">
            <ion-button color = "basic" *ngIf = "subTitle != 'Notifications' && userData?.profile_approved == true"><span *ngIf = "!count || count == 0">no</span><span *ngIf = "count > 10">10+</span><span *ngIf = "count < 10 && count > 0">{{ count }}</span>&nbsp;upcoming shifts</ion-button>
          </div>
        </div>
      </div>
      
    <div class="staffer-pic-div" *ngIf = "userData?.profileUrl && showImg && (userData?.isSignupProcessComplete || userData?.profile_approved)">
      <img *ngIf="!userData?.isStafferVerified && userData?.badges.indexOf('covid-19-vaccine') == -1" class="user-img" src="{{userData?.profileUrl.url}}">

      <div *ngIf="!userData?.isStafferVerified && userData?.badges.indexOf('covid-19-vaccine') > -1" style="width: 100%;">
        <img class="user-img" src="{{userData?.profileUrl.url}}">
        <div class="staffy-verified-covid">
          <img src="assets/images/Covid-Badge.svg">
        </div>
      </div>

      <div *ngIf="userData?.isStafferVerified">
        <img class="user-img" style="border: 0 !important; padding: 0 !important"
          src="{{userData?.profileUrl.url}}">

          <img class = "verification-icon" src = 'assets/images/staffy_verified.png'>
          
          <div class="staffy-verified-covid" *ngIf = "userData?.badges.indexOf('covid-19-vaccine') > -1">
            <img src="assets/images/Covid-Badge.svg">
          </div>
          
      </div>
    </div>

  </div>
</ng-template>

<div *ngIf = "userData?.type == 'employer'">
  <div class = "banner">
    <ion-buttons *ngIf="(showMenu | async)" slot="start">
      <ion-menu-button>
        <i class="fas fa-bars menu-icon"></i>
      </ion-menu-button>
    </ion-buttons>
  </div>
</div>
