import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IClientInvoice } from '../../../company-dashboard/interfaces/client-invoice.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { NavController } from '@ionic/angular';
import { UtilService } from '../../services/util.services';
import * as moment from 'moment';

@Component({
  selector: 'shared-client-invoice-card',
  templateUrl: './client-invoice-card.component.html',
  styleUrls: ['./client-invoice-card.component.scss'],
})
export class ClientInvoiceCardComponent implements OnInit {
  @Input() invoice: IClientInvoice;
  @Input() userData: IUser;
  @Output() downloadInvoice = new EventEmitter<IClientInvoice>();
  @Output() payInvoice = new EventEmitter<IClientInvoice>();
  isDisputable = false;
  constructor(
    private navCtrl: NavController,
    private utilService: UtilService,
  ) { }
  dueAmount: number;
  formattedOvertime: string;

  ngOnInit() {
    this.dueAmount = this.utilService.removeExtraDecimalsPlaces(this.invoice.amountDue || 0.00);


    this.invoice.jobs.forEach(job => {
      job.shiftAmount = this.utilService.removeExtraDecimalsPlaces(job.shiftAmount);
      job.staffyFee = this.utilService.removeExtraDecimalsPlaces(job.staffyFee);
      job.shiftTax = this.utilService.removeExtraDecimalsPlaces(job.shiftTax);
      job.sumOfAmounts = this.sumOfAmounts(job);
    });

    this.invoice.timeTracked = this.utilService.getTimeTracked(this.invoice.timeTracked);
    this.formattedOvertime = this.utilService.getTimeTracked(this.invoice.overtime.time.toString());
    this.invoice.staffyFee = this.utilService.removeExtraDecimalsPlaces(this.invoice.staffyFee || 0);
    this.invoice.shiftTax = this.utilService.removeExtraDecimalsPlaces(this.invoice.shiftTax || 0);
    this.invoice.calculatedAmount = this.utilService.removeExtraDecimalsPlaces(this.invoice.calculatedAmount || 0);
    this.invoice.paidAmount = this.utilService.removeExtraDecimalsPlaces(this.invoice.paidAmount || 0.00);
    this.invoice.paidFromWallet = this.utilService.removeExtraDecimalsPlaces(this.invoice.paidFromWallet || 0);
    this.invoice.shiftAmount = this.utilService.removeExtraDecimalsPlaces(this.invoice.shiftAmount || 0);

    this.isDisputable = this.checkDisputeButtonCondition();
  }

  async download() {
    this.downloadInvoice.emit(this.invoice);
  }

  payStafferInvoice() {
    this.payInvoice.emit(this.invoice);

  }



  sumOfAmounts(jobObj) {
    return jobObj ? Number(this.utilService.bigDeci(jobObj.shiftAmount).plus(this.utilService.bigDeci(jobObj.staffyFee).plus(this.utilService.bigDeci(jobObj.shiftTax)))) : '0.00';
  }



  checkDisputeButtonCondition() {
    let showButton = false;


    if (this.userData.customer.hasCreditCard && moment().diff(moment.unix(
      this.invoice.jobs[0].shiftEndTime
    ), 'hours') < 48) {
      showButton = true;
    } else if (!this.userData.customer.hasCreditCard && moment().diff(moment.unix(this.invoice.jobs[0].shiftEndTime), 'hours') < 120) {
      showButton = true;
    }
    return showButton;

  }

  fileDispute() {
    this.navCtrl.navigateForward(`company-dashboard/invoice-file-dispute/${this.invoice._id}`);


  }

}
