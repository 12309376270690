import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ITask } from 'src/modules/authentication/interfaces/task.interface';
import { ModalController } from '@ionic/angular';
import { AddTaskComponent } from '../../../company-dashboard/pages/tasks/add-task/add-task.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { sortBy, toLower } from 'lodash';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';

@Component({
  selector: 'app-shift-tasks',
  templateUrl: './shift-tasks.component.html',
  styleUrls: ['./shift-tasks.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShiftTasksComponent),
      multi: true
    }
  ]
})
export class ShiftTasksComponent implements ControlValueAccessor, OnChanges {
  //Selected task is used in edit shift, it contains tasks that were selected while making broadcast
  @Input() selectedTaskList;
  @Input() availableTasks: ITask[] = [];
  @Input() title : string;
  @Input() entityId: string;
  @Input() icon: string;
  @Input() control: FormControl;
  @Output() onTasksSelectionChange: EventEmitter<ITask[]> = new EventEmitter();
  _tasks: ITask[] = [];
  propagateChange = (_: any) => { };
  propagateTouched = (_: any) => { };

  constructor(
    private modalController: ModalController,
  ) { 
  }
  
  set tasks(value: ITask[]) {
    this._tasks = value;
    this.onTasksSelectionChange.emit(this._tasks);
    this.propagateChange(this._tasks);
  }

  get tasks(): ITask[] {
    return this._tasks;
  }

  ngOnChanges(change): void {
    
    if(!change.availableTasks || !change.availableTasks.currentValue) {
      return;
    }

    if(this.selectedTaskList && this.selectedTaskList.length) {
      this.tasks = this.selectedTaskList;
    }
    const excludedTaskIds = this.tasks.map(task => task._id);
    this.availableTasks = this.availableTasks.filter(task => !excludedTaskIds.includes(task._id));
    this.sortTaskList();  }

  handleTaskSelection(select: HTMLIonSelectElement) {
    const taskId = select.value;
    if (!taskId || taskId === 'Select Task') {
      return;
    }
    const task = this.availableTasks.find(t => t._id == taskId);
    this.tasks.push(task);
    this.availableTasks = this.availableTasks.filter(t => t._id !== task._id);
    this.onTasksSelectionChange.emit(this.tasks);
    // // Set dropdown to "Select Task" to make 
    // // user clear selected tasks are chips not dropdown
    // select.value = 'Select Task';
  }

  handleTaskRemoval(task: ITask) {
    this.tasks = this.tasks.filter(t => t._id !== task._id);
    this.availableTasks.push(task);
    this.sortTaskList();
    this.onTasksSelectionChange.emit(this.tasks);
  }

  async showAddTaskModal(){
    const addTaskModal = await this.modalController.create({
      component: AddTaskComponent,
      cssClass: 'taskModal',
      backdropDismiss: true,
      componentProps: {
        entityId: this.entityId,
      }
    });

    addTaskModal.present();
  }

  sortTaskList() {
    //sort task list by title ignoring the upper/lowercases
    this.availableTasks = sortBy(this.availableTasks, task => toLower(task.title));
  }

  //Form Accessor Functions
  writeValue(value: any): void {
    this._tasks = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
}
