<ion-row class="p0">
<ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <div class = "static-select-width">
        <select [disabled]="!availablePatients.length" [(ngModel)]="selectedPatient" class="position select-font" (change)="handlePatientIdChange()">
          <option [ngValue]="patient" *ngFor="let patient of availablePatients; trackBy:trackByPatient">
            {{patient.id}}
          </option>
        </select>
      </div>
    </div>
  </ion-col>
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <div class = "padding-right-15">
        <app-places-autocomplete [formatedAddress]="selectedPatient?.address?.street" class="style-broadcast" (addressChanged)="handleAddressChange($event)"></app-places-autocomplete>
      </div>
    </div>
  </ion-col>
</ion-row>