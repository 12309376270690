import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-add-new',
  templateUrl: './form-add-new.component.html',
  styleUrls: ['./form-add-new.component.scss'],
})
export class FormAddNewComponent implements OnInit {
  @Input() labelText: string;
  @Input() icon: string;
  @Output() onClickHandler = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  onClick() {
    this.onClickHandler.emit();
  }
  

}
