import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITalentJob } from '../../interfaces/talent-job.interface';
import { ImagesService } from 'src/modules/shared/services/images.service';
import * as moment from 'moment-timezone';
import { UtilService } from 'src/modules/shared/services/util.services';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { RecurringShiftsTimingComponent } from '../../../shared/components/recurring-shifts-timing/recurring-shifts-timing.component';
import { ModalController, AlertController } from '@ionic/angular';
import { TalentDashboardUtilService } from '../../services/talent-dashboard-util.service';
import { IUser } from '../../../../modules/authentication/interfaces/user.interface';
import { forEach } from 'lodash';
import { ISetting } from '../../interfaces/settings.interface';
import { IUpdateUser } from '../../../../modules/authentication/interfaces/update-user.interface';
import { JobService } from '../../../../modules/shared/services/job.service';
import { TalentJobFormatService } from '../../services/talent-job-format.service';
import { TalentJobChecksService } from '../../services/talent-job-checks.service';
import { LoadingService } from 'src/modules/shared/services/loading.service';
import { TalentDashboardService } from '../../services/talent-dashboard.service';
import { TalentJobSearchService } from '../../services/talent-job-search.service';
import { AnalyticsService } from 'src/modules/shared/services/analytics.service';
import { SubSkillService } from 'src/modules/shared/services/subskill.service';
import { ApplyErrorHandler } from 'src/modules/shared/services/apply-error-handler.service';

@Component({
  selector: 'app-available-shifts-cards',
  templateUrl: './available-shifts-cards.component.html',
  styleUrls: ['./available-shifts-cards.component.scss']
})
export class AvailableShiftsCardsComponent implements OnInit {
  @Input() availableShiftsData: {
    // raw data
    results: ITalentJob[];
    totalRecords: number;
  };
  //reuse available shifts view for my application, remove shift from application view if contractor unapplies on the shift
  @Input() isApplication: boolean;
  @Input() userData: IUser;
  @Input() settings: ISetting[];
  @Output() updateUser = new EventEmitter<IUpdateUser>();
  skipItems = 0;
  availableShifts = []; // formated data (initial) // initialAvailableJobs
  availableLoadedJobs = []; // data with pagenation  //availableJobs
  hour24PayAvailableBadge: boolean;
  clientName: string = ''; // search string
  sortByProximity: boolean;
  userIndustry: string;

  noShifts : {
    text: string,
    imageUrl: string,
    subText: string,
    buttonText: string,
    navigateRoute: string
  };

  constructor(
    private imagesService: ImagesService,
    private utilService: UtilService,
    private popupService: PopupService,
    private modalController: ModalController,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private jobService: JobService,
    private jobFormattingService: TalentJobFormatService,
    private jobChecksService: TalentJobChecksService,
    private loadingService: LoadingService,
    private talentDashboardService: TalentDashboardService,
    private jobSearchService: TalentJobSearchService,
    private analyticsService: AnalyticsService,
    private subSkillService: SubSkillService,
    private applyErrorHandler: ApplyErrorHandler
  ) {}

  ngOnInit() {
    this.noShifts = {
      text: 'No application found',
      imageUrl: 'assets/images/sorry1.png',
      subText: '',
      buttonText: '',
      navigateRoute: ''
    }
  }

  ngOnChanges(change) {
    if (change.availableShiftsData && change.availableShiftsData.currentValue && this.availableShiftsData.results) {
      this.clientName = '';
      this.formatAvailableShifts();
      this.availableShifts = [...this.availableShiftsData.results];
      this.loadInitialShifts();
    }

    if (this.settings) {
      this.hour24PayAvailableBadge = this.settings[0] ? this.settings[0].hour24PayAvailableBadge : false;
    }
  }

  toggleStatus() {
    this.sortByProximity = !this.sortByProximity;
    this.sortShifts();
    this.loadInitialShifts();
  }

  searchByClient() {
    this.availableShifts = this.jobSearchService.doLocalSearch(this.availableShiftsData, this.clientName);
    this.sortShifts();
    this.loadInitialShifts();
  }

  clearSearch() {
    this.clientName = '';
  }

  sortShifts() {
    if (this.sortByProximity) {
      this.availableShifts.sort((a, b) => {
        return a.proximity - b.proximity;
      });
    } else {
      // by time
      this.availableShifts.sort((a, b) => {
        return a.shiftStartTime - b.shiftStartTime;
      });
    }
  }

  formatAvailableShifts() {
    this.availableShiftsData.results = this.jobFormattingService.formatAvailableShifts(this.availableShiftsData.results, this.userData);
  }

  loadInitialShifts() {
    // this.sortAvailableShifts();

    this.skipItems = 2;
    // this.loadedAvailableShifts = 0;
    this.availableLoadedJobs = [];

    if (this.availableShifts.length >= 1) {
      this.availableLoadedJobs.push(this.availableShifts[0]);
    }

    if (this.availableShifts.length >= 2) {
      this.availableLoadedJobs.push(this.availableShifts[1]);
    }

    // this.loadedAvailableShifts = this.availableLoadedJobs.length;

    if (this.availableLoadedJobs && this.availableLoadedJobs.length > 0) {
      forEach(this.availableLoadedJobs, (obj, index) => {
        this.addAlreadyAppliedStatus(obj, index);
      });
    }
  }

  getAvailableShifts() {
    if (this.availableShifts.length >= this.availableLoadedJobs.length) {
      const loadedJobs = this.availableShifts.slice(this.skipItems, this.skipItems + 2);

      this.availableLoadedJobs = this.availableLoadedJobs.concat(loadedJobs);
      
      forEach(this.availableLoadedJobs, (obj, index) => {
        this.addAlreadyAppliedStatus(obj, index);
      });

      // this.loadedAvailableShifts = this.availableLoadedJobs.length;
      this.skipItems = this.skipItems + 2;
    }
  }

  addAlreadyAppliedStatus(job, index) {
    for (let i = 0; i < job.isABroadcastRequest.interestedStaffers.length; i++) {
      if (job.isABroadcastRequest.interestedStaffers[i] === this.userData._id) {
        job.isApplied = true;
        break;
      }
    }
  }

  calculatePotentialEarning(job: ITalentJob) {
    return this.talentDashboardUtilService.getcalculatedPotentialEarning(job);
  }

  getProfilePic(profileUrl: { [key: string]: string }) {
    return this.imagesService.getProfilePic(profileUrl);
  }

  getDateRange(jobItem: ITalentJob, i: number) {
    let formatted: string;
    const firstShiftStartTimings = jobItem.jobs[0].shiftStartTime;
    const firstShiftEndTimings = jobItem.jobs[0].shiftEndTime;
    const timeZone = jobItem.jobs[0].timeZone;
    let uniqueFound = false;

    const jobShiftTime = jobItem.jobs.map(job => {
      if (
        moment.unix(firstShiftStartTimings).tz(timeZone).format('hh:mm a') !==
          moment.unix(job.shiftStartTime).tz(timeZone).format('hh:mm a') &&
        moment.unix(firstShiftEndTimings).format('hh:mm a') !== moment.unix(job.shiftEndTime).tz(timeZone).format('hh:mm a')
      ) {
        this.availableShifts[i].similarTimings = false;
        uniqueFound = true;
      } else {
        this.availableShifts[i].similarTimings = uniqueFound == true ? false : true;
      }
      return job.shiftStartTime;
    });

    if (jobShiftTime.length !== 0) {
      formatted = this.utilService.stringifyRecurringShifts(jobItem.jobs[0].timeZone, jobShiftTime);
    }
    return formatted;
  }

  showLocationOnMap(lat: number, lng: number, name: string) {
    this.utilService.launchAddressOnMap(lat, lng, name);
  }

  showShiftAndSitenotes(item: { [key: string]: any }) {
    this.popupService.showModal({
      heading: 'Shift Note',
      message: item.shiftNotes || item.entity.siteNotes,
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: ''
    });
  }

  async viewShiftTimings(jobItem: ITalentJob) {
    const myModal = await this.modalController.create({
      component: RecurringShiftsTimingComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        jobItem
      }
    });
    return await myModal.present();
  }

  async onJobApply(jobItem, index) {
    const result = await this.jobChecksService.performChecks(jobItem, this.settings, this.userData, this.updateUser);
    if (result.canApply) {
      try {
        await this.applyOnJob(jobItem, result.rcmpAccepted);
        this.updateJobAppliedStatus(jobItem, index, true);
      } catch (err) {}
    }
  }

  async onJobDecline(jobItem, index, unApply) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      const isRecurring = jobItem.recurringShift.isTrue;
      const jobId = isRecurring ? jobItem.recurringShift.groupJobId : jobItem._id;
      await this.jobService.unApplyFromBroadcast(jobId, this.userData, unApply, isRecurring, false);
      if (unApply) {
        // if job is unapplied, we just need to update its applied
        // status
        this.updateJobAppliedStatus(jobItem, index, false);
        
        if(this.isApplication) {
          this.removeJobFromList(jobItem);
        }
      } else {
        // else we need to remove it from the list
        this.removeJobFromList(jobItem);
      }
    } catch (error) {
      console.log('error: ', error);
      this.handleJobRejectError(error);
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

  async applyOnJob(jobItem, acceptedRcmpFee: boolean) {
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      if (!jobItem.recurringShift.isTrue) {
        await this.jobService.acceptBroadcastRequestNonRecurring(jobItem._id, this.userData._id, acceptedRcmpFee);
      } else if (jobItem.recurringShift.isTrue) {
        await this.talentDashboardService.acceptBroadcastRequestRecurring(
          jobItem.recurringShift.groupJobId,
          this.userData._id,
          acceptedRcmpFee
        );
      }

      this.popupService.showModal({
        heading: 'Shift requested',
        message: 'You have successfully requested this shift! We will send you a notification if you are approved.',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/clap.png'
      });
      
      this.userIndustry = await this.utilService.getIndustryBySkillName(jobItem.skill);
      this.analyticsService.trackEvent('job_applied', 'User Flow', '3 Apply Job', 'app', this.userIndustry);

    } catch (error) {
      this.handleJobApplyError(error);
      throw error;
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

  async handleJobApplyError(error) {
    console.log('errorx', error);

    // checking if we need to show subskill error
    const { isHandled } = this.subSkillService.checkSubSkillError(error, this.userData);
    if (isHandled) {
      return;
    }
    
    this.applyErrorHandler.handleError(error);

    console.log('error', error);
    this.popupService.showModal({
      heading: 'Sorry',
      message:
        error.error && typeof error.error === 'string'
          ? error.error
          : error.data && error.data.errMsg
          ? error.data.errMsg
          : typeof error.data === 'string'
          ? error.data
          : 'We encountered an error when processing your request. Please try again later.',
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png'
    });
  }

  private updateJobAppliedStatus(jobItem, index, isApplied: boolean) {
    this.availableLoadedJobs[index].isApplied = isApplied;
    const shiftIndex = this.availableShifts.findIndex(shift => {
      return shift._id === jobItem._id;
    });
    this.availableShifts[shiftIndex].isApplied = isApplied;
  }

  private handleJobRejectError(error) {
    this.popupService.showModal({
      heading: 'Sorry',
      message: error.data || typeof error.error === 'string' ? error.error : 'Something went wrong, please try again later',
      btn: 'Dismiss',
      navigateRoute: null,
      imgURL: 'assets/images/sorry1.png'
    });
  }

  private removeJobFromList(jobItem) {
    const shiftIndex = this.availableShifts.findIndex(shift => {
      return shift._id === jobItem._id;
    });
    this.availableShifts.splice(shiftIndex, 1);
    this.loadInitialShifts();
  }

  loadData(event) {
    setTimeout(() => {
      this.getAvailableShifts();
      event.target.complete();
    }, 500)
  }
  
}
