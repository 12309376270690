import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { ITracker } from '../../interfaces/tracker.interface';
import { PopupService } from '../../services/popup.service';
import { UtilService } from '../../services/util.services';

@Component({
  selector: 'app-shift-attendance-info',
  templateUrl: './shift-attendance-info.component.html',
  styleUrls: ['./shift-attendance-info.component.scss'],
})
export class ShiftAttendanceInfoComponent implements OnInit {

  @Input() jobItem : IClientJob; 
  @Input() staffer: IUser;

  clockInTime: string;
  clockOutTime: string;
  shiftTimingDescription : string;
  isShiftLate: Boolean = false;
  tracker: ITracker;

  constructor(private popupService: PopupService,private utilService: UtilService) { }

  ngOnInit() {
    this.isShiftLate = false
    let hiredStaffer = this.jobItem.hiredStaffers.find(staffer => staffer._id == this.staffer._id)

    if(hiredStaffer && hiredStaffer.tracker){
      this.tracker = hiredStaffer.tracker
    }
    if(!this.tracker){
      this.setShiftTimingDescription()
    }

    if(this.tracker && this.tracker.start){
      this.clockInTime = moment(this.tracker.start,"X").tz(this.jobItem.timeZone).format("hh:mm a")
      let startTime = moment(this.jobItem.shiftStartTime, "X").tz(this.jobItem.timeZone);
      if(moment(this.clockInTime,"hh:mm a").diff(moment(startTime,"X"),"minutes") > 15){
        this.isShiftLate = true
      }
    }

    if(this.tracker && this.tracker.end){
      this.clockOutTime = moment(this.tracker.end,"X").tz(this.jobItem.timeZone).format("hh:mm a")
    }
    
  }

  /**
   * Set Shift Timing Description based on how much time left 
   */
  setShiftTimingDescription() {
    let startTime = moment(this.jobItem.shiftStartTime, "X").tz(this.jobItem.timeZone);
    let currentTime = moment().tz(this.jobItem.timeZone);

    let timeDiffMinutes = startTime.diff(currentTime, "minutes");
    let timeDiffHours = startTime.diff(currentTime, "hours");
    let timeDiffDays = startTime.diff(currentTime, "days");

    if (timeDiffMinutes > 1440) { // More than 1 day
        this.shiftTimingDescription = `Shift will start in ${this.utilService.pluralizeText(timeDiffDays, "day")}`;
    } else if (timeDiffMinutes < 0) { // Already late
        this.shiftTimingDescription = `${this.utilService.pluralizeText(-timeDiffMinutes, "minute")} late`;
        this.isShiftLate = true;
    } else if (timeDiffMinutes < 60) { // Within 1 hour
        this.shiftTimingDescription = `Shift will start in ${this.utilService.pluralizeText(timeDiffMinutes, "minute")}`;
    } else { // Within 1 day but more than 1 hour
        this.shiftTimingDescription = `Shift will start in ${this.utilService.pluralizeText(timeDiffHours, "hour")}`;
    }
}

async showNotClockedInModal() {
    
  this.popupService.showModal(
    {
      heading: 'Not Yet Clocked In',
      message: `The contractor has not yet started their shift. Staffy is attempting to contact this contractor to verify their arrival. If you would like to contact them directly, their telephone number is: ${this.staffer.phoneNumber}`,
      btn: 'Go back',
      navigateRoute: null,
      imgURL: null,
      iconClass: 'fa fa-exclamation-circle',
      specificClass: 'not-clocked-in-modal',
      showDismissButton: true
    });
  }

}
