<div class = "profile-picture"> 
  <div class = "center-align">
    <div class = "center-text-align" *ngIf="!formattedImage">
        <div class = "avatar" (click)="changeImage()">
          <i class = "fa fa-user"></i>
        </div>
        <ion-button class = "upload-picture" color = "basic"  (click)="changeImage()">Upload profile picture</ion-button>
        <p class = "text">Upload your most professional, clear headshot</p> 
      </div>

    <div id="userImage" *ngIf="formattedImage" class="avatar" (click)="changeImage()">
      <i class = "fa fa-camera"></i>
      <img src="{{formattedImage}}">
    </div>
  </div>
</div>