<div class="ion-justify-content-center ion-text-center child">
  <h1 class="">
    {{name}}
  </h1>
  <p class="ion-text-center">
    {{message}}
  </p>

  <div class="info-row" [ngClass]="{'mobile-view': isMobile}">
    <star-rating class="ratings" [value]="0" (rate)="onRate($event)" [totalstars]="5" checkedcolor="#ff4d00"
      uncheckedcolor="grey" size="22px">
    </star-rating>

  </div>
  <ion-button expand="full" color="basic" id="success-popup-btn" class=" ion-no-margin submit-btn success-popup-ok"
    (click)='submit()'>Submit </ion-button>
  <ion-button expand="full" color="dark" id="success-popup-btn" class=" ion-no-margin cancel-btn success-popup-ok"
    (click)='cancel()'>Cancel </ion-button>
</div>