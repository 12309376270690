import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';

@Component({
  selector: 'shared-shifts-properties-signals',
  templateUrl: './shifts-properties-signals.component.html',
  styleUrls: ['./shifts-properties-signals.component.scss'],
})
export class ShiftsPropertiesSignalsComponent implements OnInit {
  @Input() userData: IUser;
  @Input() isDirectHire?= false;

  constructor() { }

  ngOnInit() {
  }


}
