export enum CompanyTypeEnum {
    HOTEL = 'Hotel',
    RESTURANT = 'Restaurant',
    STAFFING_AGENCY = 'Staffing agency',
    FOOD_SERVICE_COMPANY = 'Food service company',
    CATERING_COMPANY = 'Catering company',
    GROCER = 'Grocer',
    WAREHOUSE = 'Warehouse',
    FOOD_DISTRIBUTION = 'Food Distribution',
    RETIREMENT_HOME = 'Retirement Home',
    HEALTHCARE = 'Healthcare',
    LONG_TERM_CARE = 'Long Term Care',
    HOSPITALITY = 'Hospitality',
    VENUE = 'Venue',
    GENERAL = 'General',
    OTHER = 'Other',
}
