import { PipeTransform, Pipe } from '@angular/core';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { forEach } from 'lodash';

@Pipe({
    name: 'stafferWorkedForTheJob'
})
export class StafferWorkedForTheJob implements PipeTransform {
    transform(job: any): any {
        if (!job) {
            return;
        }
        let staffers = '';

        if (job.staffersAndShifts) {
            const invoice = job.isABroadcastRequest.isTrue ? job.invoice : job.staffersAndShifts[0].invoices[0];

            forEach(job.staffersAndShifts, (s) => {
                if (s.state === 'complete' || (s.state === 'accepted'
                    && invoice && (invoice.status === 'late cancellation penalty unpaid' ||
                        invoice.status === 'late cancellation penalty paid'))) {

                    const lastName = s.staffer.lastName && s.staffer.isFullName ? s.staffer.lastName : s.staffer.lastName[0] + '.';
                    staffers += s.staffer.firstName + ' ' + lastName + ',';
                };
            });
        }
        return staffers;

    }
}
