import { Input, OnChanges } from '@angular/core';
import { getNotificationCount, getShiftInProgress } from 'src/modules/authentication/+store/auth-selector';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import { ModalController } from '@ionic/angular';
import { ignoreElements, take } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import 'moment-duration-format';
import { Router } from '@angular/router';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { ITalentDashboardState } from 'src/modules/talent-dashboard/+store/talent-dashboard.state';
import { filter } from 'lodash';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import { getUpcomingShifts, getFutureShiftsCOunt } from 'src/modules/talent-dashboard/+store/talent-dashboard-selector';
import { JobService } from '../../services/job.service';



@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss'],
})
export class BottomBarComponent implements OnInit, OnChanges {
  @Input() manualActiveTab;
  userData;
  notificationCount;
  activeTab: string = 'home';
  options;
  showNotifications: boolean = false;
  isOpen = true;
  showList: boolean = false;
  loadNotificationFromDrawer : boolean = false;
  readonly user = this.store.pipe(select(getUserInfo));
  notificationModal;
  shiftEnded;
  upcomingShiftsCount;
  checkShiftInProgress: number =  0
  inProgress : boolean;
  jobStarted: boolean;
  shiftInProgress: boolean;
  shiftInProgressSubscription: Subscription;
  futureSubscription: Subscription;
  notificationSubscription = this.store.pipe(select(getNotificationCount)).subscribe(async (result) => {
    this.notificationCount = result;
  });

  readonly futureUpcomingShiftsCount = this.talentDashboardStore.pipe(select(getFutureShiftsCOunt));


  readonly upcomingShiftsData$ = combineLatest([this.talentDashboardStore.pipe(select(getUpcomingShifts)), this.user]).pipe(
    map(([upcomingJobs, userData]) => {
      if (userData) {
        this.userData = userData;
        return this.talentDashboardUtilService.filterUserUpcomingJobs(upcomingJobs, userData._id);
      }
    }),
    map(upcomingShiftsData => {
      return filter(upcomingShiftsData, job => {
        return !job.isDeleted;
      });
    }),
    map(filteredUpcomingShiftsData => {
      
      return filteredUpcomingShiftsData.sort((a, b) => {
        return a.shiftStartTime - b.shiftStartTime;
      });
    })
  );
  constructor(
    private store: Store<IAuthState>,
    private router: Router,
    private talentDashboardService: TalentDashboardService,
    private talentDashboardStore: Store<ITalentDashboardState>,
    private talentSyncService: TalentSyncService,
    private modalController: ModalController,
    private talentDashboardUtilService: TalentDashboardUtilService,
    private jobService : JobService,
    private authStore: Store<IAuthState>,
    ) { }


  ngOnChanges() {

    if(!this.shiftInProgress){
      this.shiftInProgressSubscription = this.authStore.pipe(select(getShiftInProgress)).subscribe(async (result) => {
        this.shiftInProgress = result;
      }); 
    }

    if(!this.upcomingShiftsCount) {
    this.futureSubscription = this.futureUpcomingShiftsCount.subscribe( async result => {
      this.upcomingShiftsCount = result;
    })  

    }
  }  

  async ngOnInit() {
    this.shiftInProgressSubscription = this.authStore.pipe(select(getShiftInProgress)).subscribe(async (result) => {
      this.shiftInProgress = result;
    }); 

    this.futureSubscription = this.futureUpcomingShiftsCount.subscribe( async result => {
      this.upcomingShiftsCount = result;
    })  
  
    
    const path =  this.router.url;
    this.showList = false;
    this.userData = await this.user.pipe(take(1)).toPromise();
    
    if(this.manualActiveTab) 
      this.activeTab = this.manualActiveTab;
    else   
      this.activeTab = path.substring(path.lastIndexOf('/') + 1, path.length);
  }

  hasShiftStarted(stafferId, staffersAndShifts) {
    return this.talentDashboardUtilService.hasStafferStartedAJob(stafferId, staffersAndShifts)
  }

  
  async hideList(data : {
    isOpen: boolean,
    redirectPath : string
  }) {
    this.isOpen = data.isOpen;
    this.loadNotificationFromDrawer = false; 
    
    if(this.notificationModal)
      this.notificationModal.dismiss();
    
    if(!this.isOpen) 
      await this.updateNotificationViewStatus()
      this.router.navigateByUrl(data.redirectPath || 'talent-dashboard/home');
  }

  async updateNotificationViewStatus() {
    try {
      await this.talentDashboardService.updateViewStatusForAll();
    } catch (error) {
      console.log(error);
    }
  }

  async setRootTab(path: string) {
    this.jobStarted = null;
    
    if(this.manualActiveTab) 
      this.activeTab = this.manualActiveTab;
    else   
      this.activeTab = path.substring(path.lastIndexOf('/') + 1, path.length);
  
    if(path == 'notifications'){
      this.showList = true;
      this.isOpen = true;

      this.notificationModal = await this.modalController.create({
        id : "notification-list-modal",
        component: NotificationListComponent,
        backdropDismiss: false,
        cssClass: 'notificationListModal',
        componentProps: {
          isOpen: this.isOpen,
          loadNotificationFromDrawer : true
        }
      });
      
        await this.notificationModal.present();  

        return await this.notificationModal.onDidDismiss().then(async dismiss => {
          this.hideList(dismiss.data);
          await this.talentSyncService.sync();
          this.router.navigateByUrl(dismiss.data &&  dismiss.data.redirectPath ? dismiss.data.redirectPath : 'talent-dashboard/home');
        });
    
    } else 
      this.router.navigateByUrl(`talent-dashboard/${path}`);
  }


  ionViewWillLeave() {
    this.manualActiveTab = null;
    this.notificationSubscription.unsubscribe();
}
  ngOnDestroy() {
    this.manualActiveTab = null;
    this.notificationSubscription.unsubscribe();
  }

}

