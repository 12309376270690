import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';
@NgModule({
    imports: [
        StoreModule.forRoot({
            router: routerReducer
        }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot()
    ],
    exports: [StoreModule, StoreDevtoolsModule, EffectsModule, StoreRouterConnectingModule]

})
export class AppStoreModule { }
