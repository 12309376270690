import { Component, Input, OnInit, OnChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { pluck, tap, map } from 'rxjs/operators';
import { MenuController, NavController } from '@ionic/angular';
import { combineLatest, of, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { getUserInfo } from 'src/modules/authentication/+store/auth-selector';
import { ITalentDashboardState } from 'src/modules/talent-dashboard/+store/talent-dashboard.state';
import { filter } from 'lodash';
import { TalentDashboardUtilService } from 'src/modules/talent-dashboard/services/talent-dashboard-util.service';
import { getUpcomingShifts, getFutureShiftsCOunt } from 'src/modules/talent-dashboard/+store/talent-dashboard-selector';


@Component({
  selector: 'app-contractor-header',
  templateUrl: './contractor-header.component.html',
  styleUrls: ['./contractor-header.component.scss'],
})
export class ContractorHeaderComponent implements OnInit, OnChanges {

  @Input() userData: IUser;
  @Input() isHome : boolean;
  @Input() heading : string;
  @Input() subTitle: string;
  @Input() showImg: boolean;
  @Input() showMenuOption: boolean;
  @Input() showUpcomingCount: boolean;
  @Output() updateActiveTab =  new EventEmitter();
  upcomingCount;
  headerCatogory;
  futureSubscription: Subscription;
  count = 0;
  showMenu = of(false);
  readonly user = this.authStore.pipe(select(getUserInfo));
  readonly upcomingShiftsData$ = combineLatest([this.talentDashboardStore.pipe(select(getUpcomingShifts)), this.user]).pipe(
    map(([upcomingJobs, userData]) => {
      if (userData) {
        this.userData = userData;

        return this.talentDashboardUtilService.filterUserUpcomingJobs(upcomingJobs, userData._id);
      }
    }),
    map(upcomingShiftsData => {
      return filter(upcomingShiftsData, job => {
        return !job.isDeleted;
      });
    }),
    map(filteredUpcomingShiftsData => {
      return filteredUpcomingShiftsData.sort((a, b) => {
        return a.shiftStartTime - b.shiftStartTime;
      });
    })
  );
  

  readonly futureUpcomingShiftsCount = this.talentDashboardStore.pipe(select(getFutureShiftsCOunt));
  constructor(
    private route: ActivatedRoute,
    private menu: MenuController,
    private navCtrl: NavController,
    private authStore: Store<IAuthState>,
    private router: Router,
    private talentDashboardStore: Store<ITalentDashboardState>,
    private talentDashboardUtilService: TalentDashboardUtilService
) { }

  async ngOnInit() {
    this.checkForMenuBtns();
  }

  
  async ngOnChanges(){
    this.futureSubscription = this.futureUpcomingShiftsCount.subscribe( async result => {
      this.count = result;
    })
    this.checkForMenuBtns();
  } 

  checkForMenuBtns() {
    this.showMenu = this.route.data.pipe(pluck('showMenu'), map((showMenu) => showMenu || this.showMenuOption),
      tap((showMenu, ) => this.menu.enable(showMenu, 'side-menu')));
  }

  navigateToView(destView: string) {
    switch(destView) {
        case 'upcoming' : {
          this.router.navigateByUrl('talent-dashboard/upcoming-shifts');
          this.updateActiveTab.emit(destView);
          break;
        }

        case 'instruction' : {
          this.router.navigateByUrl('onboarding-staffer/instruction',{
            replaceUrl : true
          });
          this.updateActiveTab.emit(destView);
          break;
        }

        case 'edit-profile' : {
          this.router.navigateByUrl('onboarding-staffer/onboarding-dashboard');
          this.updateActiveTab.emit(destView);
          break;
        }

        case 'onboarding-dashboard' : {
          this.router.navigateByUrl('onboarding-staffer/onboarding-dashboard');
          this.updateActiveTab.emit(destView);
          break;
        }
    }
  }

  navBack() {
    this.navCtrl.back();
  }

}
