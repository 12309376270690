import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface RecurringShiftData {
  Date: string;
  'Start Time': string;
  'End Time': string;
  selected: boolean;
  _id: string;
  action: string;
}

@Component({
  selector: 'app-cancel-recurrent-shifts',
  templateUrl: './cancel-recurrent-shifts.component.html',
  styleUrls: ['./cancel-recurrent-shifts.component.scss'],
})
export class CancelRecurrentShiftsComponent implements OnInit {
  @Input() onClose;
  @Input() onSuccess;
  @Input() shifts: IClientJob[];
  @Input() groupShiftId: string;
  cancellationForm: FormGroup;

  recurringShiftData: RecurringShiftData[] = [];
  recurringShiftConfig: string[] = ['Date', 'Start Time', 'End Time'];

  selectedShifts: { [key: string]: boolean } = {};

  constructor(
    private popupService: PopupService,
    private loadingService: LoadingService,
    private jobService: JobService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.recurringShiftData = this.shifts.map(item => ({
      Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM'),
      'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
      'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
      selected: false,
      _id: item._id,
      action: 'Select all from here',
    }));

    this.cancellationForm = this.formBuilder.group({
      cancellationReason: ['', Validators.required],
    });
  }

  /**
   * It will delete all selected recurring shifts
   * @returns 
   */

  async onCancelShifts() {
    if (Object.keys(this.selectedShifts).length === 0) {
      this.onClose();
      this.popupService.showModal({
        heading: 'Sorry!',
        message: 'Please select at least one shift to cancel',
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png',
      });
      return;
    }

    this.cancellationForm.markAllAsTouched();
    const loading = await this.loadingService.showLoading().toPromise();

    try {
      this.onClose();
      
      await this.jobService.deleteRecurrShift(this.groupShiftId, 'specific', {
        specificJobs: this.selectedShifts,
        ...this.cancellationForm.value,
      });
      this.popupService.showModal(
        {
          heading: 'Recurring Shift Deleted!',
          message: 'Shift has been deleted successfully!',
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/thumbs-up.png',
        },
        async () => {
          await this.onSuccess();
        },
      );
    } catch (error) {
      console.log('error', error);
      const errorMessage =
        typeof error.error === 'string' ? error.error : 'Could not delete the shift';
      this.popupService.showModal({
        heading: 'Sorry!',
        message: errorMessage,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/sorry1.png',
      });
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

  onCancelModal() {
    this.onClose();
  }

  /**
   * 
   * If a single checkbox is toggles
   */
  onToggleCheckBox({ index }: { index: number }) {
    const shiftId = this.recurringShiftData[index]._id;
    if (!this.selectedShifts[shiftId]) {
      this.selectedShifts[shiftId] = true;
    } else {
      delete this.selectedShifts[shiftId]
    }
  }

  getSelectedShiftCount(){
    return Object.keys(this.selectedShifts).length
  }

  /**
   * 
   * If parent checkbox is toggled
   */

  onToggleAllCheckBoxes({ checked, index }: { checked: boolean; index: number }) {
    for (let i = index; i < this.recurringShiftData.length; i++) {
      this.recurringShiftData[i].selected = checked;
      this.recurringShiftData[i].action = checked ? 'Deselect all from here' : 'Select all from here';

      const shiftId = this.recurringShiftData[i]._id;
      if (!this.selectedShifts[shiftId] && checked) {
        this.selectedShifts[shiftId] = true;
      } else if (this.selectedShifts[shiftId] && !checked) {
        delete this.selectedShifts[shiftId];
      }
    }


    this.recurringShiftData = this.recurringShiftData.map(item=>{
      return {
        ...item
      }
    })

  }
}
