import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment-duration-format';

@Pipe({
    name: 'milisecondsToHumanize'
})
export class MilisecondsToHumanizePipe implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return value;
        }
        return moment.duration(value).humanize();
    }
}