import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { UtilService } from 'src/modules/shared/services/util.services';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { AddressModificationService } from '../../services/address-modification.service';

@Component({
  selector: 'shared-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
})
export class AddressMapComponent implements OnChanges, AfterViewInit {
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  // @ViewChild('googlePlaces', { static: false }) googlePlaceInput: IonInput;
  @Input() address: { [key: string]: string };
  @Output() formSubmitted = new EventEmitter();
  @Input() isStaffer: boolean;
  @Output() addressUpdatesSubmitted = new  EventEmitter();
  @ViewChild('googlePlaces', { static: false }) googlePlaceInput: IonInput;
  @Input() googlePlace:  IonInput;

  selectedPlace; // to save selected google place;
  type: string;
  showAddressTextBox: boolean = false;
  showPostalCodeTextBox: boolean = false;
  constructor(
    private popupService: PopupService,
    private utilService: UtilService,
    private addressModificationService: AddressModificationService) { }

  ngOnChanges() {
    if (this.address) {
      this.selectedPlace = this.address.street;

      //remove postal code from street address displayed on input
      if(this.isStaffer && this.selectedPlace.formatted_address){
        this.address.street = this.addressModificationService.removePostalCodeFromStreetAddress(this.selectedPlace.formatted_address);

        //extract country for postal code validation
        this.address.country = this.utilService.findCountryFromState(this.address.province) === 'United States' ? 'us' : 'ca';
      }
      this.loadMap();
    }
  }

  ngAfterViewInit() {
    if(this.isStaffer)
    this.googlePlaceInput = this.googlePlace;
    this.addressUpdates();
  }
  loadMap() {

    const geocoder = new google.maps.Geocoder();
    const address = typeof this.selectedPlace === 'object' ? this.selectedPlace.formatted_address : this.selectedPlace;
    geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const loc = results[0].geometry.location;
        const options = {
          center: new google.maps.LatLng(loc.lat(), loc.lng()),
          zoom: 18,
          disableDefaultUI: false
        };

        // Render map with marker on user selected address
        const map = new google.maps.Map(this.mapElement.nativeElement, options);
        const marker = new google.maps.Marker({
          position: options.center,
          map,
          draggable: true,
        });

        // Event listener to get new address on marker movement.
        google.maps.event.addListener(marker, 'dragend', () => {
          this.draggedMarker(map, marker, geocoder);
        });
      }
    });
    

  }

  draggedMarker(map, marker, geocoder) {
    map.setCenter(marker.getPosition());
    map.panTo(marker.getPosition());
    geocoder.geocode({ 'location': marker.getPosition() }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.selectedPlace = results[0];
          
          //call when street address is changed by dragging marker
          //EFFECT: Extracts code from street address (display only), Evaluate country and code of newly entered address
          if(this.isStaffer)
            this.updateAddressFields(this.selectedPlace)
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }
  
  editAddress() {
    this.showAddressTextBox = true;
    this.showPostalCodeTextBox = true;
  }
  async addressUpdates() {
    // Google Places API auto complete
    const input = await this.googlePlaceInput.getInputElement();
    const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // retrieve the place object for your use
      const place = autocomplete.getPlace();
      this.selectedPlace = place;

      //call when street address is changed by selecting option from dropdown
      //EFFECT: Extracts code from street address (display only), Evaluate country and code of newly entered address
      if(this.isStaffer)
        this.updateAddressFields(this.selectedPlace);
      this.loadMap();
    });
  }

  updateAddressFields(selectedPlace) {
     //when pointer is dragged, update address fields (code, country, street address) used in the view 
      this.address = this.addressModificationService.extractAddressFieldsFromGeoAPIResult(selectedPlace.formatted_address, selectedPlace.address_components)
      //Element by id used to render changes, replace it with street address without code
      this.googlePlaceInput.value = this.address.street;
      this.addressUpdatesSubmitted.emit(selectedPlace);
      
  }
  
  nextStepStaffer() {
    
    //validate postal code 
    const country = this.address.country == 'ca' ? 'ca' : 'us'

    if(this.addressModificationService.isPostalCodeValid(this.address.code, country)) {
      
      //if street address is same, pass the address and post code to parent
      //created new obj to store value of post code

      if((typeof this.selectedPlace) == 'string') {
        const obj = {
          street : this.selectedPlace,
          code: this.address.code
        }
        this.formSubmitted.emit(obj);
      } 
      //if address is updated from auto complete or by dragging marker, It'll create an object
      //added a field to store current post code, if contractor updates the post code from input field we'll consider that as final value rather than the one suggested by google API.
      else { 
        this.selectedPlace.code = this.address.code;
        this.formSubmitted.emit(this.selectedPlace);
      }
    } 
    //show error if postal code is invalid
    else {
      this.popupService.showPopup('Error', 'Please enter your complete address, we couldn’t find the postal code for the address you entered.');
    } 
  }

  nextStepEmployer() {
    this.formSubmitted.emit(this.selectedPlace);
  }
}
