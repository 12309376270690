import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalController, IonInput } from '@ionic/angular';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { UtilService } from '../../services/util.services';
import { UserService } from '../../services/user.service';
import { AddressModificationService } from '../../services/address-modification.service';
import { deburr } from 'lodash';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PersonalInformationValidatorService } from 'src/modules/onboarding-staffer/services/personal-information.validator.service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { getUserInfoAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { Store } from '@ngrx/store';
import { CountryPhoneNumbersComponent } from '../country-phone-numbers/country-phone-numbers.component';


@Component({
  selector: 'app-edit-partial-user-info',
  templateUrl: './edit-partial-user-info.component.html',
  styleUrls: ['./edit-partial-user-info.component.scss']
})
export class EditPartialUserInfoComponent implements OnInit, OnDestroy {
  disableSave = true;
  @Input() userData: IUser;
  @Input() redirectToggleOff?: boolean;
  @Input() successMsg?: string;
  @Input() mailingAddress?;
  @Input() updateAddress?;
  @Input() view: string;
  @Output() resumeSubmitted = new EventEmitter();
  @ViewChild('googlePlaces', { static: false }) googlePlaceInput: IonInput;
  selectedFlag: string = "assets/images/ca-flag.png";
  personalInformationForm: FormGroup = this.fb.group({
    bio: new FormControl('', [Validators.required]),
    address: new FormGroup({
      street: new FormControl(''),
      type: new FormControl('', Validators.required),
      code: new FormControl(''),
      suiteNumber: new FormControl(''),
      province: new FormControl(''),
      country: new FormControl('ca')
    }, [this.personalInformationValidatorService.postalCodeValidator()]),
    formattedAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required])
  });
  confirmStreetAddress = '';
  mailingAddressMatched = true;
  saving = false;
  initView: string = '';
  
  placeholder = [
    'Street Address',
    'Confirm Street Address'
  ]
  addressSubscription?: Subscription;
  constructor(
    private modalController: ModalController,
    private popupService: PopupService,
    private userService: UserService,
    private addressModificationService: AddressModificationService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private personalInformationValidatorService: PersonalInformationValidatorService,
    private store: Store<IAuthState>,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
  this.initView = this.view;

  if(this.initView === 'profile') {
    this.addressSubscription = this.address.valueChanges.pipe(
      distinctUntilChanged((x, y) => x.type === y.type)
    ).subscribe((address) => {
      this.updateValidators(address.type !== 'home');
    });


    if(this.userData) {
      this.formControls['formattedAddress'].setValue(this.userData.address.street);
      this.formControls.address['controls'].street.setValue(this.userData.address.street);
      this.formControls.address['controls'].code.setValue(this.userData.address.code);
      this.formControls.address['controls'].type.setValue(this.userData.address.type);
      this.formControls.address['controls'].suiteNumber.setValue(this.userData.address.suiteNumber);
      this.formControls['bio'].setValue(this.userData.bio);
      this.formControls['phoneNumber'].setValue(this.userData.phoneNumber);
      console.log("this.userData.phoneNumber", this.userData.phoneNumber);

      this.selectedFlag = this.utilService.updateCountryFlag(this.userData.address.province);
    }
  } else {
      this.mailingAddress = {
        type: '',
        street: '',
        suiteNumber: '',
        code: ''
      };
    } 
  }

  ngOnChanges() {
    if(this.userData) {
      this.formControls['formattedAddress'].setValue(this.userData.address.street);
      this.formControls.address['controls'].street.setValue(this.userData.address.street);
      this.formControls.address['controls'].code.setValue(this.userData.address.code);
      this.formControls.address['controls'].type.setValue(this.userData.address.type);
      this.formControls.address['controls'].suiteNumber.setValue(this.userData.address.suiteNumber);
      this.formControls['bio'].setValue(this.userData.bio);
    }
   }

ngAfterViewInit() {
  if(this.initView === 'profile') {
    this.addressUpdates();
    this.cdref.detectChanges();
  }
}
async addressUpdates(event?) {
  // Google Places API auto complete
  if(event) {
    this.extractFields(event);      
  }

  const input = await this.googlePlaceInput.getInputElement();
  const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
  
  google.maps.event.addListener(autocomplete, 'place_changed', () => {
    // retrieve the place object for your use
    const place = autocomplete.getPlace();
    this.extractFields(place);
  });
}


extractFields(place) {
  const addressObj = this.addressModificationService.extractAddressFieldsFromGeoAPIResult(place.formatted_address, place.address_components);
  this.formControls.formattedAddress.setValue(place.formatted_address);
  this.formControls.address['controls'].street.setValue(place);
  (this.address as FormGroup).get('street').setValue(place);
  (this.address as FormGroup).get('code').setValue(addressObj.code);
  (this.address as FormGroup).get('country').setValue(addressObj.country);
}

updateValidators(validationStatus: boolean): void {
  if (validationStatus) {
    this.suiteNumber.setValidators([Validators.required]);
  }else{
    this.suiteNumber.setValidators(null);
  }
  this.suiteNumber.updateValueAndValidity();
}

get address() {
  return this.personalInformationForm.get('address');
}

get type() {
  return this.personalInformationForm.get('address.type');
}

get code() {
  return this.personalInformationForm.get('address.code');
}

get suiteNumber() {
  return this.personalInformationForm.get('address.suiteNumber');
}

get phoneNumber() {
  return this.personalInformationForm.get('phoneNumber');
}

get formControls() {
  return this.personalInformationForm.controls;
}

get formValue() {
  return this.personalInformationForm.value;
}

choosedFile(event: any) {
  this.resumeSubmitted.emit(event);
}

onAddressChanged(place: google.maps.places.PlaceResult, mailingAddress) {
  // Google Places API auto complete
  this.getReadableFormat(place, mailingAddress);
  if (mailingAddress === 'confirmAddress') {
    this.confirmStreetAddress = place.formatted_address;
  } else {
    this.mailingAddress.street = place.formatted_address;
  }
}

  getReadableFormat(place, mailingAddress) {
    const geocodeResult = place;

    const address: {
      country?: string;
      city?: string;
      latitude?: number;
      location?: {};
      longitude?: number;
      code?: string;
      province?: string;
      street?: string;
    } = {
      country: 'ca'
    };
    
    

    if (geocodeResult) {
        geocodeResult.address_components.forEach(addressComp => {
        // CITY
        if (addressComp.types.indexOf('locality') !== -1 && addressComp.types.indexOf('political') !== -1) {
          address.city = addressComp.long_name.split(' ').join('_');
        }

        // COUNTRY
        if (addressComp.types.indexOf('country') !== -1 && addressComp.types.indexOf('political') !== -1) {
          address.country = addressComp.short_name.toLowerCase();
        }

        // PROVINCE / STATE
        if (addressComp.types.indexOf('administrative_area_level_1') !== -1) {
          address.province = addressComp.long_name.split(' ').join('_');
          //Deburr will change French into general English e.g: Québec = Quebec
          address.province = deburr(address.province);
        }

        
        if (this.formControls.address['controls'].code && addressComp.types.indexOf('postal_code') !== -1 || addressComp.types.indexOf('postal_code_prefix') !== -1) {
          address.code = addressComp.long_name;
        }
      });

      address.street = geocodeResult.formatted_address;
    }

    if (
      geocodeResult &&
      geocodeResult !== 'ZERO_RESULTS' &&
      geocodeResult.geometry &&
      geocodeResult.geometry.location &&
      geocodeResult.geometry.location.lat &&
      geocodeResult.geometry.location.lng
    ) {
      address.latitude = geocodeResult.geometry.location.lat();
      address.longitude = geocodeResult.geometry.location.lng();

      address.location = {
        type: 'Point',
        coordinates: [geocodeResult.geometry.location.lng(), geocodeResult.geometry.location.lat()]
      };
    }

    if (mailingAddress !== 'confirmAddress') {
      this.mailingAddress = { ...this.mailingAddress, ...address };
    }    
  }

  clearState() {
    this.mailingAddress.street = '';
    this.mailingAddress.suiteNumber = '';
  }

  dismiss(data) {  
    this.modalController.dismiss(data);
  }

  async save() {
    if (this.mailingAddress.type === '') {
      this.popupService.showPopup('Missing Info', '  Please fill in the missing information.');
    } else {
      if (this.mailingAddress.street === '') {
        this.popupService.showPopup('Error', 'Invalid street address. Please select address from the available suggestions.');
      } else if (this.confirmStreetAddress === '') {
        this.popupService.showPopup(
          'Error',
          'Invalid confirmation street address. Please select the confirmation address from the available suggestions.'
        );
      } else if (this.mailingAddress.street !== this.confirmStreetAddress) {
        this.popupService.showPopup('Error', 'Address mismatch. Please ensure both addresses match.');
      } else if ((this.mailingAddress.type == 'apartment' || this.mailingAddress.type == 'condo') && this.mailingAddress.suiteNumber === '') {
        this.popupService.showPopup('Missing Info', 'Suite/Apartment is required.');
      } else if (!this.addressModificationService.isPostalCodeValid(this.mailingAddress.code, this.mailingAddress.country)) {
        // VALIDATING POSTAL CODE FORMAT
        this.popupService.showPopup('Error', 'Please enter valid postal code.');
      } else {
        try {
          this.saving = true;
          
          let data = {
            address: this.mailingAddress,
          }

          console.log('data: ', data);
          
          await this.userService.updateUserPromise(this.userData._id, data);
          this.dismiss(true);
          this.showPopup('Success', `${this.successMsg || 'Address Updated Successfully'}`, 'assets/images/thumbs-up.png', 'Dismiss', this.redirectToggleOff? '' : 'talent-dashboard/profile');  
          
        } catch (resErr) {
          console.log('error: ', resErr);
          this.dismiss(false);
          if (resErr && resErr.error && resErr.error.message) {
            this.showPopup('Unable to update address: ', resErr.error.message, '', 'Dismiss');
          } else {
            this.showPopup('Error', resErr, '', 'Dismiss');
          }
        }
      }
    }
  }

  async saveChanges() {
    try {
      const payload = {
        ...this.formValue
      };

      await this.userService.updateUserPromise(this.userData._id, payload);
      this.personalInformationForm.markAsPristine();
      this.store.dispatch(getUserInfoAction());
      this.showPopup('Success', `${this.successMsg || 'Profile Updated Successfully'}`, 'assets/images/thumbs-up.png', 'Dismiss', this.redirectToggleOff? '' : 'talent-dashboard/profile');  
      
    } catch (resErr) {
      if (resErr && resErr.error && resErr.error.message) {
        this.showPopup('Error', 'Unable to update profile', 'sorry1.png', 'Dismiss', '');
      } else {
        this.showPopup('Error', resErr, '', 'Dismiss');
      }   
    }
  }

  showPopup(heading: string, message: string, imgURL: string, btn?: string, navigateRoute?: string) {
    this.popupService.showModal(
      {
        heading,
        message,
        btn: btn || 'Dismiss',
        navigateRoute,
        imgURL
      },
      () => {
        this.dismiss(true);
      }
    );
  }

  ngOnDestroy() {
    this.personalInformationForm.reset();
    console.log(this.personalInformationForm);
    if (this.addressSubscription) {
      this.addressSubscription.unsubscribe();
    }
  }
}
