import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';
import { RecurrentShiftService } from '../services/recurrentShift.service';

@Component({
  selector: 'app-modify-unit-number',
  templateUrl: './modify-unit-number.component.html',
  styleUrls: ['./modify-unit-number.component.scss'],
})
export class ModifyUnitNumberComponent implements OnInit, AfterViewChecked {

  @Input() jobItem: IClientJob;
  @Input() allClientUnits: string[];
  @Input() activeView: string; 
  action: string;
  
  constructor(
    private broadcastJobsUtilService: BroadcastJobsUtilService,
    private recurrentShiftService: RecurrentShiftService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.assignActionTextToButton(this.jobItem);
  }
  ngAfterViewChecked() {
    this.assignActionTextToButton(this.jobItem);
    this.cdr.detectChanges();

  }

  async editUnitNumberPopup(jobItem: IClientJob) {
    this.jobItem = await this.broadcastJobsUtilService.editUnitNumber(jobItem, this.allClientUnits, this.action);
    
    console.log('updated: ', this.jobItem);
    this.assignActionTextToButton(this.jobItem); 
  }


  assignActionTextToButton(jobItem: IClientJob) {
    //Check if job doesn't have a unit number, set action text to Add [unit number]
    //else set action text to Edit [unit number]

    const action =  this.recurrentShiftService.unitNumberByShift(jobItem);
    
    if(!action) {
      this.action = 'Add';
    } else {
     this.action = 'Edit';
      } 
    }
}
