<ion-grid class = "child">
  <i class = "fa fa-times" (click) = "dismissModal()"></i>
  <ion-row class = "heading">
    <h1>
      {{ notification?.created | momentPipe:'MMM DD, YYYY'}}
    </h1>
    <p class = "general-bold-large padding-around" *ngIf = "badge?.type">{{ ('NOTIFICATIONS.' +notification?.type ) | translate }}</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.jobCompleted">{{ 'NOTIFICATIONS.Job Completed' | translate }}</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.fileDispute">{{ 'NOTIFICATIONS.Dispute Filed' | translate }}</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.shiftFilled">{{ 'NOTIFICATIONS.Shift filled' | translate }}</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.hired">{{ 'NOTIFICATIONS.Hired' | translate }}!</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.rejected">{{ 'NOTIFICATIONS.Rejected' | translate }}!</p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.awaitingResponse">{{ 'NOTIFICATIONS.Awaiting Response' | translate }} </p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.stafferHired">{{ 'NOTIFICATIONS.Hired' | translate }}! </p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.stafferHired">{{ 'NOTIFICATIONS.All Vacancies Filled' | translate }} </p>

    <p class = "general-bold-large padding-around" *ngIf = "badge?.stafferIgnored">{{ 'NOTIFICATIONS.Ignored' | translate }}!</p>

    <p class = "general-bold-large padding-around" *ngIf="badge.stafferIgnored"> {{ 'NOTIFICATIONS.All Vacancies Filled' | translate }} </p>
  
    <div class = "para">
      <p class = "top-zero">{{ notification?.body }}</p>

      <div *ngIf = "notification.job" class = "general-bold-large">{{ ('SKILLS.' + notification.job.skill) | translate }}</div>
      <span class = "general-bold-large" *ngIf="notification?.job?.subSkills?.length">Subskill required: </span><span class = "general-bold-small">
        {{ notification.job.subSkills.join(", ")}}
      </span>

    <div class="wrapper" *ngIf="notification.job"> 
      <ul class="icon-wrapper">
        <li *ngIf = "!notification.groupJobId">
          <i class = "fa fa-calendar"></i>{{notification.job.shiftStartTime | momentPipeUnix:'Do MMM YYYY': notification.job.timeZone }}
        </li>
        
        <li *ngIf = "!notification.groupJobId">
            <i class = "fas fa-clock"></i>{{ notification.job.shiftStartTime | momentPipeUnix:'hh:mm A' :  notification.job.timeZone }} - {{notification.job.shiftEndTime | momentPipeUnix:'hh:mm A':  notification.job.timeZone}}
        </li>

        <li *ngIf = "notification.groupJobId">
          <i class = "fa fa-calendar"></i>{{ notification.dateRange }}
        </li>
   

        <li *ngIf="notification?.groupJobId?.to && notification?.groupJobId?.from ">
          <i class = "fas fa-clock"></i>
          <a (click)="viewShiftTimings()">
            {{ 'NOTIFICATIONS.View shift timings' | translate }}
          </a>
        </li>

        <li *ngIf="notification?.job?.unitNumber">
          <img class = "unitNumber" src = "assets/images/square-u.png">Unit number: {{ notification.job.unitNumber }}
        </li>

        <li *ngIf="notification?.groupJobId">
          <i class="fa fa-tasks" aria-hidden="true"></i>Number of shifts: {{ notification.groupJobId.jobs.length}}
        </li>

        <li *ngIf="notification?.job?.rate">
          <i class = "fas far fa-coins"></i>${{ notification.jobRate }}/{{'NOTIFICATIONS.hour' | translate}}
        </li>

        <li *ngIf="notification?.user?.type == 'staffer' && ((!notification?.job?.entity?.creator?.customer?.status && notification?.job?.entity?.creator?.customer?.hasCreditCard && notification.hour24PayAvailableBadge)) || (notification?.job?.entity?.creator?.force24HourPayAvailableBadge || notification?.job?.is24HoursBadge)">
          <i class="fas fa-calendar"></i>
          {{ 'NOTIFICATIONS.StaffyPay' | translate }}
        </li>

        <li *ngIf="notification?.user?.type == 'staffer' && notification?.job?.travelTime">
          <i class="fas fa-solid fa-bus"></i>
          {{notification.job.travelTime}} {{"NOTIFICATIONS.Hours" | translate}}&nbsp;<b>{{'NOTIFICATIONS.TRANSIT_ALLOWANCE' | translate}}</b>
        </li>

        <li *ngIf="notification?.user?.type == 'staffer' && notification?.job?.isTipIncluded">
          <i class="fas fa-solid fa-folder-plus"></i>
          {{'NOTIFICATIONS.Includes tip' | translate}}
        </li>

        <li *ngIf="notification?.job?.break?.unpaid">
          <i class="fas fa-money-check"></i>
          {{notification.job.break.unpaid | translate}}&nbsp;<b>{{'NOTIFICATIONS.Unpaid Break Allowed' | translate}}</b>
        </li>

        <li *ngIf="notification?.job?.isStatPay && notification?.job?.isStatPay == true">
          <i class="fas fa-money-check"></i>
          {{'NOTIFICATIONS.Stat Holiday Pay' | translate}}
        </li>

        <li *ngIf="notification?.job?.companyAddress" class="block">
          <i class = "fa fa-map-marker"></i>
            {{notification.job.companyAddress.street 
              + (  notification.job.companyAddress.street && notification.job.companyAddress.code ? ', ' : '') 
              + notification.job.companyAddress.code 
              + ( notification.job.companyAddress.street || notification.job.companyAddress.code ? ", " : '')  
              + notification.job.companyAddress.city }} - <span class="general-bold">{{notification.job.proximity}} km away</span>
        </li>

        
        <li  *ngIf="notification?.job?.shiftNotes" class = "spacing block"><span class = "general-bold">Shift Notes: </span>
        <span [innerHTML]="notification.job.shiftNotes | sanitizeHtml"></span></li>

        
        <li *ngIf="notification?.job?.entity?.siteNotes" class = "spacing block"><span class = "general-bold">Site Notes: </span>{{ notification.job.entity.siteNotes}}
        </li>
      </ul>
    </div>
    </div>
  </ion-row> 

  <ion-row *ngIf = "badge.showRating"> 
    <div *ngIf="notification.user.numOfRatedUsers > 3">
      <star-rating class="ratings" [value]="notification.user.aggregated_rating" [totalstars]="5"
        checkedcolor="#ff4d00" uncheckedcolor="grey" size="22px" readonly="true"></star-rating>
        ({{notification.user.aggregated_rating}})
    </div>
    
    <p *ngIf="notification.user.numOfRatedUsers > 3" class="rating-info">
      ({{notification.user.numOfRatedUsers}}
      {{ ('NOTIFICATIONS.' + (notification.user.numOfRatedUsers == 1 ? 'rating' : 'ratings') ) | translate }})
    </p>

    <p *ngIf="notification.user.numOfRecommendations" class="rating-info">{{notification.user.numOfRecommendations}}
      {{ ('NOTIFICATIONS.' + (notification.user.numOfRecommendations == 1 ? 'recommendation' : 'recommendations') ) | translate }}
    </p>

    <div *ngIf="notification.user.badges.length" class="ion-margin-top ion-margin-bottom">
      <shared-home-staffer-badges [userData]="notification.user"></shared-home-staffer-badges>
    </div>
    

  </ion-row>
  
  <ion-row class = "full-width">
    <div class="buttons-section">
      <span *ngIf="notification.jobOffer" class = "full-width">
        
        <div class = "align-button-row" [hidden]="badge.isABroadcastRequest">
          <ion-button class =  "half-width ion-no-margin btn success-popup-ok remove-bg" (click)="rejectJob()">
            {{ 'NOTIFICATIONS.Cancel Shift' | translate }}
          </ion-button>
          <ion-button color="basic" class=" ion-no-margin btn success-bg" [disabled]="notification.disable" (click)="acceptHireRequest()">
            {{ 'NOTIFICATIONS.Confirm' | translate }}
          </ion-button>
        </div>
  
        <div class = "align-button-row" *ngIf = "badge.showApplyButton">
          <ion-button class =  "half-width ion-no-margin btn success-popup-ok remove-bg" (click)="rejectStaffer()">
            {{ 'NOTIFICATIONS.Pass Shift' | translate }}
          </ion-button>
          <ion-button color="basic" class=" ion-no-margin btn success-bg" [disabled]="notification.disable"
            (click)="applyOnBroadcastRequest()">
            <span *ngIf = "userData?.profile_approved">Apply</span>
            <span *ngIf = "!userData?.profile_approved">Request Shift</span>
          </ion-button>
        </div>
      </span>
      
      <span *ngIf = "badge.recurringJobOffer" class = "full-width">
        <div class = "align-button-row" *ngIf="notification.type == 'recurring shift offer'">
          <ion-button class =  "half-width ion-no-margin btn success-popup-ok remove-bg" (click)="recurringRejectJob()">
            {{ 'NOTIFICATIONS.Cancel Shift' | translate }}
          </ion-button>
          <ion-button [disabled]="notification.disable" color="basic" class=" ion-no-margin btn success-bg" (click)="recurringAcceptJob()">
            {{ 'NOTIFICATIONS.Confirm' | translate }}
          </ion-button>
        </div>
        <div class = "align-button-row" *ngIf = "badge.showApplyRecurringButton">
          <ion-button class =  "half-width ion-no-margin btn success-popup-ok remove-bg"
            (click)="recurringRejectBroadcastRequest( )">
            {{ 'NOTIFICATIONS.Pass Shift' | translate }}
          </ion-button>
          <ion-button color="basic" *ngIf="notification.job.canApply && !notification.job.isApply" class=" ion-no-margin btn success-bg" (click) = "recurringRespondToBroadcastRequest()">
            <span *ngIf = "userData?.profile_approved">Apply</span>
            <span *ngIf = "!userData?.profile_approved">Request Shift</span>
          </ion-button>
        </div>
      </span>

      <span *ngIf = "badge.alreadyAppliedOnRecurring" class = "full-width">
        <ion-button color="dark" class="ion-no-margin btn uppercaseTxt" (click)="recurringUnApplyFromShift()">
          {{ 'NOTIFICATIONS.Unapply' | translate }}
        </ion-button>
      </span>

     
      <div class="align-button-row" *ngIf="notification.jobAccpted" >
        <ion-button class="half-width ion-no-margin btn success-popup-ok remove-bg" (click)="notification.job.recurringShift.isTrue ? recurringRejectJob() : rejectJob()">
          Decline Shift
        </ion-button>
        <ion-button class="ion-no-margin btn success-bg" (click)="confirmShift()">
          {{ 'NOTIFICATIONS.Confirm' | translate }}
        </ion-button>
      </div>

      <span *ngIf = "badge.verifiedHoursBadge">
        <ion-button class="ion-no-margin btn success-bg" (click)="verifyHours()">
          {{ 'NOTIFICATIONS.Verify Hours' | translate }}
        </ion-button>
      </span>

      <span *ngIf = "badge.recurringJobCheck">
        <ion-button *ngIf="notification.isIntrestedStaffer" (click)="recurringHireStaffer()">
          {{ 'NOTIFICATIONS.Hire' | translate }}
        </ion-button>
        
        <ion-button *ngIf="notification.isIntrestedStaffer" (click)="recurringRejectBroadcastRequest()">{{ 'NOTIFICATIONS.Reject' | translate }}
        </ion-button>
      </span>

      <span *ngIf="badge.isRecurringBroadcast">
        <ion-button *ngIf="badge.seeAvailableShifts" (click)="seeAvailableShifts()">
          {{ 'NOTIFICATIONS.See available shift(s)' | translate }}
        </ion-button>
      </span>

      <span
          *ngIf = "badge.jobCompletedForEmployer">
          <ion-button *ngIf="!notification.filedDispute" (click)="fileDispute()">
            {{ 'NOTIFICATIONS.File Dispute' | translate }}
          </ion-button>

          <ion-button *ngIf = "badge.canRateShift" (click)="rateStaffer()">
            {{ 'NOTIFICATIONS.Rate' | translate }}
          </ion-button>
        </span>


      <span *ngIf = "badge.trackerWorking">
        <ion-button class="ion-no-margin btn success-bg" (click)="stillWorkingStaffer()">
          {{ 'NOTIFICATIONS.Yes' | translate }}
        </ion-button>
        <ion-button class="ion-no-margin btn remove-bg" (click)="stopTrackerNow()">
          {{ 'NOTIFICATIONS.No' | translate }}
        </ion-button>
      </span>

      <span *ngIf="badge.alreadyApplied">
        <ion-button class="ion-no-margin btn remove-bg" (click)="unApplyFromShift()">
          {{ 'NOTIFICATIONS.Unapply' | translate }}
        </ion-button>
      </span>

      <span *ngIf = "badge.alreadyHired">
        <ion-button class="ion-no-margin btn success-bg" *ngIf="notification.isIntrestedStaffer"
          (click)="hireStafferForJob()">
          {{ 'NOTIFICATIONS.Hire' | translate }}
        </ion-button>

        <ion-button color="dark" class="ion-no-margin btn uppercaseTxt" *ngIf="notification.isIntrestedStaffer"(click)="rejectStaffer()">
          {{ 'NOTIFICATIONS.Reject' | translate }}
        </ion-button>
      </span>


      <span *ngIf = "badge.seeAvailableShifts">
        <ion-button color="basic" class="ion-no-margin btn" (click)="seeAvailableShifts()">
          {{ 'NOTIFICATIONS.See available shift(s)' | translate }}
        </ion-button>
      </span>

      <span *ngIf = "badge.logFileDispute">
        <ion-button class="ion-no-margin btn success-bg" (click)="fileDispute()">
          {{ 'NOTIFICATIONS.File Dispute' | translate }}
        </ion-button>
      </span>

      <span *ngIf = "badge.logRating">
        <ion-button  class="ion-no-margin btn remove-bg" (click)="rateStaffer()">
          {{ 'NOTIFICATIONS.Rate' | translate }}
        </ion-button>
      </span>
    </div>
  </ion-row>
  
</ion-grid>