<ion-grid class="ion-no-padding height-100">
  <ion-row class="ion-justify-content-center  main-wrapper">
    <ion-col >
      <div class="heading-wrapper">
        <div class = "tag-line"><span class = "bold">Set your rates based on your experience</span>,  
          We will use this information to notify you the shifts 
          that best suit you.</div>
      </div>

      <div class="form-wrapper">
        <div class="card-wrapper">
            <div class="skill-label">
              {{ 'ADD_SKILLS_RATE.Minimum Broadcast Rate' | translate }}
            </div>
            
            <div class="ranger-sec" *ngIf = "rates.length > 0">
              <ion-range id="dual-range" class="ranger" [(ngModel)]="minBroadcastRate"
                [min]="rates[0].id" [max]="rates[rates.length-1].id" step="0.5" (ngModelChange) = "updateMinimumBroadcastRate()">
              </ion-range>
              <div class = "rate-in-words">{{formatRate(minBroadcastRate)}}<span> Per Hour</span></div>
            </div>
        </div>
        
        <div class="card-wrapper" *ngFor="let item of skills; let i = index;">
          <div class="skill-label">
            {{ item.name }}
          </div>
          
          <div class="ranger-sec" *ngIf = "rates.length > 0">
            <ion-range id="dual-range" class="ranger" [(ngModel)]="item.rate" (ngModelChange) = "updateRate(item.name, item.rate, i)"
            [min]="rates[0].id" [max]="rates[rates.length-1].id" step="0.5">
            </ion-range>
            <div class = "rate-in-words">{{formatRate(item.rate)}}<span> Per Hour</span></div>
          </div>
      </div>
      </div>

        <!-- <div class = "button-div">
          <ion-button class = "register-btn" color = "basic" type="submit" (click)="next()">
            <div class = "register-txt">Save</div>
          </ion-button>
        </div> -->
    </ion-col>
  </ion-row>
</ion-grid>