<ion-row class="p0 box-shadow">
  <ion-col size="12">
    <div class = "jobPosition">
      <p class="input-label task-label">
        <span>Tasks <span *ngIf="title">FOR {{title}}</span></span>
        <span class="add-link" (click)="showAddTaskModal()"><i  class="fas fa-plus"></i> Add New</span>
      </p>
     
      <div class = "static-select-width" style = "display: flex; flex-direction: column !important;">
        <div>
          <select  class="position select-font task-select" #taskDropdown (change)="handleTaskSelection(taskDropdown)">
            <option>Select a Task</option>
            <option *ngFor="let task of availableTasks" [value]="task._id" style="text-transform: capitalize">
              {{ task.title || task.name }}
            </option>
          </select>
        </div>
        <table *ngIf= "tasks?.length">
          <tbody>
            <tr class = "table-row table-header">
              <td class = "table-name">Task Name</td>
              <td class = "table-description">Task Description</td>
            </tr>
            <tr *ngFor="let task of tasks" class = "selectedTasks">
              <td class = "task-tab table-name"> 
                <div class = "inner-tab">
                  <span class = "task-title">{{ task.title || task.name }}</span> <i class="fas fa-times" (click)="handleTaskRemoval(task)"></i></div>
              </td>
              <td class = "table-description">
                <div class = "description"><span>{{ task.description}}</span></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ion-col>
</ion-row>