<div class="ion-justify-content-center ion-text-center child">
    
    <h1 class="modal-title">
        You're changing grouped shifts </h1>

    <div class="modal-line"></div>

    <div class="form-wrapper">

        <div class="modal-text-warning">
            If you'd like to change the unit number for this shift only, please contact the Ops team at <a class="contact-email"
                href="mailto:info@staffy.com">info@staffy.com</a>
        </div>

        <div class="modal-text">
            Would you like to update the unit number for all the shifts in this group?
        </div>
    </div>




</div>
<div class=" button-stack">

            <button  (click)="handleNoBtn()" class="btn no-btn">
                No
            </button>

            <button  (click)="handleYesBtn()" class="btn yes-btn">
                Yes
            </button>
</div>