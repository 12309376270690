import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-submit-button',
  templateUrl: './form-submit-button.component.html',
  styleUrls: ['./form-submit-button.component.scss'],
})
export class FormSubmitButtonComponent implements OnInit {

  @Input() labelText: string;
  @Output() onClickHandle = new EventEmitter();
  @Input() showIcon : Boolean = true

  constructor() { }

  ngOnInit() {
  }

  handleClick() {
    this.onClickHandle.emit();
  }

}
