<div style="margin-bottom: 20px;">

  <div class="card">
    <div class="inner-row">
      <!-- <div id="userImage" class="user-image-wrap">
        <div class="staffer-pic-div">
          <img class="user-img" src="{{invoice.entity.creator.profileUrl.url}}">
        </div>
      </div> -->
      <div class="user-info-wrap">
        <div class="user-name">
          {{ invoice?.entity.name}}
          <!-- <span class="desktop-ic hidden-mob">
            <img class="ic" src="assets/images/calender.png" alt="">
            <span class="ic-info">
              {{invoice.created_date | momentPipeUnix:'MMMM.DD.YYYY' }}
            </span>
          </span> -->
        </div>
        <!-- <div class="info-row hidden-desktop">
          <img class="staffer-icons" src="assets/images/calender.png" alt="">
          <span class="info">
            {{invoice.created_date | momentPipeUnix:'MMMM.DD.YYYY' }}

          </span>
        </div> -->
      </div>

    </div>
    <div class="inner-row bg-gray  desc ">
      <span class="heading">
        <b>
          {{'INVOICES.Description' | translate}}
        </b>

      </span>
      <!-- <ng-container *ngFor="let j of invoice.jobs">
        <div>
          {{j.job | stafferWorkedForTheJob}}
          {{'INVOICES.as' | translate}}
          {{ ('SKILLS.' + j.skill) | translate }}
          @ {{ invoice?.entity.name}}
          {{ j.job.shiftStartTime | momentPipeUnix:'dddd MMMM Do' }} (
          {{  j.job.shiftStartTime | momentPipeUnix:'hh:mma' }} -
          {{  j.job.shiftEndTime | momentPipeUnix:'hh:mma' }} ) ${{ j.rate}}/hr





        </div>
      </ng-container> -->

      <ul class="listing">
        <ng-container *ngFor="let j of invoice.jobs">

          <li *ngIf="j.timeTracked > 0">
            <span>
              {{j.job | stafferWorkedForTheJob}}
              {{'INVOICES.as' | translate}}
              {{ ('SKILLS.' + j.skill) | translate }}
              @ {{ invoice?.entity.name}}
              {{ j?.job?.shiftStartTime | momentPipeUnix:'dddd MMMM Do': j?.job?.timeZone }}
              ({{  j?.job?.shiftStartTime | momentPipeUnix:'hh:mma' : j?.job?.timeZone }} -
              {{  j?.job?.shiftEndTime | momentPipeUnix:'hh:mma' : j?.job?.timeZone }}) ${{ j.rate}}/hr
            </span>

          </li>
        </ng-container>
      </ul>


      <!-- <div ng-repeat="j in invoice.jobs" ng-if="j.timeTracked > 0">
        <div>
            <p class="paymentHistory margin-zero" style="color:grey">
                {{j.job | stafferWorkedForTheJob}} {{"as" | translate}} {{(j.skill) | translate}} @ {{invoice.entity.name}}  {{ j.job.shiftStartTime | momentXDate }} ( {{ j.job.shiftStartTime | momentX }} - {{ j.job.shiftEndTime | momentX }} ) ${{j.rate}}/hr
            </p>
        </div>

        <hr/>
        <br/>
    </div> -->

    </div>
    <!-- /////////////////////////////////////////// -->
    <div class="inner-row bg-white hidden-mob">
      <span class="left-wrap">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Invoice No' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">

          {{ invoice?.serialNo }}


        </span>
      </span>
      <span class="right-wrap">
        <span class="left-wrap">
          <b>{{'INVOICES.Amount' | translate}}</b>
        </span>
        <span class="right-wrap text-align-right">

          ${{invoice.calculatedAmount}}


        </span>
      </span>
    </div>
    <div class="inner-row bg-gray hidden-mob">
      <span class="left-wrap">
        <span class="left-wrap">
          <b>

            {{'INVOICES.Date' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">


          {{invoice.created_date | momentPipeUnix:'MM-DD-YYYY' }}



        </span>
      </span>


      <span class="right-wrap">
        <span class="left-wrap">
          <b>

            {{'INVOICES.Status' | translate}}

          </b>

        </span>
        <span class="right-wrap text-align-right">

          <b class="font-color">{{ invoice?.status.toUpperCase()}}</b>
        </span>
      </span>
    </div>

    <div class="inner-row bg-white desc hidden-desktop">
      <span class="left-wrap">
        <b>
          {{'INVOICES.Invoice No' | translate}}

        </b>
      </span>
      <span class="right-wrap text-align-right">
        {{ invoice?.serialNo }}
      </span>
    </div>

    <div class="inner-row bg-gray desc hidden-desktop">
      <span class="left-wrap">
        <b>
          {{'INVOICES.Amount' | translate}}

        </b>
      </span>
      <span class="right-wrap text-align-right">
        ${{ invoice?.calculatedAmount }}
      </span>
    </div>

    <div class="inner-row bg-white desc hidden-desktop">
      <span class="left-wrap">
        <b>
          {{'INVOICES.Date' | translate}}

        </b>
      </span>
      <span class="right-wrap text-align-right">
        {{invoice.created_date | momentPipeUnix:'MM-DD-YYYY' }}
      </span>
    </div>

    <div class="inner-row bg-gray desc hidden-desktop">
      <span class="left-wrap">
        <b>
          {{'INVOICES.Status' | translate}}

        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b>{{invoice.status.toUpperCase()}}</b>
      </span>
    </div>


    <!-- LOOP THROUGH JOBS START -->
    <div *ngFor="let jobObj of invoice.jobs">
      <div class="sub-header">
        <p>{{jobObj.skill.toUpperCase()}}
          {{ jobObj?.job?.shiftStartTime | momentPipeUnix:'MMM Do (hh:ma' : jobObj?.job?.timeZone  }}-{{ jobObj?.job?.shiftEndTime | momentPipeUnix:'hh:ma)':   jobObj?.job?.timeZone }}
        </p>
      </div>
      <!-- Desktop Table Start-->
      <div class="inner-row bg-white hidden-mob bg-white">
        <span class="left-wrap">
          <span class="left-wrap">
            <b>

              {{'INVOICES.Rate' | translate}}

            </b>

          </span>
          <span class="right-wrap text-align-right">
            ${{ jobObj?.rate }}/hr
            <br/>
            <span *ngIf="jobObj.overtime.time > 0" style="font-size: small;">
              overtime paid at ${{jobObj.overtime.rate.toFixed(2)}}/hr
            </span>
          </span>
        </span>
        <span class="right-wrap">
          <span class="left-wrap">
            <b>

              {{'INVOICES.Travel Time' | translate}}
            </b>

          </span>
          <span class="right-wrap text-align-right">


            {{ jobObj?.travelTime }} Hours


          </span>
        </span>
      </div>

      <div class="inner-row bg-white hidden-mob bg-gray">

        <span class="left-wrap">
          <span class="left-wrap">
            <b>

              {{'INVOICES.Shift Amount' | translate}}

            </b>

          </span>
          <span class="right-wrap text-align-right">
            ${{ jobObj?.shiftAmount  }}
          </span>
        </span>
        <span class="right-wrap">
          <span class="left-wrap">
            <b>

              {{'INVOICES.Staffy fee' | translate}}
            </b>

          </span>
          <span class="right-wrap text-align-right">


            ${{  jobObj?.staffyFee  }}


          </span>
        </span>
      </div>

      <div class="inner-row bg-white hidden-mob bg-white">

        <span class="left-wrap">
          <span class="left-wrap">
            <b>
              {{'INVOICES.Tax' | translate}}
            </b>

          </span>
          <span class="right-wrap text-align-right">
            ${{  jobObj?.shiftTax  }}
          </span>
        </span>
        <span class="right-wrap">
          <span class="left-wrap">
            <b>

              {{'INVOICES.Total' | translate}}
            </b>

          </span>
          <span class="right-wrap text-align-right">


            ${{jobObj?. sumOfAmounts }}


          </span>
        </span>
      </div>
      <!-- Desktop Table End-->

      <!-- Mobile Table Start -->

      <div class="inner-row bg-white desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Rate' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          ${{ jobObj?.rate }}/hr
          <br/>
          <span *ngIf="jobObj.overtime.time > 0" style="font-size: xx-small;">
              overtime paid at ${{jobObj.overtime.rate.toFixed(2)}}/hr
          </span>
        </span>

      </div>


      <div class="inner-row bg-gray desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Travel Time' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          {{ jobObj?.travelTime }} Hours
        </span>

      </div>


      <div class="inner-row bg-white desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Shift Amount' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          ${{ jobObj?.shiftAmount  }}
        </span>

      </div>


      <div class="inner-row bg-gray desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Staffy fee' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          ${{  jobObj?.staffyFee  }}
        </span>

      </div>


      <div class="inner-row bg-white desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Tax' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          ${{  jobObj?.shiftTax  }}
        </span>

      </div>

      <div class="inner-row bg-gray desc hidden-desktop">
        <span class="left-wrap">
          <b>
            {{'INVOICES.Total' | translate}}

          </b>
        </span>
        <span class="right-wrap text-align-right">
          ${{jobObj?. sumOfAmounts }}
        </span>

      </div>
      <!-- Mobile Table End -->
    </div>
    <!-- LOOP THROUGH JOBS END -->
    <!-- Desktop Design Start -->
    <div class="inner-row bg-gray hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Time Tracked' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>{{ invoice?.timeTracked  }}hrs
        </b>
        <br/>
        <span style="font-size: small;" *ngIf="invoice.overtime.time>0">
          includes {{formattedOvertime}} overtime hrs
        </span>
      </span>
    </div>

    <div class="inner-row bg-white hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Shift Amount' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>
          ${{ invoice?.shiftAmount  }}
        </b>
      </span>
    </div>

    <div class="inner-row bg-gray hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Staffy fee' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  invoice?.staffyFee  }}
        </b>
      </span>
    </div>

    <div class="inner-row bg-white hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Tax' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{ invoice?.shiftTax }}
        </b>
      </span>
    </div>


    <div class="inner-row bg-gray hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Total' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  invoice?.calculatedAmount  }}
        </b>
      </span>
    </div>

    <div class="inner-row bg-white hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Total' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  invoice?.calculatedAmount  }}
        </b>
      </span>
    </div>

    <div class="inner-row bg-gray hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Total Due' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  dueAmount }}
        </b>
      </span>
    </div>

    <div class="inner-row bg-white hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Total Paid' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  invoice?.paidAmount  }}
        </b>
      </span>
    </div>

    <div *ngIf="invoice.paidFromWallet > 0" class="inner-row bg-gray hidden-mob">
      <span class="left-wrap font-color">
        <b>

          {{'INVOICES.Paid from wallet' | translate}}

        </b>

      </span>

      <span class="right-wrap text-align-right font-color">
        <b>${{  invoice?.paidFromWallet  }}
        </b>
      </span>
    </div>

    <!-- Desktop Design End -->

    <!-- Mobile Design Start -->

    <div class="inner-row desc bg-white hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Time Tracked' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> {{ invoice?.timeTracked  }}hrs</b>
        <br/>
        <span style="font-size: xx-small;" *ngIf="invoice.overtime.time>0">
          includes {{formattedOvertime}} overtime hrs
        </span>
      </span>
    </div>
    <div class="inner-row desc bg-gray hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Shift Amount' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.shiftAmount }}</b>
      </span>
    </div>

    <!-- /////////////////////////////// -->

    <div class="inner-row desc bg-white hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Staffy fee' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.staffyFee }}</b>
      </span>
    </div>
    <div class="inner-row desc bg-gray hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Tax' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.shiftTax }}</b>
      </span>
    </div>

    <!-- //////////////////////////////////// -->


    <div class="inner-row desc bg-white hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Total' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.calculatedAmount }}</b>
      </span>

    </div>



    <!-- //////////////////// -->

    <div class="inner-row desc bg-gray hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Total Due' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ dueAmount }}</b>
      </span>
    </div>
    <div class="inner-row desc bg-white hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Total Paid' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.paidAmount }}</b>
      </span>

    </div>
    <div *ngIf="invoice.paidFromWallet > 0" class="inner-row desc bg-gray hidden-desktop">
      <span class="left-wrap font-color">
        <b>
          {{'INVOICES.Paid from wallet' | translate}}
        </b>
      </span>
      <span class="right-wrap text-align-right font-color">
        <b> ${{ invoice?.paidFromWallet }}</b>
      </span>
    </div>
  </div>

  <div class=" button-stack">
    <!-- && invoice.status != 'paid' -->
    <button *ngIf="invoice.status == 'partially paid'  && invoice.timeTracked" (click)="payStafferInvoice( )"
      class="btn blue">
      {{'INVOICES.Retry Payment' | translate}}
    </button>
    <button
      *ngIf="invoice.status === 'unpaid' && userData.customer.hasCreditCard && (invoice.timeTracked || invoice.travelTime)"
      (click)="payStafferInvoice( )" class="btn blue">
      {{'INVOICES.Pay Now' | translate}}


    </button>

    <button *ngIf="!invoice.isDisputed && isDisputable" (click)="fileDispute( )" class="btn gray">

      {{'INVOICES.File Dispute' | translate}}


    </button>


    <button (click)="download()" class="btn black">
      {{'INVOICES.DOWNLOAD INVOICE' | translate}}
    </button>
  </div>
</div>