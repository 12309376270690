import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'removeUnderscoreWithSpace'
})
export class RemoveUnderscoreWithSpace implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return value;
        }
        return value.replace(/_/g, ' ');
    }
}