import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent
      ),
      multi: true
    }
  ]
})
export class FormCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() options: Array<any>;
  @Input() hideCheckbox : boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  
  _value: {label: string, checked: boolean};
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};



  constructor() { }

  ngOnInit() {}

  get value(): {label: string, checked: boolean} {
    return this._value;
  }

  set value(value: {label: string, checked: boolean}) {
      this._value = value;
      this.onChange.emit(this._value);
      this.propagateChange(this._value);
  }

  writeValue(value: any): void {
    this._value = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

}
