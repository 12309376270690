<div class = "history">
  <i class = "fa fa-times" (click) = "dismiss()"></i>

  <div>
      <h1><span *ngIf = "history">Past Shifts with </span><span *ngIf = "!history">Future Shifts with </span><span *ngIf = "userData?.entityId?.name">{{ userData.entityId.name }}</span></h1>
      <p *ngIf = "contractorPastShifts">{{ contractorPastShifts.length}} {{ contractorPastShifts.length === 1 ? 'Shift' : 'Shifts'}} </p>
  </div>


  <div class = "timings">
    <div *ngIf = "contractorPastShifts">
      <div *ngFor="let job of contractorPastShifts" class="inner">
          <app-shift-basic-info class = "equalize-col" [jobItem] = "job" [allClientUnits] = "allClientUnits" [readOnly] = "true"></app-shift-basic-info>
          
          <div class = "under-line"></div>
          
            <div class = "mont-bold small-font bottom-10 applicant-card remove-margin">{{ shiftStatus }}:</div>
              <div class="applicant-card"> 
                 <div class = "contractor-detail-card bottom-20"> 
                 <app-shift-detail-container [staffer] = "staffer" [userData] = "userData" [viewingHistory] = "true" [viewingHired]="true"></app-shift-detail-container> 
                 <div class = "button-stack">
                  <button (click)="addToTeam(staffer.staffer._id)" class="reject mont-regular">Add to a team
                  </button>
            
                  <button *ngIf = "staffer.status === 'interested'" (click)="hireAgain()" class = "hire">
                    <div class = "hire-txt">Hire again</div>
                    <div class = "ellipse"><i class = "fas fa-arrow-right"></i></div>
                  </button>
                </div> 
                </div>
               </div> 
               <div>
            
            <!-- <app-pagination *ngIf = "hiredStaffers && hiredStaffers.length" [staffersCount] = "hiredStaffers.length" (loadStaffers) = "loadHiredStaffers($event)"></app-pagination> -->
            
          </div>
          
          <!-- <span>{{ job.shiftStartTime | momentPipeUnix: 'DDD MMM YYYY'}}:</span>&nbsp;{{job.shiftStartTime | momentPipeUnix: 'hh:mm a'}} - {{job.shiftEndTime | momentPipeUnix: 'hh:mm a'}} -->
        <br/>
      </div>
    </div>
  </div>
  
</div>
