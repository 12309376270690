import { Component, EventEmitter, Input,  OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';


@Component({
    selector: 'app-form-input',
    templateUrl: './form-input.component.html',
    styleUrls: ['./form-input.component.scss'],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => FormInputComponent),
          multi: true
        }]
})
export class FormInputComponent implements OnInit, ControlValueAccessor {
    
    @Input() labelText: string;
    @Input() placeholder: string = '';
    @Input() inputType: string;
    @Input() control: FormControl;

    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    _value: string;

    propagateChange = (_: any) => {};
    propagateTouched = (_: any) => {};


    constructor() { }

    ngOnInit() {
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
        this.onChange.emit(value);
        this.propagateChange(this._value);
    }


    touched($event) {
        this.propagateTouched($event);
    }

    //Form Accessor Functions
    writeValue(value: any): void {
        this._value = value;
    }
    

    registerOnChange(fn: (value: any) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.propagateTouched = fn;
    }
}