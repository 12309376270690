import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-analytics-card',
    templateUrl: './analytics-card.component.html',
    styleUrls: ['./analytics-card.component.scss'],
})
export class AnalyticsCardComponent implements OnInit {

    @Input() cardData;
    @Input() isLoading;

    constructor(private router: Router) { }

    navigateToRoute(route){
        this.router.navigateByUrl(route)
    }



    ngOnInit() {

    }


}
