<div class="wrapper" [ngClass]="{'homeScreenMissingRating' : homeScreenMissingRating}">
  <div class="shifts-container ">
    <div class="date-section">
      <span>
        <img class=" " src="assets/images/clock.png" alt="">
        {{job.shiftStartTime| momentPipeUnix:'hh:mma'}} - {{job.shiftEndTime| momentPipeUnix:'hh:mma'}}
      </span>
      <span>
        <img class=" " src="assets/images/job.png" alt="">
        {{ ('SKILLS.' + job.skill) | translate }}
      </span>

    </div>
    <div class="info-section">
      <div class="skill-and-place">
        <section class="job-profile-section">
          <div class="job-profile-pic">
            <img class="profile-pic" src="{{profilePicture}}">
          </div>
          <div class="user-rating">
            <ion-icon class="star-icon" name="star"></ion-icon>
            <span class="rating">{{'MISSING_RATING.Rating' | translate}} </span>
            <span class="rating"> {{job.staffer.aggregated_rating}} </span>
            <span class="num-of-rated">
              ({{job.staffer.numOfRatedUsers  || 0}})
            </span>
          </div>
        </section>

        <section class="information-section">

          <div class="upcoming">

            <div class="job-detail">
              <h3 class="job-name">
                {{job.staffer.firstName}}
                {{job.staffer.lastName && job.staffer.isFullName ? job.staffer.lastName : job.staffer.lastName}}
              </h3>
              <table class="shift-table ">
                <tbody>
                  <tr>
                    <td class="icon-td">
                      <img class="staffer-icons" src="assets/images/calender.png" alt="">
                    </td>
                    <td class="">
                      <span>
                        {{ job.staffer.shifts_completed || 0 }}
                      </span>
                      {{'MISSING_RATING.Shifts Completed' | translate}}
                    </td>
                  </tr>
                  <tr>
                    <td class="icon-td">
                      <img class="staffer-icons" src="assets/images/proximity.png" alt="">
                    </td>
                    <td class="">
                      <span>
                        {{job.distanceFromShift}}KM
                      </span>

                      {{'MISSING_RATING.Away' | translate}}

                    </td>
                  </tr>
                  <tr>
                    <td class="icon-td">
                      <img class="staffer-icons" src="assets/images/money-earned.png" alt="">
                    </td>
                    <td class="">
                      <span>
                        {{ job.staffer.total_earned || 0 }}
                      </span> {{'MISSING_RATING.Earned' | translate}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="shift-properties info-row" *ngIf="!homeScreenMissingRating">
            <div
              [ngClass]="{'green-txt-icon' : (job.staffer?.lateArrival == 0 || !job.staffer?.lateArrival), 'yellow-txt-icon' : (job.staffer?.lateArrival > 0 && job.staffer?.lateArrival < 3), 'red-txt-icon' : job.staffer?.lateArrival >= 3}"
              style="margin-left: 0 !important;">
              <div class="properties-figure">
                {{job.staffer?.lateArrival || 0}}</div>
              <div class="properties-text">
                {{ 'HOME_TALENT.LATE_ARRIVALS' | translate }}
              </div>
            </div>

            <div
              [ngClass]="{'green-txt-icon' : (job.staffer?.shiftsCanceled == 0 || !job.staffer?.shiftsCanceled), 'yellow-txt-icon' : (job.staffer?.shiftsCanceled > 0 && job.staffer?.shiftsCanceled < 3), 'red-txt-icon' : job.staffer?.shiftsCanceled >= 3}">
              <div class="properties-figure">{{job.staffer?.shiftsCanceled || 0}}</div>

              <div class="properties-text" style="width: 100%">
                Shifts
                <span style="overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                  white-space: nowrap;">Cancelled</span></div>
            </div>

            <div
              [ngClass]="{'green-txt-icon' : (job.staffer?.noShows == 0 || !job.staffer?.noShows), 'red-txt-icon' : job.staffer?.noShows > 0}">
              <div class="properties-figure">{{job.staffer?.noShows || 0}}</div>

              <div class="properties-text no-show-padding">{{'HOME_TALENT.NO_SHOWS' | translate}}</div>
            </div>
          </div>

          <div class="shift-properties info-row" *ngIf="homeScreenMissingRating">
            <div
              [ngClass]="{'light-orange-txt-icon' : (job.staffer?.lateArrival < 3 || !job.staffer?.lateArrival), 'dark-orange-txt-icon' : (job.staffer?.lateArrival >= 3 && job.staffer?.lateArrival < 5), 'dark-blue-txt-icon' : job.staffer?.lateArrival >= 5}"
              style="margin-left: 0 !important;">
              <div class="properties-figure">
                {{job.staffer?.lateArrival || 0}}</div>
              <div class="properties-text">
                {{ 'HOME_TALENT.LATE_ARRIVALS' | translate }}
              </div>
            </div>

            <div
              [ngClass]="{'light-orange-txt-icon' : (job.staffer?.shiftsCanceled < 3 || !job.staffer?.shiftsCanceled), 'dark-orange-txt-icon' : (job.staffer?.shiftsCanceled >= 3 && job.staffer?.shiftsCanceled < 5), 'dark-blue-txt-icon' : job.staffer?.shiftsCanceled >= 5}">
              <div class="properties-figure">{{job.staffer?.shiftsCanceled || 0}}</div>

              <div class="properties-text" style="width: 100%">
                Shifts
                <span style="overflow: hidden;
                  text-overflow: ellipsis;
                  display: block;
                  white-space: nowrap;">Cancelled</span></div>
            </div>

            <div
              [ngClass]="{'light-orange-txt-icon' : (job.staffer?.noShows == 0 || !job.staffer?.noShows), 'dark-blue-txt-icon' : job.staffer?.noShows > 0}">

              <div class="properties-figure">{{job.staffer?.noShows || 0}}</div>

              <div class="properties-text no-show-padding">{{'HOME_TALENT.NO_SHOWS' | translate}}</div>
            </div>
          </div>
        </section>

      </div>

    </div>

    <div class="rate-section">
      <ion-button (click)="submit()" color="basic" class="ion-no-margin btn ">
        {{'MISSING_RATING.Rate Now' | translate}} </ion-button>
      <star-rating class="ratings" [value]="0" (rate)="onRate($event)" [totalstars]="5" checkedcolor="#ff4d00"
        uncheckedcolor="grey" size="28px">
      </star-rating>
    </div>
    <div *ngIf="job.companyAddress" class="address-section"
      (click)="showLocationOnMap(job.companyAddress.latitude,  job.companyAddress.longitude, job.entityName )">
      <img src="assets/images/white_address.png" alt="">
      <span>

        {{job.companyAddress?.street}}
      </span>
    </div>
  </div>







</div>