import { Directive, ElementRef, OnInit, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  selector: '[appCompanyInfoScreenCalculator]'
})
export class CompanyInfoScreenCalculationDirective implements AfterViewInit {
  totalHeight;
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.calculateOffset();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateOffset();
  }

  calculateOffset() {
    this.totalHeight = this.el.nativeElement.offsetHeight;
    const innerHeight = this.el.nativeElement.querySelector('.heading-wrapper').offsetHeight;
    const top = this.el.nativeElement.querySelector('.heading-wrapper').offsetTop;
    this.el.nativeElement.querySelector('.form-wrapper').style.minHeight = `${this.totalHeight - innerHeight - top
      }px`;

    if (this.totalHeight === 0) {
      setTimeout(() => {
        this.calculateOffset();

      }, 50);
    }
  }

}
