<ion-grid class="ion-no-padding main-wrapper">

  <ion-row class = "header-section">
    <div class = "upper-section">
      <div class = "head-section">
        <div class = "step-count">
          <span class = "smaller">Refer your friends</span>
        </div>
        <h1 class = "main-heading">and earn money</h1>
      </div>
    </div>
  </ion-row>

  <ion-row>
    <div class = "referral-invite">
      <div class = "invite-container">
        <div class = "referral-text">
          <div class = "circle">1</div>
            <div class = "referral-form">
              <p class = "referral-font">Use this form to invite your friends:</p>
              <form (ngSubmit)="processForm()" [formGroup]="referralForm">
                <ion-item class="form-item">
                  <ion-input class="form-fields firstName" required type="text" formControlName="name"
                    placeholder="{{ 'ONBOARDING_STAFFER_INFORMATION.name' | translate }}">
                  </ion-input>
                </ion-item>
      
      
                <ion-item class="form-item">
                  <ion-input type="email" formControlName="email" class="form-fields email"
                    placeholder="{{ 'ONBOARDING_STAFFER_INFORMATION.email' | translate }}"></ion-input>
                </ion-item>
      
                <ion-item class="form-item">
                  <ion-input type="text" formControlName="phoneNumber" class="form-fields phoneNumber"
                    placeholder="{{ 'ONBOARDING_STAFFER_INFORMATION.phoneNumber' | translate }}"></ion-input>
                </ion-item>
              </form>
              <div class = "next-btn">
                <ion-button class = "apply-btn" type="submit" color="basic" (click)="processForm()">
                  <div class = "apply-txt">Invite</div>
                </ion-button>
              </div>
              </div>
          </div>
      </div>
  
    <div class ="referral-text">
      <div class = "circle">2</div>
      <p class = "referral-font">Track the status of your referrals here:</p>
    </div>
     </div>
  </ion-row>

  <ion-row class = "card">
    <div >
        <div class = "search" *ngIf = "refList.length > 0">
          <input
            class="client-search-input"
            type="text"
            [(ngModel)]="searchQuery"
            (input)="onFieldChangeActiveRef()"
            *ngIf = "enableSearch"
          />
          <i class = "fa fa-search" *ngIf = "!enableSearch" (click) = "toggleSearch()"></i>
          <i class = "fa fa-times-circle" *ngIf = "enableSearch" (click) = "clearSearch($event)"></i>
        </div>

        <ion-grid>
          <ion-row *ngFor="let referral of refList" [ngClass] = "{'inner-row' : referral}">
            <ion-col  class = "universal-font center displayName">
              <span *ngIf = "referral.displayName">{{referral.displayName}}</span>
              <span *ngIf = "referral?.staffer?.firstName">{{referral.staffer.firstName}} {{referral.staffer.lastName}}</span>
            </ion-col>

            <ion-col class = "universal-font center">
              <div class="status flex-grow" >
                <span *ngIf = "!referral.staffer">{{ formatStatus(referral.status)}}</span>
                <span *ngIf = "referral?.staffer && (referral?.jobsHC > 0 || referral?.jobsNonHC > 0)"> {{ (referral.jobsNonHC + referral.jobsHC) }} shifts completed</span>
              </div>
    
            </ion-col>

            <!-- <ion-col class = "universal-font" *ngIf = "referral.amount">
              <span class="price">
                {{referral.amount}}
              </span>
            </ion-col> -->

            <ion-col class = "end" *ngIf = "referral.status == 'Invite sent' || referral.status == 'Registered' || referral.status == 'pending'" >
              <ion-button class = "active-btn" *ngIf="referral.status == 'Invite sent'" (click)="resendInvite(referral)">Resend
              </ion-button>

            <ion-button class = "active-btn" *ngIf = "referral.status == 'Registered'">Active</ion-button>

            <ion-button class = "inactive-btn" *ngIf = "referral.status == 'pending'">Inactive</ion-button>
            </ion-col>

            <ion-col class = "end" *ngIf = "referral?.staffer && referral?.referral?.status == 'Registered'">
              <ion-button class = "active-btn">Active</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
  </ion-row>





</ion-grid>