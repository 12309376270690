<ion-header class = "header">
  <ion-toolbar>
    <div class = "left-pane" slot = "start">
      <p class = "heading" *ngIf = "currentHeaderProperties?.heading">{{ currentHeaderProperties.heading }}</p>

      <ion-buttons slot = "start" *ngIf = "currentHeaderProperties?.button">
        <ion-button type="submit" (click) = "navigateTo(currentHeaderProperties?.navigateTo)">
          <div class = "broadcast-txt">{{currentHeaderProperties.button}}</div>
          <div class = "ellipse"><i class = "fas fa-arrow-right"></i></div>
        </ion-button>
      </ion-buttons>

      <div *ngIf = "currentHeaderProperties?.icon" class = "icon padding-right-40">
        <i [ngClass] = [currentHeaderProperties?.icon]></i>
      </div>

      <div *ngIf = "currentHeaderProperties?.toggleBar">
        <app-shared-toggle-bar  
        class = "header" 
        [userType] = "userType"
        [allView]="allView" 
        [recurrentView]="recurrentView" 
        [requestViewChange]="requestViewChange"
        [data] = "currentHeaderProperties?.toggleBar?.data" [paramValue] = "paramValue" (toggleBar) = "onShiftChange($event)"></app-shared-toggle-bar>
      </div>

    </div>
    
    <div class = "right-pane">
    <ion-title slot = "end">
      <div class = "entity-information">
        <span class = "image">
          <img class="user-img" src="{{entity?.creator?.profileUrl.url}}">
        </span>
        <span class = "name">{{ entity.name }}</span>
      </div>
      </ion-title>
      <ion-buttons slot = "end">
        <ion-button (click) = "toggleMenu()">
          <i class = "fa fa-chevron-down"></i>
        </ion-button> 
      </ion-buttons>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class = "menu" *ngIf = "showMenu">
    <ion-button class = "menu-dropdown" (click) = "logout()">
      <i class = "fa fa-sign-out-alt"></i>
      <span class = "text">Log out</span>
    </ion-button>
</ion-content>