import { Component, Input, OnInit } from '@angular/core';
import { BroadcastJobsUtilService } from 'src/modules/company-dashboard/services/broadcast-job-utils.service';

@Component({
  selector: 'app-form-google-places-input',
  templateUrl: './form-google-places-input.component.html',
  styleUrls: ['./form-google-places-input.component.scss'],
})
export class FormGooglePlacesInputComponent implements OnInit {

  @Input() formattedAddress: string = '';
  @Input() labelText: string = '';
  constructor(
    private broadcastJobsUtilService: BroadcastJobsUtilService
  ) { }

  ngOnInit() {}

  handleAddressChange(address){
    const formattedAddress = this.broadcastJobsUtilService.getFormatedAddress(address);
    // this.onPatientChange.emit({id: this.selectedPatient.id, address: formattedAddress })
  }
}
