<div class="child">
  <h1 class = "general-bold-large">Rating</h1>
  <div class = "rating-section">
    <p *ngIf='rating === 0' class = "general-regular">
      How are you enjoying the app?
    </p>
    <p *ngIf='rating > 0 && rating < 5' class = "general-regular">
      We're sorry you're not completely happy!
    </p>
    <star-rating *ngIf="rating == 0" class="ratings" [value]="0" (rate)="onRate($event)" [totalstars]="5" checkedcolor="#ff4d00"
      uncheckedcolor="grey" size="30px">
    </star-rating>
    <form class="form-wrapper" [formGroup]="rateAppForm" *ngIf="rating && rating < 5">
      <ion-item>
        <ion-input class = "general-regular" required type="text" formControlName="review" placeholder="What can we do better?">
        </ion-input>
      </ion-item>
    </form>
    <div *ngIf='rating === 5' class = "general-regular">
      <p>Thank you!  We love you too! Please show your support by rating us the {{deviceType}} store.</p>
    </div>
  </div>
  <div class = "button-stack">
    <button *ngIf='rating !== 5' (click)="cancel()" class="cancel">Cancel</button>
    <button *ngIf="rating === 5"(click)="rateApp()" class="ok">Ok</button>
    <button *ngIf="rating !==5" (click)="submit()" class="submit">Submit</button>
  </div>
</div>
