<div class = "dates-selection-container">

  <div class = "all-dates" (click) = "handleShowAllDates()">
    <span class = "all-dates-text" [ngClass] = "{'active-shift': showAllDates}">All Dates</span>
  </div>

  <div class="scrolling-list" *ngIf = "visibleItems">
    <div class = "inner">
      <ion-button (click)="previousItem()" [disabled] = "showAllDates || visibleItems[0] === sliderItems[0]">
        <i class = "fa fa-solid fa-chevron-left"></i>
      </ion-button>

      <div class = "dates-container">
        <ion-list lines="none" class="drawer" scrollX [ngClass] = "{'space-around' : visibleItems.length < 5}">
          <ion-item *ngFor="let item of visibleItems; let i = index" 
          class = "mont-regular" 
          [ngClass] = "{'active-shift': (item == sliderItems[selectedItemIndex] && !showAllDates)}"
          (click) = "activateSliderOption(item, i)">
            {{ item }}
          </ion-item>
        </ion-list>
      </div>
      <ion-button (click)="nextItem()" [disabled] = "visibleItems[visibleItems.length - 1] ===  sliderItems[sliderItems.length-1]">
        <i class="fa fa-solid fa-chevron-right"></i>
      </ion-button>
    </div>  
  </div>
</div>