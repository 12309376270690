<div class="main-container"
  [ngClass]="{'reset-padding' : user?.profile_approved, 'apply-padding' : !user?.profile_approved}">
  <div class="upper-section">
    <div class="head-section">
      <h1 class="main-heading" *ngIf="!user?.profile_approved">References<div class="icon-div"><i
            class="fa fa-question-circle" (click)="showHelpPopup()"></i></div>
      </h1>
      <div class="underline" *ngIf="!user?.profile_approved"></div>
      <div class="sub-heading">
        Tell us up to 3 of your most relevant job experiences
      </div>
      <div>
        <div class="form-section">
          <form *ngIf="informationForm" [formGroup]="informationForm" (submit)="submitForm()">
            <div class="form-initalize" formArrayName="referenceDetails"
              *ngFor="let cd of informationForm['controls'].referenceDetails['controls'] ;let i=index;">
              <i class="fa fa-trash" *ngIf="i >= 3" (click)="removeReference(i)"></i>
              <div formGroupName={{i}}>
                <div class="form-row">
                  <div class="field-column">
                    <ion-select ok-text="Select" [interfaceOptions]="{cssClass: 'references-select'}"
                      formControlName="referenceType" [(ngModel)]="cd.value.referenceType"
                      [ngClass]="{'disableOption' : cd.value.referenceType == ''}" name="{{ cd.value.referenceType}}">
                      <ion-select-option value="" disabled>Type</ion-select-option>
                      <ion-select-option class="selection" *ngFor="let refType of type"
                        [value]="refType">{{refType}}</ion-select-option>
                    </ion-select>


                    <ion-select (ionChange)="skillsUpdated(cd.value.skill, i)"
                      *ngIf="cd.value.referenceType === 'professional'" ok-text="Select"
                      [interfaceOptions]="{cssClass: 'references-select'}" formControlName="skill"
                      [ngClass]="{'disableOption' : cd.value.skill == null}" [(ngModel)]="cd.value.skill"
                      name="{{ cd.value.skill}}">
                      <ion-select-option value="" disabled>Job Title</ion-select-option>
                      <ion-select-option class="selection" *ngFor="let skillset of skills" [value]="skillset"
                        >{{skillset}}</ion-select-option>
                    </ion-select>



                    <ion-select *ngIf="cd.value.referenceType === 'personal'" ok-text="Select"
                      [interfaceOptions]="{cssClass: 'references-select'}" formControlName="relation"
                      [ngClass]="{'disableOption' : cd.value.relation == ''}" [(ngModel)]="cd.value.relation"
                      name="{{ cd.value.relation}}">
                      <ion-select-option class="disable-text" value="" disabled>How do you know
                        them?</ion-select-option>
                      <ion-select-option class="selection" *ngFor="let rel of relation" [value]="rel"
                        >{{rel}}</ion-select-option>
                    </ion-select>

                  </div>
                </div>

                <div class="form-row" *ngIf="cd.value.referenceType === 'professional'">
                  <div class="field-column">
                    <div class="date-head">
                      <ion-input *ngIf="cd.value.referenceType === 'professional'" type="text"
                        formControlName="companyName" placeholder="Company Name">
                      </ion-input>

                      <p *ngIf="cd.controls.companyName.invalid && (cd.controls.companyName.dirty || cd.controls.companyName.touched)"
                        class="err-msg">Invalid company name</p>
                    </div>

                    <div class="date-head">
                      <ion-input type="text" formControlName="contactPerson" placeholder="Contact person">
                      </ion-input>

                      <p *ngIf="cd.controls.contactPerson.invalid && (cd.controls.contactPerson.dirty || cd.controls.contactPerson.touched)"
                        class="err-msg">Invalid contact person</p>
                    </div>

                  </div>
                </div>

                <div class="form-row">
                  <div class="field-column">
                    <div class="date-head">
                      <ion-input type="email" formControlName="companyEmail" placeholder="E-mail">
                      </ion-input>
                      <p *ngIf="cd.controls.companyEmail.invalid && (cd.controls.companyEmail.dirty || cd.controls.companyEmail.touched)"
                        class="err-msg">Invalid email address</p>
                    </div>

                    <div class="date-head">
                      <ion-input type="text" formControlName="phoneNumber" inputmode="tel" type="tel"
                        placeholder="Phone Number">
                      </ion-input>

                      <p *ngIf="cd.controls.phoneNumber.invalid && (cd.controls.phoneNumber.dirty || cd.controls.phoneNumber.touched)"
                        class="err-msg">Invalid phone number</p>
                    </div>
                  </div>
                </div>


                <div class="form-row" *ngIf="cd.value.referenceType === 'professional'">
                  <div class="field-column">

                    <div class="date-head">
                      <!-- <ion-input (ionChange)="addressUpdates(cd, null, i)" class="date-head googlePlaces"
                      placeholder="Job location/facility" formControlName="location"></ion-input> -->
                      <app-places-autocomplete class="style-references form"
                        placeholder="Job location/facility" [formatedAddress]="cd.controls.location.value"
                        (addressChanged)="addressUpdates(cd, $event, i)"></app-places-autocomplete>

                      <p *ngIf="cd.controls.location.invalid && (cd.controls.location.dirty || cd.controls.location.touched)"
                        class="err-msg">Invalid location</p>
                    </div>

                    <ion-input class="date-head" type="text" formControlName="unitNumber" placeholder="Unit/department">
                    </ion-input>
                  </div>
                </div>

                <div class="form-row wrapping-row">

                  <div class="date-head border-bottom"
                    *ngIf="cd.value.isHCSkill && cd.value.referenceType === 'professional'">
                    <ion-select *ngIf="cd.value.referenceType === 'professional'" ok-text="Select"
                      [interfaceOptions]="{cssClass: 'references-select'}" formControlName="subSkill"
                      [ngClass]="{'disableOption' : !cd.value.subSkill}" name="{{ cd.value.subSkill}}">
                      <ion-select-option value="" disabled>Sub Skill/speciality</ion-select-option>
                      <ion-select-option class="selection" *ngFor="let subSkillSet of subSkills"
                        [value]="subSkillSet.name">{{subSkillSet.name}}</ion-select-option>
                    </ion-select>
                  </div>


                  <div class="date-head" *ngIf="cd.value.referenceType === 'personal'">
                    <ion-input type="text" formControlName="contactPerson" placeholder="Contact person">
                    </ion-input>
                    <p *ngIf="cd.controls.contactPerson.invalid && (cd.controls.contactPerson.dirty || cd.controls.contactPerson.touched)"
                      class="err-msg">Invalid contact person</p>
                  </div>

                  <div class="border-bottom date-head date-container" (click)="showDatePicker(cd, 'startDate', i)">
                    <div class="date-input">
                      <ion-input class="no-border" formControlName="startDate">
                        <div class="dates disable-text" *ngIf="cd.value.startDate === null">
                          <span *ngIf="cd.value.referenceType === 'professional'">Start Date</span>
                          <span *ngIf="cd.value.referenceType === 'personal'">Date you first met</span>
                        </div>

                        <div class="enable-text" *ngIf="cd.value.startDate !== null">
                          <span></span>
                        </div>
                      </ion-input>
                      <div class="icon">
                        <i class="fa fa-calendar"></i>
                      </div>

                      <p *ngIf="cd.controls.startDate.invalid && (cd.controls.startDate.dirty || cd.controls.startDate.touched)"
                        class="err-msg">Invalid start date</p>
                    </div>
                  </div>


                  <div *ngIf="cd.value.referenceType === 'professional'" class="border-bottom date-head date-container">
                    <div class="date-input">
                      <ion-input class="no-border" formControlName="endDate" (click)="showDatePicker(cd, 'endDate', i)">
                        <div class="dates disable-text"
                          *ngIf="!cd.value.isCurrentlyWorking && cd.value.endDate === null">
                          <span>End Date</span>
                        </div>

                        <div class="dates enable-text" *ngIf="cd.value.isCurrentlyWorking">
                          <span>Present day</span>
                        </div>


                        <div class="enable-text" *ngIf="!cd.value.isCurrentlyWorking && cd.value.endDate !== null">
                          <span></span>
                        </div>
                      </ion-input>
                      <div class="icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="add-another-ref" (click)="addNewReference()">
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
              <span>Add Another</span>
            </div>


            <div class="lower-section" *ngIf="!user?.profile_approved">
              <div class="complete-later">
                <ion-button (click)="completeLater()">Complete this later</ion-button>
              </div>


              <div class="remaining-div">
                <div class="next-btn">
                  <ion-button class="apply-btn" id="saveReference" type="submit" color="basic">
                    <div class="apply-txt">Next</div>
                    <div class="ellipse"><i class="fas fa-arrow-right"></i></div>
                  </ion-button>
                </div>
              </div>
              <div class="extra-spacing"></div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>