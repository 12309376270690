import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'clientFilter'
  })
  export class ClientFilterPipe implements PipeTransform {
    transform(data: any[], storageKey: string): any[] {

      const storedData = JSON.parse(localStorage.getItem(storageKey)) || [];


      // If the stored data is not an array, return the original data
      if (!storedData ||!Array.isArray(storedData)) {
        return data;
      }
  
      // Filter the original data based on the stored data
      return data.filter(item => {
        return !storedData.some(entity => item.entity._id === entity._id);
      });
    }
  }