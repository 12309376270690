import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';
import { ShiftValidatorService } from 'src/modules/company-dashboard/services/shift-validator.service';
import { JobService } from '../../services/job.service';
import { LoadingService } from '../../services/loading.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-edit-recurrent-specific-shifts',
  templateUrl: './edit-recurrent-specific-shifts.component.html',
  styleUrls: ['./edit-recurrent-specific-shifts.component.scss'],
})
export class EditRecurrentSpecificShiftsComponent implements OnInit {


  editRecurrentSpecificShiftForm: FormGroup;
  recurringShiftsCollection;
  @Input() jobItem: IClientJob
  @Input() timeZone: string;
  @Input() submitView
  confirmationView: Boolean = false;
  data = []

  config = ["Date", "Start Time", "End Time", "Unpaid Break", "Transit Allowance"]
  shifts = []

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private jobService: JobService,
    private popupService: PopupService,
    private shiftValidatorService: ShiftValidatorService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.createForm();


    this.shifts = this.jobItem.jobs.map((item, index) =>
    ({
      Date: moment(item.shiftDate).tz(item.timeZone).format('DD MMM,YY'),
      'Start Time': moment(item.shiftStartTime, 'X').tz(item.timeZone).format('hh:mm A'),
      'End Time': moment(item.shiftEndTime, 'X').tz(item.timeZone).format('hh:mm A'),
      'Unpaid Break': item.break ? item.break.unpaid : "No break",
      'Transit Allowance': item.travelTime.toString(),
      _id: item._id,
      index
    }))
    if (this.shifts.length > 2) {
      this.data = this.shifts.slice(0, 2)
    } else {
      this.data = this.shifts;
    }

  }

  async loadMoreData({ startIndex, endIndex }) {
    const loader = await this.loadingService.showLoading().toPromise();

    setTimeout(() => {
      this.data = [...this.shifts.slice(startIndex, endIndex)]
      this.loadingService.hideLoading(loader);
    }, 2000)


  }

  createForm() {
    this.recurringShiftsCollection = [];
    for (let i = 0; i < this.jobItem.jobs.length; i++) {
      this.recurringShiftsCollection.push(this.formBuilder.group({
        date: [moment(this.jobItem.jobs[i].shiftDate).tz(this.timeZone).format('DD MMM,YY'), [Validators.required]],
        _id: [this.jobItem.jobs[i]._id],
        startTime: [{ value: this.jobItem.jobs[i].shiftStartTime, formatted: moment(this.jobItem.jobs[i].shiftStartTime, 'X').tz(this.timeZone).format('hh:mm A') }, [Validators.required, this.shiftValidatorService.validateTime('startTime')]],
        endTime: [{ value: this.jobItem.jobs[i].shiftEndTime, formatted: moment(this.jobItem.jobs[i].shiftEndTime, 'X').tz(this.timeZone).format('hh:mm A') }, [Validators.required, this.shiftValidatorService.validateTime('endTime')]],
        unpaidBreak: [{ label: this.jobItem.jobs[i].break ? this.jobItem.jobs[i].break.unpaid : "No Break", value: this.jobItem.jobs[i].break ? this.jobItem.jobs[i].break.unpaid : "No break" }],
        transitAllowance: [{ label: this.jobItem.jobs[i].travelTime ? `${this.jobItem.jobs[i].travelTime.toString()} Hour` : "None", value: this.jobItem.jobs[i].travelTime ? this.jobItem.jobs[i].travelTime.toString() : "0", }],
      })
      );
    }

    this.editRecurrentSpecificShiftForm = this.formBuilder.group({
      recurringShifts: this.formBuilder.array(this.recurringShiftsCollection)
    })

  }

  trackShiftPropertyChanges(updateView: Boolean = false) {
    let updatedData = [];
    let data = [];

    for (const item of this.shifts) {
      const shift = this.editRecurrentSpecificShiftForm.value.recurringShifts.find(obj => obj._id == item._id);

      if (!shift) {
        continue;
      }

      if (item["Date"] !== shift.date
        || item["Start Time"] !== shift.startTime.formatted
        || item["End Time"] !== shift.endTime.formatted
        || item["Unpaid Break"] !== shift.unpaidBreak.value
        || item["Transit Allowance"] !== shift.transitAllowance.value) {

        updatedData.push({
          shiftDate: shift.date,
          _id: shift._id,
          shiftStartTime: shift.startTime.value,
          shiftEndTime: shift.endTime.value,
          unpaidBreak: shift.unpaidBreak.value,
          transitAllowance: shift.transitAllowance.value
        })
        data.push(item)
      }
    }

    if (updateView) {
      this.data = data;
      this.shifts = this.data;
    }
    return updatedData;
  }

  async onSubmit(cb) {
    this.editRecurrentSpecificShiftForm.markAllAsTouched();

    if (this.editRecurrentSpecificShiftForm.valid) {
      const loader = await this.loadingService.showLoading().toPromise();
      try {

        await this.jobService.editRecurrentSpecificShifts(this.jobItem.recurringShift.groupJobId, { jobs: this.trackShiftPropertyChanges() });

        this.loadingService.hideLoading(loader);
        this.popupService.showModal({
          heading: 'Success',
          message: 'Successfully edit the recurring shifts',
          btn: 'Dismiss',
          navigateRoute: '',
          imgURL: 'assets/images/request-sent.png'
        }, async (data) => {
        if(cb){
          await cb()
        }
        });

      }
      catch (error) {
        this.loadingService.hideLoading(loader);
        this.popupService.showModal({
          heading: 'Error',
          message: error.error || 'Something went wrong, Please try again',
          btn: 'Dismiss!',
          navigateRoute: '',
          imgURL: 'assets/images/sorry1.png'
        });
      }
    }
    return true;
  }
}
