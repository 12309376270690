import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';

interface RecurringShift {
  Date: string;
  'Start Time': string;
  'End Time': string;
}

@Component({
  selector: 'app-recurring-shift-list',
  templateUrl: './recurring-shift-list.component.html',
  styleUrls: ['./recurring-shift-list.component.scss'],
})
export class RecurringShiftListComponent implements OnInit {
  @Input() data: RecurringShift[] = [];
  @Input() config: string[] = [];
  checkBoxOptions = [{ 
    label: ""
  }]
  @Output() onToggleCheckBox = new EventEmitter<{ checked: boolean; index: number }>();
  @Output() onToggleAllCheckBoxes = new EventEmitter<{ checked: boolean; index: number }>();

  ngOnInit() {}

  toggleCheckBox(value: boolean, index: number) {
    this.onToggleCheckBox.emit({ checked: value, index });
  }

  toggleAllCheckBoxes(value: boolean, index: number) {
    this.onToggleAllCheckBoxes.emit({ checked: value, index });
  }
}

