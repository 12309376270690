import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StarRatingComponent } from 'ng-starrating';
import { UtilService } from 'src/modules/shared/services/util.services';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-rate-app-modal',
  templateUrl: './rate-app-modal.component.html',
  styleUrls: ['./rate-app-modal.component.scss'],
})
export class RateAppModalComponent implements OnInit {
  public rateAppForm: FormGroup;
  rating: number = 0;
  isBrowser: boolean = false;
  deviceType: string = ''
  constructor(private modalController: ModalController, 
    private platform: Platform, private utilService: UtilService, private market: Market, private theInAppBrowser: InAppBrowser) { }

  ngOnInit() {
    this.createReviewForm();
    this.isBrowser = this.platform.is('desktop');
    this.deviceType = this.getDeviceType()
  }

  createReviewForm(){
    this.rateAppForm = new FormGroup({
      review: new FormControl('', [Validators.required])
    });
  }

  cancel() {
    this.modalController.dismiss();
  }

  getDeviceType(){
    if(this.platform.is('android')){
      return 'Play'
    } else if(this.platform.is('ios') || this.platform.is('ipad')){
      return 'App'
    } else {
      return "Browser"
    }
  }

  onRate(event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    if (event.newValue) {
      this.rating = event.newValue
    
    }
  }
  // User rated below 5
  submit() {
    if (this.rateAppForm.valid) {
      const data = {
        rated: true,
        rating: this.rating,
        review: this.rateAppForm.value.review
      }
      this.modalController.dismiss(data);
    }

  }
  // User rated 5 star
  rateApp(){
    const data = {
      rated: true,
      rating: this.rating,
      review: ''

    }

    this.modalController.dismiss(data);
    if(this.platform.is('android')){
      this.market.open('ca.staffy.app');
    } else {
      this.theInAppBrowser.create("itms-apps://itunes.apple.com/app/id1133559351", "_system");
    } 
  }
}
