import { Component, EventEmitter, Input, OnInit, Output, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true
    }
  ]
})
export class FormSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() labelText: string;
  @Input() options : Array<any>;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() showPopup: EventEmitter<any> = new EventEmitter<any>();
  @Input() placeholder: string;
  @Input() selectedValue: any;
  @Input() dataKey: string = '';
  @Input() icon: string = '';
  @Input() disableList: boolean;
  @Input() name: string;

  resultantObject = {};
  value: any;
  control;
  _value: string;
  onChanged;
  onTouched;

  constructor(
    private parentControl: ControlContainer

  ) { }

  ngOnInit() {
    this.value = this.selectedValue;
    this.control = this.parentControl.control.get(this.name);
  }

  ngOnChanges() {
    this.value = this.selectedValue;
  }

  handleChange(event){
  //Mark control as touched when the value is changed
    this.control.markAsTouched();
    if(!this.dataKey){
      this.onChange.emit(event.detail.value);
      return;
    }

    //Find the matching value in array of object and return the object
    this.resultantObject = this.fetchObject();
    
    this.onChange.emit(this.resultantObject);
  }

  fetchObject(): Object {
    return this.options.find( (obj) => {return obj[this.dataKey] === this.value})
  }

  handlePopup() {
    this.showPopup.emit();
  }

  //Form Accessor Functions for custom components
  writeValue(value: any): void {
    this._value = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
