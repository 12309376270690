import { Injectable } from '@angular/core';
import { IAvailableShiftsPaginatedResponse } from '../interfaces/available-shifts-paginated-response';
import { ITalentJob } from '../interfaces/talent-job.interface';

@Injectable({
  providedIn: 'root'
})
export class TalentJobSearchService {
  constructor() {}

  doLocalSearch(availableShiftsData: IAvailableShiftsPaginatedResponse, searchTerm: string) {
    let searchResults: ITalentJob[] = [];

    if (availableShiftsData && availableShiftsData.results) {
      searchResults = availableShiftsData.results.filter(i => {
        if (i.entity.name) {
          return i.entity.name.match(new RegExp(searchTerm, 'ig')) != null;
        }
      });
    }

    return searchResults;
  }
}
