import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITalentDashboardState } from './talent-dashboard.state';

export const selectTalentDashboard = createFeatureSelector<ITalentDashboardState>('talent-dashboard');

export const getUpcomingShifts = createSelector(
    selectTalentDashboard,
    state => state && state.upcomingShifts
);

export const getFutureShiftsCOunt = createSelector(
    selectTalentDashboard,
    state => state && state.futureUpcomingShiftsCount
);


