<div class="full-height">
  <div class="outer">
    <div class="wrapper">
      <div class="outer-circle" [ngClass]="{ 'align-center': isShiftEnded }">
        <div class="timing-section" *ngIf="!isShiftEnded">
          <div class="timeToShow">{{ timeToShow }}</div>
          <div class="time-stamp"><span>HH</span><span>MM</span><span>SS</span></div>
          <div class="hours-needed">{{ hours }} hours needed today</div>

          <div class="play-resume">
            <i *ngIf="staffy?.tracker?.state == 'played'" (click)="pauseTracker()" class="fas fa-pause"></i>
            <i *ngIf="staffy?.tracker?.state == 'paused'" (click)="resumeTracker()" class="fas fa-play"></i>
          </div>
        </div>

        <i class="fa fa-check" *ngIf="isShiftEnded"></i>

        <div class="timing-section" *ngIf="isShiftEnded">
          <img src="assets/images/clap.png" />
          <div class="hours-needed">Today's shift completed</div>
        </div>
      </div>
    </div>

    <circle-progress
      [clockwise]="true"
      [percent]="percentage"
      [radius]="100"
      [showTitle]="false"
      [showSubtitle]="false"
      [outerStrokeGradient]="false"
      [showUnits]="false"
      [outerStrokeWidth]="16"
      [innerStrokeWidth]="0"
      [innerStrokeColor]="'#ffffff'"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [outerStrokeColor]="'#ff4d00'"
      [innerStrokeColor]="'#ff4d00'"
      [outerStrokeGradientStopColor]="'#ff4d00'"
    >
    </circle-progress>
  </div>

  <div
    *ngIf="!isShiftEnded"
    [ngClass]="{ 'button-section': true, 'page-end': !(jobItem?.alayacareShift?.isTrue && jobItem?.alayacareShift?.tasks?.length) }"
  >
    <ion-button [disabled]="!isAllowedToClockOut" class="login-btn" type="submit" (click)="endShiftOffline()">
      <div class="login-txt">Clock-out</div>
      <div class="ellipse"><i class="fas fa-arrow-right"></i></div>
    </ion-button>
  </div>
</div>