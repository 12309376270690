import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import * as moment from 'moment-timezone';
import 'moment-duration-format';
import { TalentDashboardUtilService } from '../../../talent-dashboard/services/talent-dashboard-util.service';
import { findIndex, forEach } from 'lodash';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { TalentSyncService } from 'src/modules/talent-dashboard/services/talent-sync.service';
import { ChooseTimeModalComponent } from '../choose-time-modal/choose-time-modal.component';
import { TalentLocationTrackingService } from 'src/modules/talent-dashboard/services/talent-location-tracking.service';
import { ISetting } from 'src/modules/talent-dashboard/interfaces/settings.interface';
import { LocalStorageService } from '../../services/local-storage.service';
import { TalentDashboardService } from 'src/modules/talent-dashboard/services/talent-dashboard.service';
import { RateAppModalComponent } from 'src/modules/talent-dashboard/components/rate-app-modal/rate-app-modal.component';
import { LoadingService } from '../../services/loading.service';
import { UserService } from '../../services/user.service';
import { AnalyticsService } from 'src/modules/shared/services/analytics.service';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';

@Component({
  selector: 'shared-time-tracker',
  templateUrl: './time-tracker-home.component.html',
  styleUrls: ['./time-tracker-home.component.scss']
})
export class TimeTrackerHomeComponent implements OnInit {
  @ViewChild('startTime', { static: false }) sTime;

  @Input() page: 'dashboard-card' | 'clock-in-out';
  @Input() userData: IUser;
  @Input() jobItem: ITalentJob;
  @Output() timerEnded = new EventEmitter();
  timeToShow = '00:00';
  staffy;
  totalWorked: number;
  myInterval: any;
  startTime: number;
  endTime: number;
  pickedTime: number;
  settings: ISetting;
  totalTime: number = 0;
  percentage;
  hours;
  totalHours;
  totalSeconds;
  isShiftEnded: boolean = false;
  geoCheckFailureCount = 0;
  isAllowedToClockOut = false;

  constructor(
    private talentDashboardUtilService: TalentDashboardUtilService,
    private popupService: PopupService,
    private modalCtrl: ModalController,
    private talentLocationTrackingService: TalentLocationTrackingService,
    private utilService: UtilService,
    private talentSyncService: TalentSyncService,
    private userService: UserService,
    private loadingService: LoadingService,
    private talentDashboardService: TalentDashboardService,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private talentJobFormatService: TalentJobFormatService
  ) {}

  ngOnInit() {
    this.initilize();
  }

  ngOnChanges(){
    this.isAllowedToClockOut = this.checkCareNotesExistIfRequired()
  }

  currentStaffy(staffys: Array<any>) {
    for (const staffy of staffys) {
      if (staffy.staffer === this.userData._id) {
        return staffy;
      }
    }
  }

  formatTracker(time: number) {
    this.timeToShow = moment.duration(time).format('hh:mm:ss', { trim: false });
    
    if(!this.isShiftEnded)
      this.percentage = this.talentJobFormatService.calculateJobProgress(moment.duration(time).asHours(), this.hours);
  }

  initilize() {
    // When tracker state is stopped
    // or tracker not started yet
    // format timeToShow to 00:00:00
    this.timeToShow = '00:00';

    // find current user
    this.staffy = this.currentStaffy(this.jobItem.staffersAndShifts);
    // if tracker not started
    // then return;
    if (!this.staffy.tracker) {
      return;
    }

    this.totalHours = this.talentJobFormatService.getTotalJobHoursNeeded(this.jobItem.shiftStartTime, this.jobItem.shiftEndTime);
    this.hours = this.totalHours.asHours().toFixed(2);

    this.totalSeconds = this.totalHours.asMilliseconds();

    // Calculate total time worked
    this.totalWorked = this.talentDashboardUtilService.totalTimeWorked(this.staffy);
    if (this.staffy.tracker.state === 'played') {
      // this will show some time but static
      this.formatTracker((this.totalWorked = this.totalWorked + 1000));
      // after one second above time will change after each one second
      this.talentDashboardUtilService.startInterval(() => {
        this.formatTracker((this.totalWorked = this.totalWorked + 1000));
      }, 1000);
    } else if (this.staffy.tracker.state === 'paused') {
      this.formatTracker(this.totalWorked);
    }
    // if job has finished show total worked time
    else if (this.staffy.tracker.state === 'stopped' && this.staffy.state === 'complete') {
      this.formatTracker(this.totalWorked);
    }

    const startTime24HoursFormat = moment(this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftStartTime, true), ["h:mm A"]).format("HH:mm").split(":")[0];
    const endTime24HoursFormat = moment(this.talentDashboardUtilService.optimizeTime(this.jobItem.shiftEndTime, true), ["h:mm A"]).format("HH:mm").split(":")[0];
    this.totalTime = Math.abs(+endTime24HoursFormat - +startTime24HoursFormat);
  }

  async pauseTracker() {
    const stafferIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);
    this.jobItem.staffersAndShifts[stafferIndex]['tracker']['state'] = 'paused';
    this.jobItem.staffersAndShifts[stafferIndex]['tracker']['breaks'].push({
      start: +moment().format('X'),
      end: 0
    });
    await this.talentDashboardUtilService.saveShiftInLocalStorage(this.jobItem);
    await this.talentSyncService.sync();
    this.cancelInterval();
  }

  async resumeTracker() {
    const stafferIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);
    this.jobItem.staffersAndShifts[stafferIndex]['tracker']['state'] = 'played';
    forEach(this.jobItem.staffersAndShifts[stafferIndex].tracker.breaks, b => {
      if (b.end === 0) {
        b.end = +moment().format('X');
      }
    });
    await this.talentDashboardUtilService.saveShiftInLocalStorage(this.jobItem);

    await this.talentSyncService.sync();
    this.cancelInterval();
  }

  async endShiftOffline() {
    if(!this.checkCareNotesExistIfRequired()){
      await this.showCareNotesRequiredPopup();
      return;
    }

    try {
      this.settings = await this.talentDashboardService.getSettings();

      if (!this.jobItem.entity.switchOffLocationTracking && !this.userData.enableToStartShiftWithoutLocationCheck) {
        if (this.settings && this.settings.enableLocBaseShiftStart) {
          this.talentLocationTrackingService.checkIfUserIn100meters(
            this.jobItem._id,
            'end',
            () => {
              this.constinueEndingTheShift();
            },
            () => {
              this.constinueEndingTheShift();
            }
          );
        } else {
          this.constinueEndingTheShift();
        }
      } else {
        this.constinueEndingTheShift();
      }
    } catch (err) {
      this.settings = await this.talentDashboardService.getSettings();
      this.constinueEndingTheShift();
    }
  }


  updateProgressBar() {
    this.isShiftEnded = true;
    this.percentage = 100;
  }

  async constinueEndingTheShift() {

    const currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);

    this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['end'] = +moment().format('X');

    const totalLoggedHours = moment
      .duration(this.talentDashboardUtilService.totalTimeWorked(this.jobItem.staffersAndShifts[currentUserIndex]))
      .format('h:mm', { trim: false });

    const data = {
      heading: 'Are you sure you want to clock out?',
      message: 'If you are taking a break you do not have to clock out, this time is included in your shift.',
      rejectBtnText: 'No',
      btn: 'Yes',
      navigateRoute: '',
      imgURL: 'assets/images/alarm.png'
    }

    this.popupService.showModal(data, async (data) => {

      await this.popupService.showModal({
        heading: 'Hours Confirmation!',
        message: 'You are about to log' + ' ' + totalLoggedHours + 'hours. Please confirm to proceed?',
        btn: 'Confirm hours',
        rejectBtnText: 'Edit time',
        navigateRoute: null,
        imgURL: 'assets/images/alarm.png'
      },
        () => {
          this.endShiftContinue();
        }, () => {
          this.askStartTime();
        })
    })


  }

  async askStartTime() {
    const currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);

    const startTime = this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['start'];
    const endTime = +moment().tz(this.jobItem.timeZone).format('X');

    let slider = {
      floor: startTime,
      ceil: endTime,
      min: +moment.unix(startTime).tz(this.jobItem.timeZone).startOf('day').format('X'),
      max: +moment.unix(endTime).tz(this.jobItem.timeZone).endOf('day').format('X'),
    };

    const datePickerModal = await this.modalCtrl.create({
      component: ChooseTimeModalComponent,
      cssClass: 'forgotPassModal',
      componentProps: {
        slider
      }
    });
    await datePickerModal.present();

    datePickerModal.onDidDismiss().then(async (data) => {
      if (data && data.data) {
        const pickedStartTime = data.data.startTime;
        if (pickedStartTime >= this.jobItem.shiftStartTime) {
          this.startTime = pickedStartTime;

          this.endTime = data.data.endTime;

          if (this.startTime > this.endTime) {
            this.endTime = +moment(this.endTime, 'hh:mm a').tz(this.jobItem.timeZone).seconds(0).add(1, 'day').format('X');
          }
          if (moment.duration(moment(Number(this.endTime)).diff(Number(this.startTime)), 'seconds').asHours() > 14) {
            this.endTime = +moment.unix(this.endTime).tz(this.jobItem.timeZone).subtract(1, 'day').format('X');
          }

          if (this.endTime <= +moment.unix(this.jobItem.shiftEndTime).add(2, 'hour').tz(this.jobItem.timeZone).format('X')) {
            const hasUserAgreed = await this.askForManualHourChargeConfirmation();

            if (!hasUserAgreed) {
              return;
            }
            this.endShiftContinue();
          } else {
            this.startTime = null;
            this.popupService.showModal({
              heading: 'STOP',
              message: `Sorry, your logged hours are more than 2 hours over the scheduled shift time.  You can only log up to 2 hours of additional time.`,
              btn: 'Dismiss',
              navigateRoute: null,
              imgURL: 'assets/images/sorry1.png'
            });
          }

        } else {
          this.startTime = null;
          this.popupService.showModal({
            heading: 'STOP',
            message: `Sorry, your specified start time is before the scheduled shift start time.`,
            btn: 'Dismiss',
            navigateRoute: null,
            imgURL: 'assets/images/sorry1.png'
          });
        }
      }
    });
  }


  async endShiftContinue(forceSkipGeoCheck = false) {
    
    const loading = await this.loadingService.showLoading().toPromise();
    
    // finding current user index in job.staffersAndShifts
    const currentUserIndex = findIndex(this.jobItem.staffersAndShifts, ['staffer', this.userData._id]);

    this.jobItem.staffersAndShifts[currentUserIndex]['state'] = 'complete';
    this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['state'] = 'stopped';

    if (this.startTime && typeof this.startTime !== 'undefined') {
      this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['start'] = this.startTime;
      this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['end'] =
        this.endTime && this.endTime !== null ? this.endTime : +moment().format('X');
      this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['hasAddedFromStaffer'] = true;
    } else {
      this.jobItem.staffersAndShifts[currentUserIndex]['tracker']['end'] = +moment().format('X');
    }

    try {
      const location = await this.talentLocationTrackingService.getUserLocation();
      await this.talentDashboardService.stopTimer(
        this.jobItem._id,
        this.staffy.tracker.end,
        this.staffy.staffer,
        {
          latitude: `${location.coords.latitude}`,
          longitude: `${location.coords.longitude}`
        },
        this.staffy.tracker.start,
        forceSkipGeoCheck ? true:  this.staffy.tracker.hasAddedFromStaffer,
        forceSkipGeoCheck
      );

      // // saving/updating shift in local storage
      await this.talentDashboardUtilService.saveShiftInLocalStorage(this.jobItem);

      this.cancelInterval();

      this.popupService.showModal(
        {
          heading: 'Shift ended',
          message: `Shift ended. The creator will be sent a notification.`,
          btn: 'Dismiss',
          navigateRoute: null,
          imgURL: 'assets/images/clap.png'
        },
        async () => {
          this.updateProgressBar();
          if (this.userData.shifts_completed == 0) {
            const userIndustry = await this.utilService.getIndustryBySkillName(this.jobItem.skill);
            this.analyticsService.trackEvent('job_completed', 'User Flow', '4 Complete Job', 'app', userIndustry);
          }

          if (
            this.userData &&
            (!this.userData.appRating || !this.userData.appRating.isRated) &&
            !this.platform.is('desktop') &&
            this.settings[0].enableAppRating
          ) {
            this.rateApp();
          }
        }
      );

      this.timerEnded.emit({
        jobItem: this.jobItem,
        shiftEnds: false
      });
    } catch (error) {
      let errMsg = 'Something went wrong';
      if (error.error === 'USER_NOT_ON_JOB_LOCATION') {
        errMsg = 'You cannot clock out outside your job location.'
        this.geoCheckFailureCount++;
        if (this.geoCheckFailureCount >= 3) {
          this.checkForGeoCheckFailureCount();
          return;
        }

      }

      if (error.error === 'INVALID_STOP_TIME') {
        errMsg = 'You cannot clock out before the shift starts.'
      }

      this.popupService.showModal({
        heading: 'Error!',
        message: errMsg,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: 'assets/images/alert.png'
      });
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

  cancelInterval() {
    this.talentDashboardUtilService.stopInterval();
    this.initilize();
  }

  async rateApp() {
    const rateModal = await this.modalCtrl.create({
      component: RateAppModalComponent,
      cssClass: 'forgotPassModal',
      componentProps: {}
    });
    await rateModal.present();

    rateModal.onDidDismiss().then((dataReturned: any) => {
      if (dataReturned.data) {
        this.addRating(dataReturned.data);
      }
    });
  }

  async addRating(appRating) {
    const loading = await this.loadingService.showLoading().toPromise();
    try {
      const data = {
        appRating: {
          isRated: appRating.rated,
          rating: appRating.rating,
          feedback: appRating.review,
          ratingDate: +moment.tz('America/Toronto').format('X')
        }
      };
      await this.userService.updateUserPromise(this.userData._id, data);
    } catch (err) {
      console.log(err);
    } finally {
      this.loadingService.hideLoading(loading);
    }
  }

  private async askForManualHourChargeConfirmation(): Promise<boolean> {
    return new Promise(async (resolve, rejectPromise) => {
      this.popupService.showModal(
        {
          heading: 'Hour adjustment request',
          message: `Please note that you should be logging your hours properly via the app. 
                Manual adjustments may result in a $20 fee on your invoice.`,
          btn: 'Accept',
          rejectBtnText: 'Reject',
          imgURL: 'assets/images/sorry1.png',
          navigateRoute: null
        },
        () => resolve(true),
        () => resolve(false)
      );
    });
  }

  private checkCareNotesExistIfRequired(): boolean {
    const isCareNoteRequired = this.jobItem.entity.homecareClient &&
      this.jobItem.entity.homecareClient.isTrue && 
      this.jobItem.entity.homecareClient.careNotes && 
      this.jobItem.entity.homecareClient.careNotes.isRequired;

    return isCareNoteRequired ? this.areCareNotesSubmitted() : true;
  }

  private areCareNotesSubmitted() : boolean {
   
      const isCareNoteMissing = patient => !patient.careNotes;
  
      // check if care notes are missing for any patient
      const notesMissingForAnyPatient = this.jobItem.homecareShift && this.jobItem.homecareShift.patients && this.jobItem.homecareShift.patients.some(isCareNoteMissing);
  
      // if care notes are missing for any patient, return false
      return !notesMissingForAnyPatient;
    }

  private async showCareNotesRequiredPopup(): Promise<void> {
    await this.popupService.showModal({
      heading: 'Alert!',
      message: 'You must fill out the Care Notes before you Clock-out',
      btn: 'Ok',
      navigateRoute: null,
      imgURL: 'assets/images/alert.png'
    });
  }
  
  private async checkForGeoCheckFailureCount() {
    const data = {
      heading: 'You are not at job location, Are you still want to clock out?',
      message: 'If you clock out outside your job location then the payment for this shift will be required 5 days to process',
      rejectBtnText: 'No',
      btn: 'Yes',
      navigateRoute: '',
      imgURL: 'assets/images/alarm.png'
    }
    this.popupService.showModal(data, () => {
      this.endShiftContinue(true);
    })
  }

}
