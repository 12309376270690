import { Injectable } from '@angular/core';
import { filter, forEach, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { HttpPromiseService } from 'src/modules/shared/services/http-promise.service';
import { UtilService } from 'src/modules/shared/services/util.services';
import { ContractUpdateNoticeComponent } from 'src/modules/shared/components/contract-update-notice/contract-update-notice.component';
import { maxBusinessContractVersionSupported } from 'src/modules/shared/utils/constants';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { EditUnitNumberComponent } from 'src/modules/shared/components/edit-unit-number/edit-unit-number.component';
import { IClientJob } from '../interfaces/client-job.interface';
import { ModalController } from '@ionic/angular';
import { TalentJobFormatService } from 'src/modules/talent-dashboard/services/talent-job-format.service';
import { SkillsService } from 'src/modules/shared/services/skills.service';
import { UserService } from 'src/modules/shared/services/user.service';
import { FilterMappingService } from './filter-mapping.service';


@Injectable({
    providedIn: 'root'
})
export class BroadcastJobsUtilService {
    constructor(
        private httpPromiseService: HttpPromiseService,
        private utilService: UtilService,
        private popupService: PopupService,
        private modalController: ModalController,
        private talentJobFormatService: TalentJobFormatService,
        private userService: UserService,
        private filterMappingService: FilterMappingService
    ) { }

    generatedStepArray(dateValue?: string, initialValue?: string) {
        // datevalue accept in the format of moment(date, 'MM/DD/YYYY').tz(tz).format();
        let stepsArray = [];
        // We don't want to start our loop with dateValue but we use the dateValue in out moment object
        // So that purpose we initialize "i = 6"
        // If date value hour is less then 6 Am then generate step array from 6 AM
        const dateValueStartFrom = dateValue && !initialValue ? +moment(dateValue).format('H') : 6;
        // for current day
        for (let i = dateValueStartFrom; i <= 24; i++) {

            stepsArray.push(
                {
                    value: dateValue ? +moment(dateValue).startOf('day').add(i, 'hours').format('X') :
                        +moment().startOf('day').add(i, 'hours').format('X'), formatted: dateValue ?
                            moment(dateValue).startOf('day').add(i, 'hours').format('hh:mm A')
                            : moment().startOf('day').add(i, 'hours').format('hh:mm A')
                },
                {
                    value: dateValue ? +moment(dateValue).startOf('day').add(i, 'hours').add(15, 'minutes').format('X')
                        : +moment().startOf('day').add(i, 'hours').add(15, 'minutes').format('X'), formatted: dateValue
                            ? moment(dateValue).startOf('day').add(i, 'hours').add(15, 'minutes').format('hh:mm A')
                            : moment().startOf('day').add(i, 'hours').add(15, 'minutes').format('hh:mm A')
                },
                {
                    value: dateValue ? +moment(dateValue).startOf('day').add(i, 'hours').add(30, 'minutes').format('X')
                        : +moment().startOf('day').add(i, 'hours').add(30, 'minutes').format('X'), formatted: dateValue
                            ? moment(dateValue).startOf('day').add(i, 'hours').add(30, 'minutes').format('hh:mm A')
                            : moment().startOf('day').add(i, 'hours').add(30, 'minutes').format('hh:mm A')
                },
                {
                    value: dateValue ? +moment(dateValue).startOf('day').add(i, 'hours').add(45, 'minutes').format('X')
                        : +moment().startOf('day').add(i, 'hours').add(45, 'minutes').format('X'), formatted: dateValue
                            ? moment(dateValue).startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                            : moment().startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                }
            );
        }

        // for next day - midnight.
        for (let i = 1; i <= 11; i++) {

            if(i !== 11) {
                        stepsArray.push(
                            {
                                value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').format('X')
                                    : +moment().add(1, 'days').startOf('day').add(i, 'hours').format('X'), formatted: dateValue
                                        ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').format('hh:mm A')
                                        : moment().add(1, 'days').startOf('day').add(i, 'hours').format('hh:mm A')
                            },
                            {
                                value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(15, 'minutes').format('X')
                                    : +moment().add(1, 'days').startOf('day').add(i, 'hours').add(15, 'minutes').format('X'), formatted: dateValue
                                        ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(15, 'minutes').format('hh:mm A')
                                        : moment().add(1, 'days').startOf('day').add(i, 'hours').add(15, 'minutes').format('hh:mm A')
                            }
                        );
            }
            if (i === 11) {
            

             stepsArray.push(
                {
                    value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').format('X')
                        : +moment().add(1, 'days').startOf('day').add(i, 'hours').format('X'), formatted: dateValue
                            ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').format('hh:mm A')
                            : moment().add(1, 'days').startOf('day').add(i, 'hours').format('hh:mm A')
                }
            );
                /*stepsArray.push(
                    {
                        value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('X')
                            : +moment().add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('X'), formatted: dateValue
                                ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                                : moment().add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                    },
                    {
                        value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(59, 'minutes').format('X')
                            : +moment().add(1, 'days').startOf('day').add(i, 'hours').add(59, 'minutes').format('X'), formatted: dateValue
                                ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(59, 'minutes').format('hh:mm A')
                                : moment().add(1, 'days').startOf('day').add(i, 'hours').add(59, 'minutes').format('hh:mm A')
                    }
                );*/
            } 

            else {
                stepsArray.push(
                    {
                        value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(30, 'minutes').format('X')
                            : +moment().add(1, 'days').startOf('day').add(i, 'hours').add(30, 'minutes').format('X'), formatted: dateValue
                                ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(30, 'minutes').format('hh:mm A')
                                : moment().add(1, 'days').startOf('day').add(i, 'hours').add(30, 'minutes').format('hh:mm A')
                    },
                    {
                        value: dateValue ? +moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('X')
                            : +moment().add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('X'), formatted: dateValue
                                ? moment(dateValue).add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                                : moment().add(1, 'days').startOf('day').add(i, 'hours').add(45, 'minutes').format('hh:mm A')
                    }
                );
            }
        }
        

        
        var elementPos = stepsArray.map(function(x) {return x.formatted; }).lastIndexOf('12:00 AM');
        
        stepsArray.splice(elementPos, 0, {formatted: 'Next Day'})

        return stepsArray;
    }

    getFormatedAddress(googlePlace) {
        const addressFormat: IUserAddress = { city: '', latitude: 0, longitude: 0, default: false, province: '', street: '', code: '' };
        if (googlePlace) {
            forEach(googlePlace.address_components, (addressComp) => {
                if (addressComp.types.indexOf('locality') !== -1 && addressComp.types.indexOf('political') !== -1) {
                    addressFormat.city = addressComp.long_name.split(' ').join('_');
                }

                if (addressComp.types.indexOf('administrative_area_level_1') !== -1) {
                    addressFormat.province = addressComp.long_name.split(' ').join('_');
                }

                if (addressComp.types.indexOf('postal_code') !== -1 || addressComp.types.indexOf('postal_code_prefix') !== -1) {
                    addressFormat.code = addressComp.long_name;
                }

            });

            addressFormat.street = googlePlace.formatted_address;
        }

        if (googlePlace && googlePlace !== 'ZERO_RESULTS'
            && googlePlace.geometry && googlePlace.geometry.location
            && googlePlace.geometry.location.lat && googlePlace.geometry.location.lng) {

            const long = googlePlace.geometry.location.lng();

            const lat = googlePlace.geometry.location.lat();

            addressFormat.latitude = lat;
            addressFormat.longitude = long;
        }

        return addressFormat;
    }

    // getExpectedRates(skill: string, coords: {latitude: number, longitude: number}): Promise<{averageRate: number}>{
    //     return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/stats/predict-rate?skill=${skill}&latitude=${coords.latitude}&longitude=${coords.longitude}`)
    // }

   
    getExpectedRates(skill: string, coords: {latitude: number, longitude: number}): Promise<{averageRate: number}>{
        return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/stats/predict-rate?skill=${skill}&latitude=${coords.latitude}&longitude=${coords.longitude}`)
    }
    
    async requiredTrainingSubSkill(requiredTrainingSubSkills: string[], skill: string, subSkills: string[]): Promise<string[]> {
        
        // check if the selected skill is a nursing skill
        const isNursingSkill = this.utilService.isHCNursingSkill(skill);
        
        // check if the selected skill is a nursing skill and entity has `requiredTrainingSubSkills` array then pre populate sub skills  
        if(isNursingSkill && requiredTrainingSubSkills) {
            subSkills = [...subSkills, ...requiredTrainingSubSkills];
            // uniq sub skills when skills are changed 
            subSkills = uniq(subSkills);
        } else {
            // if the selected skill is not nursing skill or no required training is set, remove all the selected sub skills
            subSkills = [];
        }
        return subSkills;
    }

    async isLatestContractSignedByClient(companyType: string, clientContractVersion: number, contractCutoffDate: any, isShiftsBlocked: boolean, email: string): Promise<Boolean> {
        const isHospitalityClient = companyType === 'Hospitality';
        const hasSignedLatestContract = maxBusinessContractVersionSupported === clientContractVersion;
        if (!isHospitalityClient || (isHospitalityClient && hasSignedLatestContract)) {
            return true;
        }

        let isClientEmailWhiteList: { status? : Boolean} = await (this.userService.checkEmailWhiteListStatus(email).toPromise())

        if(isClientEmailWhiteList.status){
            return true
        }

        
        this.popupService.showCustomComponentModal({
            component: ContractUpdateNoticeComponent,
            customComponentParams: {
                cutOffDate: contractCutoffDate,
                noticeType: 'client',
                isShiftsBlocked,
                allowClose: true,
            }
        });

        return false;
    }

    
  async editUnitNumber(jobItem, unitNumber: string[], action: string): Promise<IClientJob> {
    const myModal = await this.modalController.create({
      component: EditUnitNumberComponent,
      cssClass: 'wideModal',
      showBackdrop: true,
      componentProps: {
        jobItem: jobItem.jobs || jobItem,
        shiftUnitNumber : this.talentJobFormatService.unitNumberByShift(jobItem),
        unitNumbers: unitNumber,
        action: action
      }
    });

    await myModal.present();
    return await myModal.onDidDismiss().then((data) => {
      let updatedJob = data.data;
        if(!updatedJob){
            return jobItem;
        }

        jobItem = this.onSavedUnitNumber(updatedJob, jobItem);
        
        return jobItem;
    });
  }

  onSavedUnitNumber(data: {payload: [{jobId: string, unitNumber: string, isChanged: boolean}]}, jobItem) {        
    if (jobItem.recurringShift.isTrue) {
      //update the unit number of recuring shift
      //update unit number by job index
      data.payload.forEach(( shift ) => {

        //Identiy job index to update unit number in recurring shifts at front end 
        const index = jobItem.jobs.findIndex((job) => {return job._id === shift.jobId}); 
    

        if(index > -1) {
            jobItem.jobs[index].unitNumber = shift.unitNumber;
        }

    
    })
    } else {
      //update the unit number of non recuring shift
      //payload array will always have one element in a non recurring shift
      jobItem.unitNumber = data.payload[0].unitNumber;
      jobItem.jobs[0].unitNumber = jobItem.unitNumber;
    }

    return jobItem;
  }  


  getMinimumRateByProvince(province: string): number {
    const minRate = {
    'Ontario': 14,
        'British_Columbia': 11.5,
        'New_York': 13
    }
    return minRate[province] || 11.5;   
  }

  formatUrl(shiftType: string, urlParams: {showRegularShifts: boolean, showRecurringShifts: boolean}): string {
 
    const {showRegularShifts, showRecurringShifts } = urlParams;
    // Base URL
    let url = `/api/v2/jobs/client-shifts?preset=clientShift`;

    // Helper function to apply common filters
    const applyCommonFilters = (isFilled: boolean) => {
        const filterParams = this.filterMappingService.applyShiftFilters({ 'regular': showRegularShifts, 'recurring': showRecurringShifts });
        return `&isFilled=${isFilled}${filterParams ? filterParams : ''}`;
    };

    const applyTimeParams = (applyTimeConstraint: boolean) => {
        const shiftEndTime = +moment().subtract(2, 'hours').unix();
        return applyTimeConstraint ? `&shiftEndTime=gte:${shiftEndTime}`: '';
    }

    // Collect URL parameters based on shiftType
    switch (shiftType) {
    case 'ongoing':
        url += `&ongoing=true`;
        break;

    case 'filled':
        url += applyCommonFilters(true) + applyTimeParams(true); 
        break;

    case 'open':
        url += applyCommonFilters(false) + applyTimeParams(true);
        break;
    }
    return url;
    }

    updateContractorCountForShift(jobItem) {
        jobItem.confirmedStaffers = this.utilService.isStaffersConfirmedShifts(jobItem.staffersAndShifts, true).length;
        jobItem.pendingStaffers = this.utilService.isStaffersConfirmedShifts(jobItem.staffersAndShifts, false).length;
        jobItem.interestedStaffers = this.utilService.filterInterestedStaffers(jobItem.isABroadcastRequest.broadcastedTo).length;
        return jobItem;  
      } 
    
    
      removeRejectedStaffers(jobItem: IClientJob): IClientJob {
        if (jobItem.isABroadcastRequest && jobItem.isABroadcastRequest.broadcastedTo.length > 0) {
          // calculate total number of confirm staffers
    
          jobItem.isABroadcastRequest.broadcastedTo = filter(jobItem.isABroadcastRequest.broadcastedTo, i => {
            return i.status !== 'rejected';
          });
        }
    
        return jobItem;
      }
}
