import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';
import { NewPatientFormComponent } from '../new-patient-form/new-patient-form.component';

@Component({
  selector: 'app-single-patient-shift-selection',
  templateUrl: './single-patient-shift-selection.component.html',
  styleUrls: ['./single-patient-shift-selection.component.scss'],
})
export class SinglePatientShiftSelectionComponent implements OnInit {

  @Input() entityId: string;
  @Input() availablePatients: HomecarePatient[] = [];
  @Output() onPatientChange: EventEmitter<HomecarePatient> = new EventEmitter();

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  handlePatientChange(data){
    this.onPatientChange.emit(data);
  }


  async showNewPatientForm(){
    const patientModal = await this.modalController.create({
      component: NewPatientFormComponent,
      cssClass: 'patientsModal',
      backdropDismiss: true,
      componentProps: {
        entityId: this.entityId,
        addPatientAddress: (newPatient: {id: string, address: IUserAddress}) => {
          this.availablePatients.push(newPatient);
        }
      }
    });

    patientModal.present();
  }

}
