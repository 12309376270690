<div class="wrapper" (click)="wrapperClicked()">
  <ion-row class="p0 box-shadow">
    <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
      <div class = "jobPosition">
        <p class="input-label">
          {{'BROADCAST_REQUEST.Job Position' | translate}}
        </p>
        <div class = "static-select-width">
          <select [(ngModel)]="selectedSkill" class="position select-font" (change)="handleSkillChange()">
            <option *ngFor="let skill of industries" [ngValue]="skill" style="text-transform: capitalize">
              {{ ('SKILLS.' + skill) | translate }}
            </option>
          </select>
        </div>
      </div>
    </ion-col>

    <ion-col class="hidden-mob content-padding" *ngIf="!isDirectHire" size-xl="6" size-lg="6" size-md="6" size-sm="12"
      size-xs="12">
      <div style = "width: 100%">
        <p class="input-label">
          {{'BROADCAST_REQUEST.No.of Vacancies' | translate}}
        </p>
        <input [(ngModel)]="vacancies" class="form-input" min="1" (keyup)="vacancyChange()" (change)="vacancyChange()"
          type="number">
      </div>
    </ion-col>

    <ion-col class="hidden-mob content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="12"
      size-xs="12">
      <div class="expand-width">
        <p class="input-label">
          {{'BROADCAST_REQUEST.Hourly Rate' | translate}}
        </p>
        <div class = "static-right">
          <select [(ngModel)]="rate" class="position select-font" (change)="handleRateChange()" [disabled] = "!allowRateChange">
            <option *ngFor="let skillsRate of listOfRates" [ngValue]="skillsRate">{{skillsRate.label}}</option>
          </select>
        </div>
      </div>
    </ion-col>

    <ion-col *ngIf = "isDirectHire" class="hidden-mob content-padding" size-xl="6" size-lg="6" size-md="6">
      <div class="blue-background"></div>
    </ion-col>
    <ion-row style="flex-grow: 1;justify-content: center;" *ngIf="rate && rate.id < averageRate">
        <p style="padding: 0px;
          padding-left: 12px;
          font-size: small;
          color: red;">The average rate for this position in your area is currently ${{averageRate.toFixed(2)}}</p>
    </ion-row>
  </ion-row>

  <ion-row class="hidden-desktop-mobile content-padding box-shadow">
    <ion-col class="content-padding" *ngIf="!isDirectHire">
      <div>
        <h3 class="input-label">
          {{'BROADCAST_REQUEST.No.of Vacancies' | translate}}
        </h3>
        <input [(ngModel)]="vacancies" class="form-input" min="1" (keyup)="vacancyChange()" (change)="vacancyChange()"
          type="number">
      </div>
    </ion-col>

    <ion-col class="broadcast-option">
      <div>
        <h3 class="input-label">
          {{'BROADCAST_REQUEST.Hourly Rate' | translate}}
        </h3>

        <div class = "static-select-width">
          <select [(ngModel)]="rate" class="position select-font" (change)="handleRateChange()" [disabled] = "!allowRateChange">
            <option *ngFor="let skillsRate of listOfRates" [ngValue]="skillsRate">{{skillsRate.label}}</option>
          </select>
        </div>
      </div>
    </ion-col>
  </ion-row>


  


  <ion-row *ngIf="isHomeCareClient" class="full-width box-shadow">
    <ion-col *ngIf="isMutliplePatientSelectionAllowed" class="content-padding" size-xl="12" size-lg="12" size-md="12"
      size-sm="12" size-xs="12">
      <app-multiple-patient-shift-selection [entityId]="userData.entityId._id" [availablePatients]="availablePatients"
        (onPatientChange)="handlePatientSelection($event)"></app-multiple-patient-shift-selection>
  
    </ion-col>
  
    <ion-col *ngIf="!isMutliplePatientSelectionAllowed" class="content-padding" size-xl="12" size-lg="12" size-md="12"
      size-sm="12" size-xs="12">
      <app-single-patient-shift-selection [entityId]="userData.entityId._id" [availablePatients]="availablePatients"
        (onPatientChange)="handlePatientSelection($event)"></app-single-patient-shift-selection>
    </ion-col>
  </ion-row>
  
  <div *ngIf="isHomeCareClient">
    <ion-row *ngFor="let patient of initialData.homecareShift.patients;trackBy: trackByIndex" class="full-width box-shadow">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <app-shift-tasks [entityId]="userData.entityId._id" [availableTasks]="availableTasks"
          (onTasksSelectionChange)="getSelectedTasks($event,patient)" class="'broadcast-request'" [icon]="'fa fa-plus'"
          [title]="patient.id"></app-shift-tasks>
      </ion-col>
    </ion-row>
  
  </div>

  
  


  <ion-row *ngIf="subSkills" class = "full-width box-shadow">
    
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <p class="input-label eventLabel">
          Sub Skills
        </p> 
      </ion-col>
    
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="selectUnitNumber">
          <div class = "static-right">
            <app-searchable-select [selectedOptions]="selectedSubSkills" multiple = "true" (onSelect) = "handleSubSkillChange($event)" [options]="subSkillOptions" ></app-searchable-select>
            <!-- <ion-select placeholder = "Select Sub skill" multiple = "true" [(ngModel)]="selectedSubSkills" class="position select-font" ok-text = "Select" (ionChange) = "handleSubSkillChange()" [interfaceOptions] = "{cssClass: 'references-select'}">
              <ion-select-option class = "selection"class = "selection" *ngFor="let subSkill of subSkills" [value]="subSkill.name" style="text-transform: capitalize">
                {{subSkill.name}}
              </ion-select-option>
            </ion-select> -->
          </div>
        </div>
      </ion-col>
    
  </ion-row>

  <ion-row class = "full-width box-shadow" *ngIf="!isHomeCareClient" >
    
  
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <p class="input-label eventLabel">
          {{'BROADCAST_REQUEST.Add Unit Number' | translate}}
        </p> 
      </ion-col>
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="selectUnitNumber">
          <div class = "static-select-width static-right">
            <select [(ngModel)]="selectedUnitNumber" class="position select-font" (change)="handleUnitNumberChange()">
              <option style="text-transform: capitalize" value="" disabled>
                {{'BROADCAST_REQUEST.Select Unit Number' | translate}}
              </option>
              <option *ngFor="let unitNumber of unitNumbers" [ngValue]="unitNumber" style="text-transform: capitalize">
                {{unitNumber}}
              </option>
            </select>
          </div>
        </div>
      </ion-col>
  </ion-row>
  
  <ion-row class = "full-width box-shadow">
    <ion-row class = "full-width">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <p class="input-label eventLabel">
          {{'BROADCAST_REQUEST.Disclaimer' | translate}}
        </p>
        
      </ion-col>
    </ion-row>
  
  
    <ion-row class="eventType full-width">
  
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <textarea class="form-input" placeholder="Add disclaimer here..." [(ngModel)]="disclaimer" (change)="handleDisclaimerChange()" ></textarea>
      </ion-col>
    </ion-row>
  </ion-row>
  
  <ion-row class = "full-width box-shadow">
    <ion-row class = "full-width">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <p class="input-label eventLabel">
          {{'BROADCAST_REQUEST.Shift Type' | translate}}
        </p>
        
      </ion-col>
    </ion-row>


    <ion-row class="eventType full-width">
      <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6">
        <div class="label-div">
         <div class = "eventType-checkbox static-select-width-event-type">
            <label class="day-label">
              {{'BROADCAST_REQUEST.Single Day' | translate}}
            </label>
            <label class="container eventDay">
              <input type="radio" [checked]="eventType =='single'" (change)="handleDayChange($event, 'single')">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </ion-col>

       <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6">
        <div class="label-div">
         <div class = "eventType-checkbox static-select-width-event-type multi-day">
            <label class="day-label">{{'BROADCAST_REQUEST.Multi Day' | translate}}</label>
            <label class="container">
                <input type="radio" [checked]="eventType =='multiple'" (change)="handleDayChange($event, 'multiple')">
                <span class="checkmark" style="top: 0"></span>
              </label>
          </div>
        </div>
      </ion-col>

      <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="6" size-xs="6">
        <div class="label-div">
         <div class = "eventType-checkbox static-select-width-event-type multi-day">
            <label class="day-label">{{'BROADCAST_REQUEST.Training Shift' | translate}}</label>
            <label class="container">
                <input type="checkbox" [checked]="isTrainingShift" (change)="handleTrainingShiftChange($event)">
                <span class="checkmark" style="top: 0"></span>
              </label>
          </div>
        </div>
      </ion-col>
    </ion-row>
</ion-row>


  <ion-row class = "box-shadow">
    <div class = "calendar">
      <div class = "month-year">
        {{activatedMonth}} - {{activatedYear}}
        <i class = "fas fa-caret-left" (click)="onMonthBack()"></i>
        <i class = "fas fa-caret-right" (click)="onMonthNext()"></i>
      </div>
      <div class = "multiple-event-calendar" *ngIf = "eventType === 'multiple' && selectedMultipleDates.length > 1">
        <label class="container">
          <input type="radio" [checked] = "setDifferentTime" (click)="handleDifferentTime()">
          <span class="checkmark" style="top: 0"></span>
            <p class = "diff-timing">Select different timings for each day</p>
        </label>
      </div>
    </div>
    <br>
    <div class = "dates-row">
      <button class = "date-num" *ngFor="let item of dates" [ngClass]="{'bg-grey' :  item.isDisabled, 'bg-orange' : item.isSelected == true}" (click) = "selectedDate(item)" [disabled] = "item.isDisabled"> 
        {{item.date}}
      </button>

      <span class = "hidden-mob date-num" *ngFor="let i of extraColumnDesktop"></span>
      <span class = "hidden-desktop-mobile date-num" *ngFor="let i of extraColumnMobile"></span>
    </div>
  </ion-row>

  <ion-row class="shiftTime box-shadow">
    <ion-col class="broadcast-option" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
      <div *ngIf="eventType =='single'" class="content-padding">
        <p class="input-label">
          {{'BROADCAST_REQUEST.Date' | translate}}
        </p>
        <input class = "form-input selected-date" type = "text" readonly="readonly" [(ngModel)] = "selectedSingleDate">
      </div>

      <div *ngIf="eventType =='multiple' && !setDifferentTime" class="content-padding-without-bottom">
        <p class="input-label shift-time">
          {{'BROADCAST_REQUEST.Select Dates' | translate}}
        </p>

          <input class = "form-input selected-date" type = "text" readonly="readonly" [(ngModel)] = "selectedMultipleDates">
      </div>


    </ion-col>

    <ion-col class="content-padding shift-timing" *ngIf="!setDifferentTime" size-xl="4" size-lg="4" size-md="4"
      size-sm="4" size-xs="4">
      <div>
        <p class="input-label" [ngClass] = "{'increase-height' : (eventType =='multiple' && !setDifferentTime), 'shift-time' : (eventType == 'multiple' && !setDifferentTime)}">
          {{'BROADCAST_REQUEST.Start Time' | translate}}
        </p>

        <div class = "static-left-right">
          <select [(ngModel)]="singleDayStartTime" [compareWith]="compareTime" (change)="handleStartTimeChange()"
            class="position select-font">
            <option *ngFor="let time of startTimeValues " [ngValue]="time" [disabled]="time.formatted == 'Next Day'"> 
              {{time.formatted}}
          </select>
        </div>
      </div>
    </ion-col>

    <ion-col class="content-padding shift-timing" *ngIf="!setDifferentTime" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
      <div>
        <p class="input-label" [ngClass] = "{'shift-time' : (eventType == 'multiple' && !setDifferentTime)}">
          {{'BROADCAST_REQUEST.End Time' | translate}}
        </p>
        <div class = "static-right">
          <select [(ngModel)]="singleDayEndTime" [compareWith]="compareTime" (change)="handleEndTimeChange()"
          class="position select-font">
            <option *ngFor="let time of endTimeValues" [ngValue]="time" [disabled]="time.formatted == 'Next Day'"> 
              {{time.formatted}}
            </option>
          </select>
        </div>
      </div>
    </ion-col>
  </ion-row>



  <ion-row *ngIf="setDifferentTime && multipleTimingsValues?.length" class = "content-padding box-shadow">
    <ion-row class = "full-width listOfTime" *ngFor="let mTime of multipleTimingsValues; let i = index">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
         <div class="multiple-time-date">
          <p class="input-label">
            {{'BROADCAST_REQUEST.Day' | translate}} {{ i + 1}}
            <span class="sub-heading-date">
              {{mTime.date}}
            </span>
          </p>
        
        </div>
      </ion-col>
      <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
        <div class="distinct-timing-container">
          <span class="input-label">
            {{'BROADCAST_REQUEST.Start Time' | translate}}
          </span>
          <select [(ngModel)]="mTime.startTime" class="position select-font" (change)="handleMultipleTimeChange(i)">
            <option *ngFor="let time of mTime.startTimeValues" [ngValue]="time"
              [disabled]="time.formatted == 'Next Day'">
              {{time.formatted}}
            </option>
          </select>
        </div>
      </ion-col>

      <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
        <div class="distinct-timing-container">
          <span class="input-label">
            {{'BROADCAST_REQUEST.End Time' | translate}}
          </span>
          <select [(ngModel)]="mTime.endTime" class="position select-font" (change)="handleMultipleTimeChange(i)">
            <option *ngFor="let time of mTime.endTimeValues" [ngValue]="time" [disabled]="time.formatted == 'Next Day'">
              {{time.formatted}}
            </option>
          </select>
        </div>
      </ion-col>
      <ion-col class="content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="4" size-xs="4">
        <div class="distinct-timing-container">
          <span class="input-label">
            {{'BROADCAST_REQUEST.Unpaid Break' | translate}}
          </span>

          <div class="static-right">
            <select [(ngModel)]="mTime.unpaidBreak" class="position mods-input"
              (change)="unpaidBreakForDistinctTimes(i, $event)">
              <option value="" disabled="true">
                {{'BROADCAST_REQUEST.Select' | translate}}
              </option>
              <option value="No break">
                {{'BROADCAST_REQUEST.No Break' | translate}}
              </option>
              <option value="15 minutes">15
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="30 minutes">30
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="45 minutes">45
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="1 hour">60 {{'BROADCAST_REQUEST.minutes' | translate}}</option>
              <option value="2 hour">120 {{'BROADCAST_REQUEST.minutes' | translate}}</option>
            </select>
          </div>
        </div>
      </ion-col>

    </ion-row>
    <ion-row class = "full-width">
      <ion-col class = "content-padding" size-xl="4" size-lg="4" size-md="4" size-sm="12" size-xs="12" offset-lg="8" offset-xl="8" offset-md="8">
        <div class="discard-btn" (click)="discardDiffTime()">
          {{'BROADCAST_REQUEST.DISCARD' | translate}}
        </div>
      </ion-col>
    </ion-row>
  </ion-row>


  <!-- Add Shift Notes Here! -->
  <ion-row class = "box-shadow">
    <ion-col class = "content-padding shift-notes-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
      <div>
        <p class="all-blues input-label">
          {{'BROADCAST_REQUEST.Add Shift Notes' | translate}}
        </p>
        <!-- <input [(ngModel)]="shiftNotes" class="shift-notes" type="text" (focusout)="focusOutShiftNote()"
          placeholder="Shift Notes"> -->
          <div  [innerHTML]="shiftNotes | sanitizeHtml" contentEditable="true" (focusout)="focusOutShiftNote($event)"   class="shift-notes"  style="outline:0px;"></div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class = "box-shadow mods-row">
    <!-- <ion-row>
      <ion-col size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="p0">
        <div class="mods" (click)="showMods = !showMods">
          <i *ngIf="showMods == false" class="fas fa-arrow-down mod-icon"></i>
          <i *ngIf="showMods == true" class="fas fa-arrow-up mod-icon"></i>
          <span class="mods-text">
            {{'BROADCAST_REQUEST.MODS' | translate}}
          </span>
        </div>
      </ion-col>
    </ion-row> -->
    
    <ion-row *ngIf="showMods">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12"
        *ngIf="companyType === 'Hospitality' && !isDirectHire">
        <div class = "event-name">
          <p class="input-label">
            {{'BROADCAST_REQUEST.Purchase Order' | translate}} 
            <!-- 25th Jan 2023 - Renaming event name to purchase order  -->
          </p>
          <input class="mods-input" type="text" (focusout)="focusOutEventName()" [(ngModel)]="eventName"
            placeholder="Add Purchase Order">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </div>
      </ion-col>

      <ion-col class="broadcast-option" size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
        <div class="content-padding-unpaid">
          <p class="input-label">
            {{'BROADCAST_REQUEST.Unpaid Break' | translate}}
          </p>

          <div class = "static-right">
            <select [(ngModel)]="unpaidBreak" class="position mods-input" (change)="unpaidBreakChange()">
              <option value="" disabled="true">
                {{'BROADCAST_REQUEST.Select_Option' | translate}}
              </option>
              <option value="No break">
                {{'BROADCAST_REQUEST.No Break' | translate}}
              </option>
              <option value="15 minutes">15
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="30 minutes">30
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="45 minutes">45
                {{'BROADCAST_REQUEST.minutes' | translate}}
              </option>
              <option value="1 hour">60 {{'BROADCAST_REQUEST.minutes' | translate}}</option>
              <option value="2 hour">120 {{'BROADCAST_REQUEST.minutes' | translate}}</option>
            </select>
          </div>
        </div>
      </ion-col>

      <ion-col class = "content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
        <div class = "content-padding-transit">
          <p class="input-label">
            {{'BROADCAST_REQUEST.Transit Allowance' | translate}}

          </p>
          <div class = "static-right">
            <select class="position mods-input" (change)="travelTimeChange()" [(ngModel)]="travelTime">
              <option value="" disabled="true">
                {{'BROADCAST_REQUEST.Select Value' | translate}}
              </option>
              <option value="0">{{'BROADCAST_REQUEST.None' | translate}}</option>
              <option value="1">1 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="2">2 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="3">3 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="4">4 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="5">5 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="6">6 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="7">7 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="8">8 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="9">9 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
              <option value="10">10 {{'BROADCAST_REQUEST.Hour' | translate}}</option>
            </select>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="showMods && !isDirectHire">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="content-padding-broadcast-team">
          <p class="input-label" style="margin-bottom: 3px">
            {{'BROADCAST_REQUEST.Broadcast to Team' | translate}}
          </p>
          <div class = "text-and-checkbox static-select-width-broadcast-team">
            <label class="day-label">{{'BROADCAST_REQUEST.Broadcast to Team' | translate}}</label>
            <label class="container content-padding-right-checkbox">
              <input type="checkbox" [(ngModel)]="broadCastToTeam" name="checkbox" (change)="broadCastToTeamChange()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="showMods && companyType !== 'Healthcare'">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="content-padding-broadcast-team">
          <p class="input-label" style="margin-bottom: 3px">
            {{'BROADCAST_REQUEST.Tip Included' | translate}}
          </p>
          <div class = "text-and-checkbox static-select-width-broadcast-team">
            <label class="day-label">{{'BROADCAST_REQUEST.Tip Included' | translate}}</label>
            <label class="container content-padding-right-checkbox">
              <input type="checkbox" [(ngModel)]="isTipIncluded" name="checkbox" (change)="tipIncludedChange()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="showMods && !isDirectHire && certificateRequired?.length">
      <ion-col class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="content-padding-broadcast-team">
          <p class="input-label" style="margin-bottom: 3px">
            {{'BROADCAST_REQUEST.Credential Restrictions' | translate}}
          </p>
          
          <div *ngFor = "let cert of certificateRequired">
          <div class = "text-and-checkbox static-select-width-broadcast-team">
            <label class="day-label">{{cert.type}}</label>
            <label class="container content-padding-right-checkbox">
              <input type="checkbox" [(ngModel)]="cert.isRequired" name="checkbox" (change)="certificateRequiredChange()">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="content-padding"
        *ngIf="broadCastToTeam && showMods && !isDirectHire" size-xl="12" size-lg="12" size-md="12" size-sm="12"
        size-xs="12">
        <div class="broadcast-to-team">
          <div class = "static-select-width-end-select-team">
          <select class="position" [(ngModel)]="selectedTeam" (change)="selectedTeamChange()">
            <option [value]="undefined" selected disabled>
              {{'BROADCAST_REQUEST.Select Team/s' | translate}}
            </option>
            <option *ngFor="let team of teams" [ngValue]="team">{{team.name}}</option>
          </select>
        </div>
        </div>
      </ion-col>

      <ion-col class="content-padding" *ngIf="eventType =='multiple' && showMods && !isDirectHire" size-xl="12"
        size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="content-padding-broadcast-team">
            <p class="input-label">
              {{'BROADCAST_REQUEST.Individual Shifts' | translate}}
            </p>
            <div class = "text-and-checkbox static-select-width-broadcast-team">
              <label class="day-label">
                {{'BROADCAST_REQUEST.Create Individual Shifts' | translate}}
              </label>
              <label class="container">
                <input type="checkbox" [(ngModel)]="individualShifts" name="checkbox" (change)="individualShiftsChange()">
                <span class="checkmark"></span>
              </label>
            </div>
        </div>
      </ion-col>
    </ion-row>

    <!-- <ion-row *ngIf="showMods == true">
      <ion-col class="certificate broadcast-option" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
      <div>
        <p class="input-label">
          {{'BROADCAST_REQUEST.Certifications' | translate}}
        </p>

        <ion-row class = "row-padding">
          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                {{'BROADCAST_REQUEST.Food Handler' | translate}}
              </label>
              <label class="container">
                <input [(ngModel)]="foodHandlerCertification" (change)="certificationChange('foodHandlerCertification')"
                  type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                {{'BROADCAST_REQUEST.Smart Serve' | translate}}
              </label>
              <label class="container">
                <input [(ngModel)]="smartServerCertification" (change)="certificationChange('smartServerCertification')"
                  type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="all-blues">
          <p class = "input-label">
            {{'BROADCAST_REQUEST.HEALTHCARE_WORKERS' | translate}}
          </p>
        </ion-row>

        <ion-row class = "row-padding">
          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                CPR Certification Basic Life</label>
              <label class="container">
                <input [(ngModel)]="CPRCertification" (change)="certificationChange('CPRCertification')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                Immunization Record </label>
              <label class="container">
                <input [(ngModel)]="IRCertificate" (change)="certificationChange('IRCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                Current Flu Vaccination</label>
              <label class="container">
                <input [(ngModel)]="CFVCertificate" (change)="certificationChange('CFVCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                TB Testing</label>
              <label class="container">
                <input [(ngModel)]="TBTCertificate" (change)="certificationChange('TBTCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6" [ngClass] = "{'max-width' : selectedSkill == 'catering server'}">
            <div class="certification certificate-left">
              <label class="day-label">
                Vulnerable Sector Check
              </label>
              <label class="container">
                <input [(ngModel)]="VSCCertificate" (change)="certificationChange('VSCCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col *ngIf = "selectedSkill != 'catering server'" class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                N95 Fit Testing within last 2 years
              </label>
              <label class="container">
                <input [(ngModel)]="N95Certificate" (change)="certificationChange('N95Certificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                Additional Certificate </label>
              <label class="container">
                <input [(ngModel)]="otherCertificate" (change)="certificationChange('otherCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                License/ Certification</label>
              <label class="container">
                <input [(ngModel)]="license" (change)="certificationChange('license')" type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="all-blues">
          <p class = "input-label">
            {{'BROADCAST_REQUEST.DIETARY_AIDE' | translate}}
          </p>
        </ion-row>

        <ion-row class = "row-padding">
          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                {{'PROFILE.Food Service Certification' | translate}}</label>
              <label class="container">
                <input [(ngModel)]="FSCertificate" (change)="certificationChange('FSCertificate')" type="checkbox"
                  name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                {{'PROFILE.Food Safe' | translate}}</label>
              <label class="container">
                <input [(ngModel)]="foodSafe" (change)="certificationChange('foodSafe')" type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="all-blues">
          <p class = "input-label">
            {{'BROADCAST_REQUEST.PERSONAL_SUPPORT_WORKERS' | translate}}
          </p>
        </ion-row>

        <ion-row class = "row-padding">
          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-left">
              <label class="day-label">
                {{'PROFILE.PSW or Healthcare Aid Certification/ Diploma' | translate}}</label>
              <label class="container">
                <input [(ngModel)]="HACDCertification" (change)="certificationChange('HACDCertification')"
                  type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>

          <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6"
            size-xs="6">
            <div class="certification certificate-right">
              <label class="day-label">
                OPSWA</label>
              <label class="container">
                <input [(ngModel)]="OPSWACertification" (change)="certificationChange('OPSWACertification')"
                  type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>
        </ion-row>

        <ion-row class="all-blues">
          <p class = "input-label">
            {{'BROADCAST_REQUEST.HOUSEKEEPING_AIDE' | translate}}
          </p>
        </ion-row>

        <ion-row class = "row-padding">
          <ion-col class="content-padding house-keeping" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
            <div class="certification hk-certificate-left">
              <label class="day-label">
                {{"Housekeeping Aide" | translate}}</label>
              <label class="container content-padding" style="margin-bottom: 0 !important">
                <input [(ngModel)]="HKACeertification" (change)="certificationChange('HKACeertification')"
                  type="checkbox" name="check">
                <span class="checkmark"></span>
              </label>
            </div>
          </ion-col>
        </ion-row>
      </div>
      </ion-col>
    </ion-row> -->

    <ion-row *ngIf="showMods == true">
      <ion-col *ngIf="!isHomeCareClient" class="content-padding" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <div class="content-padding-broadcast-team">
          <p class="input-label">
            {{'BROADCAST_REQUEST.Select Event Address' | translate}}
          </p>
          <div class = "static-select-width-end-address">
            <ng-container *ngFor="let address of allAddressesOfCompany; let i = index">
              <div class="address">
                <div class="address-name street-row">
                  <div class = "day-label street-name">{{ address.street }}</div>
                  <div class = "dotted-line"></div>
                </div>
                <label class="container">
                  <input type="radio" name="address-{{index}}" [checked]="address.street == selectedCompanyAddress.street "
                    (change)="handleAddressChange(address)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </ng-container>
            <p *ngIf="companyType !== 'Restaurant' && addressType == 'existing'" class="new-address"
              (click)="changeAddressType('new')"><i class="fa fa-plus"></i>
              {{'BROADCAST_REQUEST.Add New Address' | translate}}
            </p>

            <div class="address-with-save" *ngIf="addressType == 'new'">
              <ion-input class="form-input" type="text" #googlePlaces placeholder="Enter new address"
                (keyup)="addressUpdates()" [(ngModel)]="formattedAddress">
              </ion-input>
              <div class="save-btn" (click)="saveNewAddress()">
                {{'BROADCAST_REQUEST.SAVE' | translate}}
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-row>
</div>