<ion-row class="main-container">
  <ion-col class="checkbox" size="1">
    <app-form-checkbox (onChange)="toggleAllCheckBoxes($event,0)" [options]="checkBoxOptions">
    </app-form-checkbox>
    &nbsp;
  </ion-col>
  <div class="header-container">
    <ion-col class="header" size="3" *ngFor="let item of config">
      {{item}}
    </ion-col>
    <ion-col class="header" size="3" *ngFor="let item of config">
      &nbsp;
    </ion-col>
  </div>
</ion-row>
<ion-row>
  <app-recurring-shift-list-item (onToggleAllCheckBoxes)="toggleAllCheckBoxes($event,i)"
    (onToggleCheckBox)="toggleCheckBox($event,i)" class="row-container" *ngFor="let item of data; let i = index"
    [config]="config" [data]="item">

  </app-recurring-shift-list-item>
</ion-row>