import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';import { IContractorDetail } from 'src/modules/company-dashboard/interfaces/contractorDetail.interface';
import { IHiredContractors } from 'src/modules/company-dashboard/interfaces/hiredStaffers.interface';
import { ImagesService } from 'src/modules/shared/services/images.service';

@Component({
  selector: 'app-contractor-image-card',
  templateUrl: './contractor-image-card.component.html',
  styleUrls: ['./contractor-image-card.component.scss'],
})
export class ContractorImageCardComponent implements OnInit, AfterViewInit {
  @Input() maxSliceCount;
  @Input() jobStatus;
  @Input() showContractorStatus: boolean;
  @Input() contractorInfo: IHiredContractors[] = [];
  left: number;
  showImage: boolean = false;

  constructor(
    private imagesService: ImagesService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(!this.contractorInfo || !this.contractorInfo.length) {
      return;
    }    
    this.contractorInfo.forEach((attendant) => {
      if(!attendant.profileUrl) {
        return;
      }

      const profileUrl = {
        url: attendant.profileUrl
      }

      //Fetch contractors profile picture url
      attendant.profileUrl = this.imagesService.getProfilePic(profileUrl);
    })
  }

  ngAfterViewInit(): void {
    // Set showImage to true after view initialization
    this.showImage = true;
    // Manually trigger change detection
    this.cdr.detectChanges();
  }

  //When mouse hover over staffer image show contractors name 
  mouseOver(id: string) {
    let str = "#" + id;
    var popup = this.el.nativeElement.querySelector(str);
    this.left = popup.offsetWidth/4;
    if(popup) {
      popup.style.marginLeft = -popup.offsetWidth/4 + 'px';
    }
  }

}