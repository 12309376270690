import { Component, Input, OnInit } from '@angular/core';
import { RecurrentShiftService } from '../../services/recurrentShift.service';
import { IClientJob } from 'src/modules/company-dashboard/interfaces/client-job.interface';

@Component({
  selector: 'app-shift-tag',
  templateUrl: './shift-tag.component.html',
  styleUrls: ['./shift-tag.component.scss'],
})
export class ShiftTagComponent implements OnInit {

  @Input() jobItem: IClientJob;
  @Input() onlyRecurrentJobs: boolean;
  shiftInterval: string;
  shiftIntervalDays: string;
  shiftInformation: string;
  showTrainingStyle: boolean;
  
  constructor(
    private recurrentShiftService: RecurrentShiftService
  ) { }

  ngOnInit() {
    
    if(!this.jobItem) {
      return;
    }

    this.showTrainingShiftStyling();

    //If the shift is not recurrent then return by marking it as training or not. Do not calculate interval messade
    if(!this.jobItem || !this.jobItem.recurrentShiftTemplateId) {
      return
    } 

    const recurrentShiftTemplate = this.jobItem.recurrentShiftTemplateId;

    this.shiftInterval = this.recurrentShiftService.formatShiftInterval(recurrentShiftTemplate);
    this.shiftIntervalDays = this.recurrentShiftService.convertDaysToShortFormat(recurrentShiftTemplate.days);
    this.shiftInformation = this.recurrentShiftService.shiftInformation(this.jobItem);
  }


  showTrainingShiftStyling() {
    //Check if non recurring or recurring shift is a training shift
    //If true, apply training shift CSS (Show orange outline), otherwise apply regular CSS
    this.showTrainingStyle =  this.jobItem.isTrainingShift;
  }

}
