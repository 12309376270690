<div class = "basic-information">

  <!-- Shift Skill -->
  <div class = "row full-width">
      <i class = "fa fa-suitcase"></i>
      <div class = "col alignment-start">
        <p *ngIf="jobItem"
            class = "mont-bold large-font text-capitalize bottom-5">
            {{ ('SKILLS.' + jobItem.skill) | translate }}
        </p>

        <div class="row mont-regular small-font">
            <div 
            class = "right-spacing orange" 
            *ngIf="!readOnly && 
            (!doesRecurringShiftHasUnitNumber(jobItem) && !jobItem?.unitNumber) && 
            !jobItem.recurrentShiftTemplateId" 
            (click) = "addUnitNumber(jobItem)">
            Add Unit
          </div>
          <div *ngIf="(doesRecurringShiftHasUnitNumber(jobItem) || jobItem?.unitNumber)" class = "right-spacing">
            <span class = "orange" *ngIf = "jobItem?.jobs?.length > 1" (click)="viewRecurringPropertiesOfShift(jobItem, 'Unit Numbers',true)">
              View Unit
            </span>

            <span class="small-font" *ngIf = "jobItem?.jobs?.length === 1">Unit:
                {{jobItem.unitNumber}}
            </span>
          </div>

          <div class="mont-regular" *ngIf = "patientId && !jobItem?.isMultiSkills">
            Patient Id: <span class = "mont-bold">{{ patientId }}</span>
          </div>
      </div>
      

    </div>
  </div>

  <div class = "col full-width-30">
    <!-- Shift time -->
      <div class = "row alignment-start bottom-5" *ngIf = "jobItem?.jobs?.length === 1">
        <i class = "fas fa-clock"></i>   
        <p class="mont-regular small-font">
              {{jobItem.shiftStartTime | momentPipeUnix:'hh:mma' }} - 
              {{ jobItem.shiftEndTime| momentPipeUnix:'hh:mma' }}
        </p>

        <div class = "row left-20" *ngIf = "readOnly">
          <i class = "fas fa-calendar"></i>   
          <p class="mont-regular small-font">
            {{jobItem.shiftStartTime | momentPipeUnix:'MMM DD' }}
          </p>
        </div>
      </div>

        <div class="row alignment-start bottom-5 orange" *ngIf="jobItem?.jobs?.length > 1" (click) = "viewRecurringPropertiesOfShift(jobItem, 'Timings', false)">
            <i class = "fas fa-clock left-2"></i>
            <p class = "mont-regular small-font">Multiple Schedules</p>
        </div>
     
  
    <!-- Shift rate -->
     <div class = "row alignment-start">
      <i class = "fas fa-money-bill left-2"></i>  
      <p class = "mont-regular small-font">Paying: 
        <span class = "mont-bold small-font">${{jobItem.initialRate || jobItem.rate}}/hour</span>
        </p>
    </div>
  </div>
  
</div>