import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextareaComponent),
      multi: true
    }
  ]
})
export class FormTextareaComponent implements OnInit, ControlValueAccessor  {

  @Input() placeholder: string;
  @Input() labelText: string;
  @Input() rows: number = 7
  @Input() contentEditable: boolean = false;
  @Input() maxLength: number = 100;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  _notes: string;
  propagateChange = (_: any) => {};
  propagateTouched = (_: any) => {};

  constructor( ){}


  ngOnInit() {}

  get notes(): string {
    return this._notes;
  }

  set notes(value: string) {
      this._notes = value.slice(0, this.maxLength)
      this.onChange.emit(this._notes);
      this.propagateChange(this._notes);
  }

  //Form Accessor Functions
  writeValue(value: any): void {
    this._notes = value;
  }
  

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }


}
