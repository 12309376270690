<div class = "details">
        
  <div class = "medium-font mont-bold vertical-spacing">Attendance status</div>
  <div class = "row flex-wrap">
    <div *ngIf="!tracker" class = "row">
      <i class="fa fa-exclamation-circle"></i>
      <p>Not yet clocked in - {{shiftTimingDescription}}</p> <span *ngIf="isShiftLate" (click)="showNotClockedInModal()">Get help</span>
    </div>
  </div>

  <div *ngIf="tracker && tracker.start && !tracker.end"  class = "row flex-wrap">
    <div class = "row">
      <i class="fa fa-bars"></i>
      <p>Working</p>
    </div>
    <div class = "row">
      <i class="fa fa-history"></i>
      <p>Clock-in time: {{clockInTime}} {{isShiftLate ? 'late' : 'On time'}}</p>
    </div>
  </div>

  <div *ngIf="tracker && tracker.end" class = "row flex-wrap">
    <div class = "row">
      <i class="fa fa-check-circle"></i>
      <p>Completed</p>
    </div>
    <div class = "row">
      <i class="fa fa-history"></i>
      <p>Clock-out time: {{clockOutTime}}</p>
    </div>
  </div>
  
  </div>
