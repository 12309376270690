import { Injectable } from "@angular/core";
import * as moment from "moment";
import { IClientJob } from "src/modules/company-dashboard/interfaces/client-job.interface";
import { IRecurrentTemplate } from "src/modules/company-dashboard/interfaces/recurrent-template.interface";
import { IGroupedJobs } from "../interfaces/grouped-jobs.interface";

@Injectable({ providedIn: 'root' })
export class RecurrentShiftService {

constructor() {}

unitNumberByShift(jobItem): string {
    //Return unit number from root level if the shift is non recurring
    if(!jobItem.recurringShift.isTrue) {
      return jobItem.unitNumber || '';
    } else {
      //Identiy job index to update unit number in recurring shifts at front end 
      const index = jobItem.jobs.findIndex((job) => {return job.unitNumber}); 
      if(index > -1) {
        return jobItem.jobs[index].unitNumber;
      }
    }
    return '';
  }

  groupJobsByDate(broadcastedJobs: IClientJob[]): IGroupedJobs {
    // Initialize an empty object to store the grouped jobs
    let groupedJobs = {};
    // Initialize an array to keep track of dates to combine
    // Iterate over each job in the broadcastedJobs array for grouping
    broadcastedJobs.forEach((jobs) => {
      if(!jobs.recurringShift.isTrue) {
        // For non-recurring shifts (single shifts)
        // Get the date key for the shift's start time in the specified time zone
        const dateKey = moment.unix(jobs.shiftStartTime).tz(jobs.timeZone).format('D MMMM YYYY');

        if(!groupedJobs[dateKey]) {
            groupedJobs[dateKey] =  [];
        } 

        // If the key is found, append the job to the corresponding shift array in dates object
        groupedJobs[dateKey].push({  ...jobs});
      
      } else {
          // For recurring shifts
          // Iterate over each shift within the recurring job

          jobs.jobs.forEach((shift,index) => {
            // Get the date key for the shift's start time in the specified time zone
            const dateKey = moment.unix(shift.shiftStartTime).tz(shift.timeZone).format('D MMMM YYYY');

            let jobObject = {
              ...jobs,
              ...shift,
            }

            /**
             * Set the job object according to date value
             */
            if(!groupedJobs[dateKey]) {
              groupedJobs[dateKey] = [];
            }
            
            groupedJobs[dateKey].push(jobObject);
            
          });
      }
  });

  // return grouped jobs and datesToCombine array
  return this.sortKeysByDates(groupedJobs);  
  }


  sortKeysByDates(groupedJobs: IGroupedJobs): IGroupedJobs {
    //Convert the keys to Date objects using Moment.js
    const sortedGroupedJobs : IGroupedJobs = {};
    
    //Sort the keys by dates
    Object.keys(groupedJobs).sort((dateA, dateB) => moment(dateA, 'D MMM YYYY').diff(moment(dateB, 'D MMM YYYY')))
    .forEach((dateKey) => {
      sortedGroupedJobs[dateKey] = groupedJobs[dateKey];
    });
    
    return sortedGroupedJobs;
  }  

  formatShiftInterval(recurrentShiftTemplateId: IRecurrentTemplate): string {
   
    if(recurrentShiftTemplateId.intervalType !== 'Weekly') {
      return 'Daily';  
    }

    const dateRange = 'Every ' + this.addNumberSuffix(recurrentShiftTemplateId.interval) + ' week ';
    return dateRange;
  }


  addNumberSuffix(interval: (string | number)): string {

    //If interval is a string, convert it into number
    if (typeof interval !== 'number' || isNaN(interval)) {
       interval = parseInt(interval.toString());
    }

    
    const suffixes = ['st', 'nd', 'rd', 'th'];
    return interval + suffixes[(interval % 10) - 1];
  }


convertDaysToShortFormat(days: string[]): string {
  
  if(!days || !days.length) {
    return; 
  }
  
  if(days.length === 1) {
    return days[0] + 's';
  }
  const shortDays = days.map(day => moment(day, 'dddd').format('ddd'));
  if (shortDays.length === 2) {
    return shortDays.join(' & ');
  } else if (shortDays.length > 2) {
    const lastDay = shortDays.pop();
    return shortDays.join(', ') + ' & ' + lastDay;
  }

  return '';
}

shiftInformation(jobItem) {

  if(jobItem.recurrentShiftTemplateId.intervalType !== 'Weekly') {
    return (jobItem.length - 1) + ' days before it ends it will automatically renew for another 6 months.';
  }

  if(jobItem.recurrentShiftTemplateId.intervalType === 'Weekly') {
    const frequency =  jobItem.recurrentShiftTemplateId.interval + (jobItem.recurrentShiftTemplateId.interval > 1 ? ' Weeks' : ' Week');
    return frequency + ', ' + (jobItem.length) + ' days before it ends it will automatically renew for another 6 months.' 
  }
}

formatIndividualShiftProperties(broadcastedJobs: IClientJob[]): IClientJob[] {
  return broadcastedJobs.map((job) => {
    // Update each shift in the jobs array while ensuring the type matches IClientJob's job structure
    const updatedShifts = job.jobs.map((shift, index) => ({
      ...shift,
      unitNumber: shift.unitNumber || "",
      isOngoingShift: index === 0 ? job.isOngoingShift : false
    }));

    // Return the job with the updated jobs array
    return {
      ...job,
      jobs: updatedShifts as any,// Ensure this matches the expected type in IClientJob
      recurrentShiftTemplateId: job.recurringShift.groupShift.recurrentShiftTemplateId[0] || null
    };
  })
  }
}
