<!-- if  shifts -->
<ion-grid>
  <ion-row>
    <ion-col>
      <div class="search-by-client">
        <input
          class="client-search-input"
          type="text"
          placeholder="{{ 'HOME_TALENT.Search By Company Name' | translate }}"
          [(ngModel)]="clientName"
          (input)="searchByClient()"/>
          <i class = "fa fa-search" *ngIf = "clientName == ''"></i>
          <i class = "fa fa-times-circle" *ngIf = "clientName !== ''" (click) = "clearSearch()"></i>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>


<div *ngIf="!availableShifts.length" class="no-shifts-container">
  <app-no-context-found [noShiftsData] = "noShifts"></app-no-context-found>
</div>

<div class="sortBy" *ngIf="availableShifts.length > 0">
  <button class="proximity" [ngClass]="{ active: sortByProximity, inactive: !sortByProximity }" (click)="toggleStatus()">
    Sort By {{ sortByProximity ? 'Dates' : 'Proximity' }}
  </button>
</div>

<ion-row class="ion-justify-content-center">
  <ion-col
    size-xl="5"
    size-lg="5"
    size-md="5"
    size-sm="11"
    size-xs="11"
    *ngFor="let jobItem of availableLoadedJobs; let i = index"
    class="shifts-container card ion-no-padding"
  >
    <app-shift-card
      [userData] = "userData"
      [jobItem]="jobItem"
      (jobApplied)="onJobApply($event, i)"
      (jobDeclined)="onJobDecline($event, i, false)"
      (jobUnApplied)="onJobDecline($event, i, true)"
    ></app-shift-card>
  </ion-col>
</ion-row>

<ion-infinite-scroll threshold = "100px" (ionInfinite) = "loadData($event)">
  <ion-infinite-scroll-content
  loading-spinner = "bubbles"
  loading-text = "Loading more data..."></ion-infinite-scroll-content>
</ion-infinite-scroll>
