import { Injectable } from "@angular/core";
import { IJobState } from "../interfaces/job-state.interface";

@Injectable({
    providedIn: 'root'
})

export class FilterMappingService {
    constructor() {
    }

    applyShiftFilters(filters: {[key: string]: boolean}) {

        // Construct query parameters based on the filters
        let filtersToApply = Object.entries(filters)
        .filter(([key]) => filters[key] === false) // Exclude both 'open' and 'filled' filters
        .map(([key]) => `&${encodeURIComponent(key)}=true`) // Append key with false
        .join('');

        return filtersToApply;
    }

    applyCalendarFilters(filters: IJobState) {
        const {
            ongoing,
            recurring,
            regular,
            open,
            partial,
            filled,
            completed
        } = filters;

        // Early return if ongoing is true
        if (ongoing) {
            return '&ongoing=true';
        }

        // Exclude 'ongoing' from the all-true condition check
        const allTrue = [recurring, regular, open, partial, filled, completed].every((value) => value === true);
        if (allTrue) {
            // Skip applying any filters if all are true (excluding 'ongoing')
            return null; 
        }


        let queryParams = '';

        // Recurring and regular filters
        queryParams += this.handleRecurringFilter(recurring);
        queryParams += this.handleRegularFilter(regular);

        // Handle completed filter
        queryParams += this.handleCompletedFilter(completed);

        // Handle the combinations of open, partial, and filled
        if (open) {
            queryParams += this.handleOpenFilters(partial, filled);
        } else {
            queryParams += this.handleFilledFilters(partial, filled);
        }

        return queryParams;
    }

    // Applies recurring filter based on value
    handleRecurringFilter(recurring) {
        return recurring === false ? '&recurring=false' : '';
    }

    // Applies recurring filter based on value
    handleRegularFilter(regular) {
        return regular === false ? '&regular=false' : '';
    }

    // Handles filters when `open` is true
    handleOpenFilters(partial, filled) {
        // No filter needed for this combination
        if (partial && filled) {
            return '';
        }

        //Bring all shifts whose stafferAndShifts.length = 0 or they are fully filled 
        if (!partial && filled) {
            return '&partial=-1'; // Unfilled shifts only
        }

        // Only partially unfilled shifts
        if (partial && !filled) {
            return '&isFilled=false'; 
        }

        // Only Open shifts: Unfilled and no staffer shifts
        if (!partial && !filled) {
            return '&isFilled=false&partial=0'; 
        }

        return '';
    }

    handleFilledFilters(partial, filled) {
        // Partially unfilled shifts
        if (partial && !filled) {
            return '&isFilled=false&partial=1'; 
        }

        // Partially filled and filled shifts, atleast 1 staffer is hired
        if (partial && filled) {
            return '&partial=1'; 
        }

        // Fully filled shifts only
        if (!partial && filled) {
            return '&isFilled=true'; 
        }

        return '';
    }

    // Applies completed filter based on value
    handleCompletedFilter(completed) {
        return completed === false ? '&completed=false' : '';
    }
}