import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { JobService } from 'src/modules/shared/services/job.service';
import { PopupService } from 'src/modules/shared/services/popup.service';
import { PreviousHireAddToTeamComponent } from 'src/modules/company-dashboard/components/previous-hire-add-to-team/previous-hire-add-to-team.component';
import { IContractorHistory } from 'src/modules/company-dashboard/interfaces/contractorHistory.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';


@Component({
  selector: 'app-shift-history-detail',
  templateUrl: './shift-history-detail.component.html',
  styleUrls: ['./shift-history-detail.component.scss'],
})
export class ShiftHistoryDetailComponent implements OnInit {

  @Input() userData: IUser;
  @Input() contractorPastShifts: Array<IContractorHistory>;
  @Input() history: boolean;
  @Input() staffer;
  @Input() shiftStatus: string = 'completed';
  teams = [];
  allClientUnits = [];
  constructor(
    private popupService: PopupService,
    private modalController: ModalController,
    private jobService: JobService,
    
     ) { }

  ngOnInit() {

    this.contractorPastShifts = this.contractorPastShifts.map(item=>{
      return {
        ...item,
        jobs: [item]
      }
    })
    
  }

  dismiss() {
    this.modalController.dismiss();
  }


  async addToTeam(stafferId: string) {

    try {
    //Load team data when add a team is clicked
      this.teams = await this.jobService.getTeams(this.userData.entityId._id);
      
      //No team found
      if(!this.teams || this.teams.length === 0) {
        this.showPopup('Sorry!', 'No team found', 'assets/images/sorry1.png');
        return;
      }
    
    //Show a list of all teams of the client in a popup
    const myModal = await this.modalController.create({
      component: PreviousHireAddToTeamComponent,
      cssClass: 'forgotPassModal',
      componentProps: {
        teams: this.teams
      }
    });

    await myModal.present();
    myModal.onDidDismiss()
      .then(async (dataReturned) => {
        const team = dataReturned.data;

        if(!team) {
          return;
        }

        //Check if contarctor is added in the team, if not update the team with contractor id 
        if (!this.jobService.hasStafferExistInTheTeam(team, stafferId)) {
          //Push staffer id into team array containing othher staffers id
          const teamStaffers = team.staffers.map((staffer) => {
            return staffer._id;
          });
          teamStaffers.push(stafferId);

          try {
            await this.jobService.updateTeam(team._id, {
              teamStaffers
            });

            this.showPopup('Success', 'Team updated Successfully', 'assets/images/thumbs-up.png');
            
          } catch(error) {
            this.showPopup('Sorry!', 'Unable to add staffer right now', 'assets/images/sorry1.png');    
          }

        } else {
          //Show error if contractor is already added in the selected team
          this.showPopup('Sorry!', 'Contractor already exist in the team', 'assets/images/sorry1.png');
        }
      
      })

    } catch(error) {
      console.log('error while adding contractor ', this.teams, stafferId);
      this.showPopup('Sorry!', 'Unable to add staffer right now', 'assets/images/sorry1.png');
    }
  }

  showPopup(heading: string, message: string, imgUrl: string) {
    this.popupService.showModal(
      {
        heading: heading,
        message: message,
        btn: 'Dismiss',
        navigateRoute: null,
        imgURL: imgUrl  
      });
  }

  hireAgain() {
    this.modalController.dismiss('hireStaffer');
  }

}
