import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment-timezone';
import 'moment-duration-format';

@Pipe({
    name: 'formatTimeTracked'
})
export class FormatTimeTracked implements PipeTransform {
    transform(value: string): any {
        if (!value) {
            return;
        }
        return moment.duration(value).format('h:mm', { trim: false });

    }
}
