import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-',
    templateUrl: './alert-update-unit-number.component.html',
    styleUrls: ['./alert-update-unit-number.component.scss'],
})
export class AlertUpdateUnitNumberComponent implements OnInit {

    constructor(private modalController: ModalController) { }



    ngOnInit() {

    }

    handleNoBtn() {
        this.modalController.dismiss(false);
    }

    handleYesBtn() {
        this.modalController.dismiss(true);
    }

}
