<div class = "reusable-form-component">
  <ion-label class = "label">{{ labelText }}</ion-label>
  <ion-input  
    [(ngModel)] = "value" 
    [type] = "inputType" 
    [placeholder] = "placeholder" 
    (ionBlur)="touched($event)"
    >
  </ion-input>

  <app-form-errors [control] = "control"></app-form-errors>
  </div>
