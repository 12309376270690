import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImagesService } from '../services/images.service';

@Component({
  selector: 'app-upload-profile-picture',
  templateUrl: './upload-profile-picture.component.html',
  styleUrls: ['./upload-profile-picture.component.scss'],
})
export class UploadProfilePictureComponent implements OnInit {
  @Input() profilePicture;
  formattedImage: string;

  constructor(private imageService: ImagesService,
    private router: Router
    ) { }

  ngOnInit() {
    console.log('show changes init');
  }

  ngOnChanges() {
    //format profile picture according to height and width of circle
    if(this.profilePicture) {
        this.formattedImage = this.formatProfilePic(this.profilePicture);
      }
  }

  formatProfilePic(profileUrl): string {
    //if profile picture not found, return
    if(!profileUrl) {
        return;
    }

    //Reduct the width and height of image to fit in container
    return this.imageService.getProfilePic(profileUrl, 'w_250,h_200');
    
  }

  changeImage() {
    this.router.navigateByUrl(`talent-dashboard/upload-file/${'profilePic'}`);
  }

}
