
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'formatTotalEarnings'
})
export class FormatTotalEarnings implements PipeTransform {
    transform(val: string): any {
        if (!val) {
            return val;
        }
        let totalEarned = parseFloat(val).toLocaleString();
        if (totalEarned.substr(totalEarned.indexOf('.')).length === 1) {
            totalEarned += '.00';
        }

        if (totalEarned.substr(totalEarned.indexOf('.')).length === 2) {
            totalEarned += '0';
        }

        return totalEarned;
    }
}
