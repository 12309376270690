<div class = "outer center-content">
  <div class = "image-section">
    <img class="profile-pic" src="{{profilePicture}}">
  </div>

  <div class = "wrapper center-content">
    <div class = "basicInfo center-content">
      <p class = "name">{{jobItem.entity.name}}</p>
      <p class = "skill">{{ ('SKILLS.' + jobItem.skill) | translate }}</p>
    </div>

    <span class = "general-book sub-skill" *ngIf="jobItem.subSkills && jobItem.subSkills.length">
      <span class = "mont-bold-font display-content">Subskill Required: </span>{{ jobItem.subSkills.join(", ") }}
    </span>

    <div class = "notes" *ngIf = "(jobItem.shiftNotes || jobItem.entity?.siteNotes || jobItem.entity?.bulletins.length) ">
      <div class = "text flex-grow" *ngIf = "jobItem.shiftNotes" (click) = "viewNotes('shift')">
        <i class="fas fa-sticky-note"></i>Shift Notes
      </div>

      <div class = "text siteNotes flex-grow" *ngIf = "jobItem.siteNotes || jobItem.entity.siteNotes" (click) = "viewNotes('site')">
        <i class="fas fa-home"></i>Site Notes
      </div>

      <div class = "flex-grow" *ngIf = "jobItem?.entity.bulletins?.length">
        <div class = "text" *ngIf=" jobItem?.entity.bulletins?.length" (click) = "showBulletins(jobItem.entity.bulletins,jobItem.entity._id)">
          <i class="fas fa-newspaper"></i>Shift Bulletins
        </div>
    </div>
  </div>
    

    <div class = "detail-section center-content">
      <p class = "general-book">Your shift starts:</p>
      <p class = "bold-orange">{{ humanizeShiftTime }} </p>
      <div class = "general-regular lh" >
        <span><i class = "fas fa-calendar"></i>
          <span class = "general-book-bold">On </span>
          <span *ngIf="jobItem.recurringShift.isTrue">{{ jobItem.dateRange }}</span>
          <span *ngIf="jobItem.recurringShift.isTrue == false">{{ jobItem.shiftStartTimeFormatted }}</span>
        </span>
      </div>

      <!-- ngIf: similarTimings -->
      <div class = "general-book" *ngIf="jobItem.similarTimings">
        <i class = "fas fa-clock"></i><span class = "general-book-bold">From: </span>{{ jobItem.startTime }} - {{ jobItem.endTime }}
      </div>

      <div class = "general-book" *ngIf="jobItem.unitNumber">
        <img class = "unitNumber" src = "assets/images/square-orange.png"><span class = "general-book-bold">Unit number: </span>{{ jobItem.unitNumber }}
      </div>

      <div *ngIf="!jobItem.similarTimings" (click)="viewShiftTimings(jobItem)" class = "general-book">
        <i class = "fas fa-eye"></i>{{ 'HOME_TALENT.View Shift Timings' | translate }}
      </div>

      <!-- todo: fix the condition for similarUnitNumbers -->
      <!-- <div class = "unit-list" *ngIf="(!jobItem.recurringShift.isTrue || jobItem.similarUnitNumbers) && jobItem.unitNumber">
          <span class = "general-book"><img class = "unitNumber" src = "assets/images/square-u.png"></span>&nbsp;<span class = "general-book-bold">Unit Number: </span><span class = "general-book">{{ jobItem.unitNumber}}</span>
      </div> -->

      <div class = "general-book-bold lh">
        <span><i class = "fas far fa-money-bill-alt	"></i>Earning ${{ potentialEarning }} @ ${{ jobItem.stafferRate ? jobItem.stafferRate : jobItem.rate}}/hr
        </span>
      </div>
        
      <div *ngIf = "jobItem.travelTime">
        <span class = "general-book" ><i class="fas fa-solid fa-bus"></i>Transit allowance: </span><span class = "general-book-bold">&nbsp;{{ jobItem.travelTime}} hr</span>
      </div>

      <div *ngIf = "jobItem?.break">
        <span class = "general-book"><i class="fas fa-money-check"></i></span>&nbsp;<span class = "general-book-bold">{{ jobItem?.break?.unpaid ? jobItem.break.unpaid: 'No'}} </span><span class = "general-book">unpaid break</span>
      </div>

      <div *ngIf = "jobItem.isTipIncluded">
        <span class = "general-book"><i class="fas fa-solid fa-folder-plus"></i>Includes Tip </span>
      </div>

      <div *ngIf = "jobItem.isStatPay && jobItem.isStatPay == true">
        <span class = "general-book"><i class="fas fa-calendar"></i>Stat Holiday Pay</span>
      </div>

      <div *ngIf="jobItem.isCertificateRequired">
        <div class = "icon-td">
          <i class="fa fa-bullhorn" aria-hidden="true"></i>
        </div>
        <div class="text-td" *ngIf = "jobItem.certificateRequirement.length > 0">
          {{ jobItem.certificateRequirement[0].issuerCompany}} {{ jobItem.certificateRequirement[0].referenceNumber}} {{ jobItem.certificateRequirement[0].type}}
        </div>
      </div>

      <div class = "general-book" *ngIf="(!jobItem.entity.creator.customer.status && jobItem.entity.creator.customer.hasCreditCard) ||
            jobItem.entity.creator.force24HourPayAvailableBadge ||
            jobItem.is24HoursBadge">
            <i class="fas fa-calendar"></i>
            {{ 'HOME_TALENT.StaffyPay available' | translate }}
      </div>
      
      <div class = "address" *ngIf = "!jobItem?.isMultiAddressShift">
        <span class = "general-regular">
        <i class = "fas fa-map-marker"></i><span class = "general-book-bold">At </span>{{jobItem.companyAddress?.street}} - {{ distanceFromShift}} km away</span>
      </div>

      <app-shift-multi-address-list
      *ngIf = "jobItem?.isMultiAddressShift"
      [shiftAddressList] = "shiftAddressList">
      ></app-shift-multi-address-list>

      <div *ngIf = "jobItem.entity.isCovidAffected == true || jobItem.entity.isCovidAffected == false">
        <span class = "general-book" ><i class="fas fa-asterisk"></i>Covid affected: </span>
        <span *ngIf = "jobItem.entity.isCovidAffected == true" class = "general-book-bold">&nbsp;Positive</span>
        <span *ngIf = "jobItem.entity.isCovidAffected == 
        false" class = "general-book-bold">&nbsp;Negative</span>
      </div>  

      <div class = "notes margin-top-zero">
        <div class = "unit-list" *ngIf = "jobItem.recurringShift.isTrue">
          <div *ngIf="jobItem.recurringShift.isTrue && !jobItem.similarUnitNumbers" class = "text"  (click)="viewRecurringPropertiesOfShift(jobItem, 'Unit Numbers',true)">View Unit Number
          </div>
        </div>
      </div>

    <div class="btn-section">
      <app-training-shift [jobItem]="jobItem"></app-training-shift>
      <ion-button
        *ngIf="jobItem.canApply && !jobItem.isApplied"
        color="basic"
        appDebounceClick
        [debounceTime]="700"
        (debounceClick)="respondToJob(jobItem)"
        class = "login-btn"
      >{{ 'HOME_TALENT.APPLY' | translate }}
        <div class = "ellipse"><i class = "fas fa-arrow-right"></i></div>
      </ion-button>

      <ion-button *ngIf="jobItem.isApplied" class = "dismiss" type="submit"  (click)="unApplyFromJob(jobItem)">
        <div class = "login-txt">Unapply</div>
      </ion-button>

      <ion-button class = "dismiss" type="submit" (click)="declineJob(jobItem)">
        <div class = "login-txt">Decline</div>
      </ion-button>
    </div>    
  </div>
</div>
