<div class = "img-container" *ngIf = "contractorInfo?.length && jobStatus !== 'open'">
  <div class = "image-and-status" *ngFor = "let attendant of contractorInfo | slice: 0:maxSliceCount; let i = index">
    <div class = "img-inner" *ngIf = "i < maxSliceCount">
      <button class = "image-circle" (mouseover) = "mouseOver(attendant.firstName)">
        <img  *ngIf = "attendant?.profileUrl && showImage" [src] = "attendant.profileUrl"/>
        <div class = "tooltip-name" [attr.id] = "attendant.firstName" #attendant.firstName>{{attendant.firstName}} {{attendant.lastName}}</div>
      </button>
    </div>
    <div class = "ongoing-badge" *ngIf = "jobStatus === 'ongoing' && showContractorStatus">
      <div *ngIf = "attendant?.clockInStatus === 'clocked-in'" class = "clocked-in">
      </div>

      <div *ngIf = "!attendant.clockInStatus" class = "not-clocked-in">
        <i class="fas fa-exclamation"></i>
      </div>

      <div *ngIf = "attendant?.clockInStatus == 'clocked-out'" class = "not-clocked-in">
        <i class="fa fa-check"></i>
      </div>
    </div>
  </div>
  <div class = "dotted-circle darkFont" *ngIf = "jobStatus === 'open' || jobStatus === 'partial'"></div>
  <span *ngIf = "contractorInfo.length >=maxSliceCount" class = "remaining-count">+{{contractorInfo.length - 7}}</span>
</div>

<div class = "dotted-circle darkFont" *ngIf = "jobStatus === 'open'"></div>
  