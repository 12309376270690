import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {

    @Input() rows;
    @Input() columns;
    @Input() unknownColumns;
   

  constructor(private modalController: ModalController) { 
  
  }

  ngOnInit() { 
  }


  getCellClass= ({ row, column, value})=>{ 
    return{
      'data-table-cell' : true, 
    }
  }

  getRowClass() {
    return {
      'data-table-row': true
    };
  }

  getHeaderClass= ({ column})=>{ 
    return{
      'data-table-header' : true, 
      'data-table-error-cell': this.unknownColumns.map(item=>item.toLowerCase()).includes(column.name.toLowerCase())
    }
  }
  



}
