import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IEntity } from 'src/modules/talent-dashboard/interfaces/entity.interface';
import { logOutAction } from 'src/modules/authentication/+store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { NewThemeViewsService } from 'src/modules/talent-dashboard/services/new-theme-views.service';
import { IAuthState } from 'src/modules/authentication/+store/auth.state';
import { filter, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss'],
})
export class ClientHeaderComponent implements OnInit {

  @Input() entity: IEntity;
  @Input() currentView: string;
  paramValue: (boolean | string);

  currentHeaderProperties : {
    heading: string,
    button: string,
    navigateTo: '',
    icon: '',
    toggleBar: any
  } = {
    heading: '',
    button: null,
    navigateTo: null,
    icon: '',
    toggleBar: null
  };
  showMenu: boolean = false;
  routerSubscription$?: Subscription;
  extendedParams: string;
  requestViewChange: boolean =  false;
  recurrentView: boolean = false;
  allView: boolean = false;
  userType: string = 'client';
  constructor(
    private router: Router,
    private store: Store<IAuthState>,
    private newThemeViewsService: NewThemeViewsService,
    private configService: ConfigService
  ) { }


  ngOnInit() {
    this.userType = 'client';

    this.routerSubscription$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      startWith({url: this.router.url}),
    ).subscribe((event: NavigationEnd) => { 
      const currentView = event.url.split('/')[2];
      if(this.extendedParams !==  (event.url.split('/')[3] ? event.url.split('/')[3] : null)){
        this.requestViewChange = true;
      } else {
        this.requestViewChange = false;
      }

      if(event.url.includes("true")){
        this.recurrentView = true
      }else{
        this.recurrentView = false
      }
      
      if(event.url.includes("all")) {
        this.allView = true;
      } else  {
        this.allView = false;
      }
      this.extendedParams = event.url.split('/')[3] ? event.url.split('/')[3] : null;
      this.currentHeaderProperties = this.newThemeViewsService.newHeaderProperties(currentView, this.extendedParams);
      this.paramValue = '';
    });
  }

  toggleMenu() {
    this.showMenu = !this.showMenu
  }

  navigateTo(url: string) {
      this.router.navigateByUrl(url);
  }

  async logout() {
    this.configService.revertToDefault();
    this.store.dispatch(logOutAction());
  }


  onShiftChange(isRecurrent: (boolean | string)) {
    //Recurrent Shift : true
    //Regular Shift: false
    this.paramValue = isRecurrent;
    this.router.navigate([`/company-dashboard/shift-requests/${this.extendedParams}/${isRecurrent}`])    
  }
}
