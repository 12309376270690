<ion-row class="p0 box-shadow">
  <ion-col class="content-padding" [size] = "">
    <div class = "jobPosition">
      <p class="input-label">
        Patient ID
        <span (click)="showNewPatientForm()" class="add-link"><i [ngClass] = "[icon]"></i> Add New</span>
      </p>
      <div class = "static-select-width">
        <select class="position select-font" [(ngModel)]="_patientId" (change)="handlePatientIdChange($event.target.value)">
          <option selected [value]="null" disabled>Select/Add patient ID</option>
          <option class = "dark-font" [value]="availablePatient.id" *ngFor="let availablePatient of availablePatients">
            {{availablePatient.id}}
          </option>
        </select> 
      </div>
    </div>
  </ion-col>
  <ion-col class="content-padding" [size] = "colSize">
    <div class = "jobPosition code">
      <p class="input-label">
        Postal code
      </p>
      <div>
        <app-places-autocomplete [formatedAddress]="patientAddress?.street" class="style-broadcast" [ngClass]="{'form' : colSize === 12}" (addressChanged)="handleAddressChange($event)"></app-places-autocomplete>
      </div>
    </div>
  </ion-col>
</ion-row>