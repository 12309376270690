<div class = "event-detail" *ngIf = "extendedProps" [ngClass] = "formatCSSClass()">
  <div class="tooltip">
    <span *ngIf = "direction" [ngClass] = "[direction]"></span>
  </div>

  <div class = "job-detail">
    <i class = "fa fa-times" (click) = "closePopup()"></i>
    <div class = "recurrent-icon" *ngIf = "extendedProps?.recurringShift?.groupShift?.recurrentShiftTemplateId">
      <img src = "assets/images/repeat.png">
    </div>
  
    
    <div class = "status">
      <span *ngIf = "extendedProps['jobStatus']" class = "status-heading">
        <i [ngClass] = "[displayEventIcon(extendedProps['jobStatus'])]"></i>{{ formatJobStatus(extendedProps['jobStatus']) }}
      </span>
    </div>

    <div class = "skill">{{ extendedProps?.skill }}<span class = "card-font">x{{extendedProps?.isABroadcastRequest?.vacancies || 1}}</span></div>
    
    <div class = "staffer-list" *ngIf = "extendedProps['jobStatus'] != 'open'" [innerHTML]= "renderStafferList()"></div>

    <div class = "slider image-and-navigate">
      <div class = "image-slider" >
        <app-contractor-image-card
        
        [showContractorStatus] = "true" 
        [contractorInfo] = "extendedProps['hiredStaffers']" 
        [maxSliceCount] = "maxSliceCount"
        [jobStatus] = "extendedProps['jobStatus']"
        ></app-contractor-image-card>   
      </div>
    </div>

    <ion-grid class = "event-grid">
      <ion-row>
        <ion-col size-xl="1" size-lg="1" size-md="1" size-sm="1" size-xs="1">
          <i class="fa fa-map-marker"></i>
        </ion-col>
        <ion-col class = "card-font">
          <span class = "mont-regular xs-font padding-left address regularItalic">
            {{ extendedProps?.companyAddress?.street }}
          </span>
        </ion-col>
      </ion-row>

      <!-- Hiding dates from events due to optimization -->

      <ion-row *ngIf = "!extendedProps?.recurringShift?.isTrue || extendedProps?.formatted">
        <ion-col size-xl="1" size-lg="1" size-md="1" size-sm="1" size-xs="1">
          <i class="fa fa-calendar"></i>
        </ion-col>

        <ion-col class = "card-font">
          <span class = "mont-regular xs-font padding-left regularItalic line-spacing" *ngIf = "!extendedProps?.recurringShift?.isTrue">
            {{extendedProps?.shiftStartTime  | momentPipeUnix:'MMM DD, YYYY'}}</span>
            <span class = "mont-regular xs-font padding-left regularItalic line-spacing" *ngIf = "extendedProps?.formatted">
              {{extendedProps.formatted}}</span>
        </ion-col>
      </ion-row> 

      <ion-row>
        
        <ion-col size-xl="1" size-lg="1" size-md="1" size-sm="1" size-xs="1">
          <i class="fa fa-clock"></i>
        </ion-col>
        <ion-col class = "card-font">
          <span class = "mont-regular xs-font padding-left regularItalic">
            {{extendedProps?.shiftStartTime | momentPipeUnix:'hh:mm a'}} - {{ extendedProps?.shiftEndTime | momentPipeUnix:'hh:mm a'}}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div class = "see-details">
      <p [innerHTML] = "viewDetailsButton()" 
      (click) = "navigateToRequestView(extendedProps['jobStatus'])" 
      class = "see-details-text"
      [ngClass] = "{'disabled': extendedProps['jobStatus'] === 'open' && extendedProps['isPreviousShift']}"
      ></p>
    </div>
  </div>
</div>