<div *ngIf = "!userData || userData?.type == 'staffer'" class = "staffer">
<div class="ion-text-center child" [ngClass] = "{'quizDetail' : specificClass == 'quizModal', 'detail' : specificClass != 'quizModal'}">
  <div class = "img-container">
  <img *ngIf="imgURL" src="{{imgURL}}">
  </div>
  
  <div class = "heading" >
    <h1 [ngClass] = "{'isMOTD' : isMOTD}">
      {{heading}}
    </h1>
    <p *ngIf = "message" class="ion-text-center ov-auto para" [ngClass] = "{'isMOTDTxt' : isMOTD, 'isHtml' : message | isHtml}" [innerHTML]="message | replaceLineBreaks"></p>
    
    <ul *ngIf="list?.length" class="listings">
      <li *ngFor="let item of list">
        {{item}}
      </li>
    </ul>
  </div>

  <div class="buttons-section" [ngClass] = "{'hrsRequest' : (heading == 'Hour adjustment request')}">
    <ion-button *ngIf="rejectBtnText" id="success-popup-btn" class =  "half-width ion-no-margin btn success-popup-ok remove-bg"
    (click)='submit("reject")'> <span>{{rejectBtnText}}</span>
    </ion-button>
  
    <ion-button [ngClass]="{'half-width':rejectBtnText}" color="basic" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
      (click)='submit("accept")'>
      {{btnText}}
    </ion-button>
  </div>
</div>
</div>

<div class = "employer" *ngIf = "userData?.type == 'employer'">
  <div *ngIf="showDismissButton" class =  "dismissPopup">  
    <i class = "fa fa-times" (click) = "dismissModal()"></i>
  </div>

  <div class="ion-justify-content-center ion-text-center child">
    <div class="content-section">
      <img *ngIf="imgURL" src="{{imgURL}}">
      <i *ngIf="iconClass" class = "{{iconClass}}"></i>
      <h1 class="">
        {{heading}}
      </h1>
      <p class="ion-text-center ov-auto"  [innerHTML]="message | replaceLineBreaks">
        
      </p>
    </div>
    <ul *ngIf="list?.length" class="listings">
      <li *ngFor="let item of list">
        {{item}}
      </li>
    </ul>
    <div class="buttons-section" [ngClass] = "{'hrsRequest' : (heading == 'Hour adjustment request')}">
      <ion-button *ngIf="rejectBtnText" expand="full" color="ion-color-basic" id="success-popup-btn" class=" half-width ion-no-margin btn success-popup-ok bg-basic"
      (click)='submit("reject")'>
      {{rejectBtnText}}
    </ion-button>
    <ion-button   [ngClass]="{'half-width':rejectBtnText}" expand="full"  color="dark" id="success-popup-btn" class=" ion-no-margin btn success-popup-ok"
      (click)='submit("accept")'>
      {{btnText}}
    </ion-button>
    </div>
   
  </div>
</div>