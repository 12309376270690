import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss'],
})
export class PlacesAutocompleteComponent implements OnInit {

  @Input() placeholder: string = 'Address';
  @Input() class;
  @Input() formatedAddress: string;

  @Output() addressChanged = new EventEmitter<google.maps.places.PlaceResult>();
  @ViewChild('googlePlaces', { static: false }) googlePlaceInput: IonInput;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.addressUpdates();
  }



  async addressUpdates() {
    // Google Places API auto complete
    const input = await this.googlePlaceInput.getInputElement();
    const autocomplete = new google.maps.places.Autocomplete(input, { types: [] });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // retrieve the place object for your use
      const place = autocomplete.getPlace();
      //console.log('place ', place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
      this.formatedAddress = place.formatted_address;
      
      this.addressChanged.emit(place);
    });
  }
}
