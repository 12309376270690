<ion-grid class="ion-no-padding ">
    <ion-row class="ion-justify-content-center ion-text-center main-wrapper" *ngIf = "!isStaffer">
        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="10" size-xs="10">
            <div class="heading-wrapper">
                <h1 class="ion-text-center title"> {{ 'ONBOARING_ADDRESS_CONFIRMATION.title' | translate }}</h1>
                <p class="ion-text-center tag-line"> {{ 'ONBOARING_ADDRESS_CONFIRMATION.tag-line' | translate }}</p>

            </div>

            <div class="address-warpper">
                
                <ion-item [hidden]="!showAddressTextBox" lines="none" style="margin-top:10px;">
                    <h2 class="place-name" style="margin-left:10px; margin-right:20px;">Address:</h2>
                </ion-item>

                <ion-item (click)="editAddress()" [hidden]="showAddressTextBox" lines="none" >
                    <span> <i class="fas fa-edit icon-place-name"></i> </span>
                    <h2 class="place-name" style="margin-left:10px; margin-right:20px;">{{address?.street}}</h2>
                </ion-item>
                
                <ion-item [hidden]="!showAddressTextBox" lines="none" class="ion-justify-content-center" style="border:1px solid #ff4d00; padding:5px;">
                    <ion-textarea class="place-name" #googlePlaces [(ngModel)]="address.street" style="margin-left:10px;" rows="2"></ion-textarea>
                </ion-item>

                <ion-item [hidden]="!showPostalCodeTextBox" lines="none" class="ion-justify-content-center" *ngIf= "isStaffer" style="margin-top:10px;">
                    <h2 class="place-name" style="margin-left:10px; margin-right:20px;">Postal Code:</h2>
                </ion-item>

                <ion-item (click)="editAddress()" [hidden]="showPostalCodeTextBox" lines="none" class="ion-justify-content-center" *ngIf= "isStaffer">
                    <span> <i class="fas fa-edit icon-place-name"></i> </span>
                    <h2 class="place-name" style="margin-left:10px; margin-right:20px;">{{address.code}}</h2>
                </ion-item>
                
                <ion-item [hidden]="!showPostalCodeTextBox" lines="none" class="ion-justify-content-center" style="border:1px solid #ff4d00; padding:5px;" *ngIf= "isStaffer">
                    <ion-input class=" place-name" placeholder = "Postal Code" [(ngModel)]="address.code"></ion-input>
                </ion-item>

            </div>
        </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-text-center main-wrapper">
        <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="12" size-xs="12">
            <div #map id="map" class = "map" [ngClass] = "{'stafferMap' : isStaffer}"></div>
            <ion-button color="basic" expand="full" *ngIf = "!isStaffer" (click)="nextStepEmployer()" class="submit-btns address-submit">
                {{ 'SHARED_BTN_TEXT.next' | translate}} <i class="fas fa-chevron-right icon-right"></i>
            </ion-button>
        </ion-col>

    </ion-row>
</ion-grid>
