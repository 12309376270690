<ion-row class="p0">
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <p class="input-label">
        Patient Information
        <span (click)="showNewPatientForm()" class="add-link"><i class="fa fa-plus"></i> Add New</span>
      </p>
    </div>
  </ion-col>
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class = "jobPosition">
      <p class="input-label">
        Address Information
      </p>
    </div>
  </ion-col>
</ion-row>

<div class="patient-selection-container" *ngFor="let patient of patientsData;let i = index;trackBy: trackByIndex">
  <app-shift-patient [entityId]="entityId" [patient]="patient" [availablePatients]="availablePatients"
    (onPatientChange)="handlePatientChange($event,i)"></app-shift-patient>
  <span *ngIf="i!==0" (click)="removePatient(i)" class="remove-item"><i class="fa fa-trash"></i></span>
</div>


<ion-row class="p0">
  <ion-col class="content-padding" size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="6">
    <div class="jobPosition">

      <span (click)="addNewPatient()" [ngClass]="{'disabled-add-more': disabledAddMorePatient}" class="add-item"><i class="fa fa-plus"></i> ADD ANOTHER</span>

    </div>
  </ion-col>
</ion-row>
