<div  class = "training-required-button" [ngClass] = "{'recurrent-tag': jobItem.recurrentShiftTemplateId, 'trainingShift': showTrainingStyle}">
  
    <div class="creator-name">
        
        <!-- For Open Request non recurring, where job is received as a single array -->
        <div class = "type-and-date" *ngIf="(!jobItem.recurrentShiftTemplateId && !jobItem?.recurringShift?.isTrue)">
            <div [ngClass] = "{'trainingShift-inner': showTrainingStyle}">
                <div class = "training-icon" *ngIf = "showTrainingStyle">
                    <img src="assets/images/book.png">
                </div>

                <div class = "flex">
                    <div *ngIf = "showTrainingStyle" class = "training-content"><span>Training Shift</span></div>
                    <div>
                        <h1 *ngIf = "jobItem?.isABroadcastRequest?.isTrue">
                            {{'OPEN_REQUESTS.Shift Request'| translate}}
                        </h1>
                        <h1 *ngIf="!jobItem?.isABroadcastRequest?.isTrue ">
                            {{'OPEN_REQUESTS.Direct hire'| translate}}
                        </h1>
                    </div>
                </div>
               
            </div>
            

          <div>
              <p *ngIf = "jobItem?.shiftStartTime" class="open-shift-text text-capitalize">
                  {{jobItem.shiftStartTime | momentPipeUnix:'MMM DD'}}
              </p>
          </div>
        </div>


      <!-- For open request recurring/recurrent shifts, where jobs are received as more than 1 array -->
        <div class = "type-and-date" *ngIf = "jobItem?.jobs.length >= 1 && jobItem?.recurringShift?.isTrue">
            <div [ngClass] = "{'trainingShift-inner': showTrainingStyle}">
                <div class = "training-icon" *ngIf = "showTrainingStyle">
                    <img src="assets/images/book.png">
                </div>

                <div class = "flex" *ngIf = "!jobItem.recurrentShiftTemplateId">
                    <div *ngIf = "showTrainingStyle" class = "training-content"><span>Training Shift</span></div>
                    <div>
                        <h1 *ngIf="jobItem?.isABroadcastRequest.isTrue">
                            {{'OPEN_REQUESTS.Shift Request'| translate}}</h1>
                        <h1 *ngIf="!jobItem?.isABroadcastRequest.isTrue">
                            {{'OPEN_REQUESTS.Direct hire'| translate}}
                        </h1>
                    </div>
                </div>

                <div class = "flex-column recurrent" *ngIf = "jobItem.recurrentShiftTemplateId">
                    <div class = "first-col">
                        <i class="fas fa-repeat"></i>
                        <div class = "training-content light"><span>Recurring shift</span></div>
                    </div>

                    <div class = "second-col">
                        <div class = "days">
                            {{ shiftInterval }} on {{ shiftIntervalDays }}
                        </div>
                        <div class = "date-range">{{ jobItem?.recurrentShiftTemplateId.startDate | momentPipeUnix:'MMM DD' }} - {{ jobItem.jobs[jobItem.jobs.length - 1].shiftStartTime | momentPipeUnix:'MMM DD' }}
                        </div>
                    </div>

                    <div class = "third-col">
                        <div class = "tooltip">
                            <i class="fa fa-info-circle " aria-hidden="true"></i>
                            <span class="tooltiptext"><span class = "bold">This is a recurring shift</span> that will be available for {{ shiftInformation }}</span>
                        </div>
                    </div>
                </div>
            </div>
              
            <div *ngIf = "!jobItem?.recurrentShiftTemplateId">
                <p class="open-shift-text text-capitalize">
                    {{jobItem.shiftStartTime | momentPipeUnix:'MMM DD'}} - {{jobItem.jobs[jobItem.jobs.length - 1].shiftStartTime | momentPipeUnix:'MMM DD'}}
                </p>
            </div>
  
        </div>
    </div>
  
  </div>
