import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUserAddress } from 'src/modules/authentication/interfaces/user-address.interface';
import { HomecarePatient } from '../../interfaces/homecare-patient.interface';
import { NewPatientFormComponent } from '../new-patient-form/new-patient-form.component';

@Component({
  selector: 'app-multiple-patient-shift-selection',
  templateUrl: './multiple-patient-shift-selection.component.html',
  styleUrls: ['./multiple-patient-shift-selection.component.scss'],
})
export class MultiplePatientShiftSelectionComponent implements OnInit {

  @Input() entityId: string;
  @Input() availablePatients: HomecarePatient[] = [];
  @Output() onPatientChange: EventEmitter<Array<HomecarePatient>> = new EventEmitter();
  patientObject  = {
    id:'',
    address: {
      street:'',
      city:'',
      code:'',
      latitude:0,
      longitude:0,
      province:'',
      default:false
    } 
  }
  patientsData : Array<{
    id: string,
    address :IUserAddress
  }> = [this.patientObject]

  disabledAddMorePatient : Boolean = true

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  checkIfAnyPatientIsMissingId() {
    let isAnyPatientMissingId = false;
  
    this.patientsData.forEach(patient => {
      if (!patient.id) {
        isAnyPatientMissingId = true;
      }
    });
  
    return isAnyPatientMissingId;
  }
  
  handlePatientChange(data : HomecarePatient, index : number){
    this.patientsData[index] = data;
    this.onPatientChange.emit(this.patientsData);
    this.disabledAddMorePatient = this.checkIfAnyPatientIsMissingId()
  }

  async addNewPatient(){
    this.patientsData.push(this.patientObject);
    this.disabledAddMorePatient = this.checkIfAnyPatientIsMissingId()
  }

  async removePatient(index: number){
      this.patientsData = this.patientsData.filter((item,i)=>index!=i)
      this.onPatientChange.emit(this.patientsData);
      this.disabledAddMorePatient = this.checkIfAnyPatientIsMissingId()
  }

  trackByIndex(index: number): any {
    return index;
  }


  async showNewPatientForm(){
    const patientModal = await this.modalController.create({
      component: NewPatientFormComponent,
      cssClass: 'patientsModal',
      backdropDismiss: true,
      componentProps: {
        entityId: this.entityId,
        addPatientAddress: (newPatient: {id: string, address: IUserAddress}) => {
          this.availablePatients.push(newPatient);
        }
      }
    });

    patientModal.present();
  }


  
}
